import React, {FC, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {Box, FormControl, Text, useToast} from "@chakra-ui/react";
import {BreadcrumbsData} from "../../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {SetBreadcrumbsLinks} from "../../../redux/Breadcrumbs/BreadcrumbsReducer";
import {UserController} from "../../../controllers/UserController";
import {MainButton} from "../../../components/Buttons/MainButton";
import {Field, Form, Formik} from "formik";
import {BoxStyleConfig, FormStyleConfig} from "../FormStyleConfigs";
import {LabelStyled} from "../../../components/Inputs/LabelStyled";
import {InputControlStyled} from "../../../components/Inputs/InputControlStyled";
import {RegistrationValidator} from "../../../controllers/Validation/RegistrationValidator";
import {UserCreationRequest} from "../../../shared/entities/Users/User";
import {URLPaths} from "../../../config/application/URLPaths";
import {Breadcrumbs} from "../../../components/Breadcrumbs/Breadcrumbs";
import {SelectControlStyled} from "../../../components/Inputs/SelectControlStyled";
import {useAppSelector} from "../../../redux/Store";
import {CheckoutRequestNewUser} from "../../../shared/entities/Client/CheckoutRequest";
import {ProductCostCalculateUtils} from "../../../shared/utilities/ProductCostCalculateUtils";
import {OrderController} from "../../../controllers/OrderController";
import {ClientMaskedInput} from "../../../components/Inputs/ClientMaskedInput";

interface RequestRegistrationFormProps {
    links?: Array<BreadcrumbsData>,
    basket?: boolean;
    isEditing?: boolean;
}

export const RequestRegistrationForm: FC<RequestRegistrationFormProps> = ({
                                                                              links,
                                                                              basket = false,
                                                                          }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userController = new UserController(dispatch)
    const orderController = new OrderController(dispatch);
    const toast = useToast();

    const states = useAppSelector(state => [...state.StatesReducer.stateList]);
    const products = useAppSelector((state) => state.CartReducer.cartItems);
    const debug = process.env.NODE_ENV === "development";
    const [initialValues, setInitialValues] = useState<any>({
        user: {name: "", state: 1},
        company: {name: "", email: "", phone: ""},
        order: {po: ""}
    });
    if (links) {
        SetBreadcrumbsLinks(dispatch, links)
    }
    const RegistrationFormCardConfig = {
        marginX: {base: "unset", lg: "auto"},
        w: {
            sm: "100%",
            md: "100%",
            lg: "50",
            xl: "35%"
        },
        h: {
            sm: "50%",
            lg: "50%"
        },
        justifyContent: "center"
    }

    const inputProperties = {
        maxW: "auto",
        autoComplete: debug ? "on" : "new-password"
    };
    const placeholderProps = {placeholder: 'This field is optional'};

    return (
        <>
            {links && (
                <Breadcrumbs/>
            )}
            <Box {...RegistrationFormCardConfig} py={!basket ? 8 : "unset"}>
                <Text fontSize={30} fontWeight={"md"} w={"90%"}>For new customers</Text>
                {basket ? (
                    <Box py={4}>
                        <Text fontWeight={"sm"} w={"90%"}>Please submit information to register as a new
                            customer:</Text>
                    </Box>
                ) : (<Text fontWeight={"sm"} w={"90%"}>To register, please fill in the blanks and we will email you the
                        login
                        and
                        password:</Text>
                )}
                <Formik
                    initialValues={initialValues}
                    onSubmit={
                        async (values, actions) => {
                            actions.setSubmitting(true)
                            if (basket) {
                                if (products) {
                                    let result = ProductCostCalculateUtils.calculateTotalPrice(products, undefined, values.user.state ? states[values.user.state].tax : 0);
                                    const checkoutRequestNewUser: CheckoutRequestNewUser = {
                                        name: values.user.name ? values.user.name : "",
                                        email: values.company.email ? values.company.email : "",
                                        phone: values.company.phone ? values.company.phone : "",
                                        companyName: values.company.name ? values.company.name : "",
                                        stateId: states[values.user.state]?.id,
                                        tax: result.taxCost,
                                        shippingCost: result.shippingCost,
                                        totalAmount: result.totalPrice,
                                        subTotal: result.subtotal,
                                        orderPo: values?.order?.po,
                                        productRequest: ProductCostCalculateUtils.getProductsRequest(products, undefined, values.user.state ? states[values.user.state].tax : 0)
                                    };
                                    orderController.createOrderNewUser(checkoutRequestNewUser).then(res => {
                                        actions.setSubmitting(false)
                                        toast({
                                            position: "top",
                                            title: res.None ? 'Failed checkout ' + res?.None?.getErrorDescription() : 'Your order was recived and on its way to you!',
                                            status: res.None ? 'error' : 'success',
                                            duration: 8000,
                                            isClosable: true,
                                        })
                                        if (!res?.None) {
                                            navigate(`${URLPaths.ORDER_UNREGISTERED.link}${res?.Some.id}`)
                                        }
                                    });
                                }
                            } else {
                                const userCreationRequest: UserCreationRequest = {
                                    name: values.company.name ? values.company.name : "",
                                    email: values.company.email ? values.company.email : "",
                                    phone: values.company.phone ? '1' + values.company.phone : "",
                                    username: values.user.name ? values.user.name : "",
                                    state: values.user.state ? states[values.user.state].id : 0,
                                };
                                if (basket) {

                                }
                                await userController.userCreationRequest(userCreationRequest).then(res => {
                                    toast({
                                        position: "top",
                                        title: res.None ? res?.None?.getErrorDescription() : 'Your request was received. Representetive will contact you shortly.',
                                        status: res.None ? 'error' : 'success',
                                        duration: 4000,
                                        isClosable: true,
                                    })
                                    if (!res.None) {
                                        navigate(`${URLPaths.HOME.link}`)
                                    }
                                })
                            }
                        }}
                    validate={values => {
                        if (values.user.state.id !== -1) {
                            dispatch({type: "SET_STATE", payload: states[values.user.state]});
                        }
                        setInitialValues(values)
                    }}
                    enableReinitialize={true}
                    validationSchema={RegistrationValidator.GetSchema()}
                >
                    {({isSubmitting, touched, errors, values, dirty, isValid, handleSubmit, setFieldValue}) => (
                        <FormControl {...FormStyleConfig} pt="unset">
                            <Form onSubmit={handleSubmit}>
                                <Box {...BoxStyleConfig}>
                                    <LabelStyled>Company Name</LabelStyled>
                                    <InputControlStyled name="company.name" inputProps={inputProperties}/>
                                    <LabelStyled>Your Name</LabelStyled>
                                    <InputControlStyled name="user.name" inputProps={inputProperties}/>
                                    <LabelStyled>E-mail</LabelStyled>
                                    <InputControlStyled name="company.email" inputProps={inputProperties}/>
                                    <LabelStyled>Phone</LabelStyled>
                                    {/*<InputControlStyled name="company.phone" inputProps={inputProperties}/>*/}
                                    <Field name="company.phone"
                                           component={ClientMaskedInput}
                                           mask="***-***-****"
                                           placeholder="000-000-0000"/>
                                    <LabelStyled>State</LabelStyled>
                                    <SelectControlStyled
                                        name="user.state"
                                        defaultValue={"Alaska"}
                                        children={
                                            [...states?.map(type => {
                                                return <option key={type.id} value={type.id}
                                                               children={type.name}/>
                                            })]
                                        }
                                    />
                                    {basket &&
                                        <>
                                            <LabelStyled placeholder={"This field no "}>PO#</LabelStyled>
                                            <InputControlStyled name="order.po"
                                                                inputProps={{...placeholderProps, ...inputProperties}}/>
                                        </>
                                    }

                                </Box>
                                <Box pt={6}>
                                    <MainButton
                                        w={"156px"}
                                        minHeight={"40px"}
                                        borderColor="gray.500"
                                        border={"2px"}
                                        type="submit"
                                        isLoading={isSubmitting}
                                        loadingText="Sent data"
                                    >
                                        {'Send Request'}
                                    </MainButton>
                                </Box>
                            </Form>
                        </FormControl>
                    )}
                </Formik>
            </Box>
        </>
    )
}
export default RequestRegistrationForm;