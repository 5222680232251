import {PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "react"
import {SubscribeRepositoryInstance} from "../../repository/Mail/SubscribeRepository";
import {ActionTypes} from "../../redux/Mail/SubscribeReducer";

/**
 * UserService.
 *
 */
export class SubscribeService {
    private dispatch: Dispatch<PayloadAction<any>>
    private repository = SubscribeRepositoryInstance

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
    }

    public async getSubscribeExcludeAll(params: {}) {
        return await this.repository.getSubscribeExcludeAll(params).then(res => {
            this.dispatch({
                type: ActionTypes.EXCLUDE_SUBSCRIBE_LIST_ALL,
                payload: res.Some
            })
            return res.Some
        })
    }

    public async getSubscribeList(params: {}) {
        return await this.repository.getSubscribeList(params).then(res => {
            this.dispatch({
                type: ActionTypes.SUBSCRIBE_LIST_ALL,
                payload: res.Some
            })
            return res.Some
        })
    }

    public async removeExcludeSubscribe(params: {}) {
        return await this.repository.removeExcludeSubscribe(params).then(res => {
            return res.Some
        })
    }

    public async addExcludeSubscribe(data: any) {
        return await this.repository.addExcludeSubscribe(data).then(res => {
            return res.Some
        })
    }

}



