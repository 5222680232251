import {CustomError} from "../../shared/errors/CustomErrors";
import {Option} from "../../shared/utilities/OptionT";
import {APIDao, Requester} from "../APIRequester";
import {defaultState} from "../../shared/entities/Chapter/DefaultState";
import {STATE_API_PREFIX} from "../../common/constants";

/**
 * ProductRepository.
 *
 * Responsible for fetching data from backend
 * by callind APIDao and handling various data
 * coming from API
 *
 * Data is passed to ClientService in a form of
 * an @type Option<T> type, where @param{Some: T} is either
 * a valid data, expected from the server,
 * or, in case of error, a default safe value,
 * for an object of array (kinda like default
 * values for types in Go),and @param{None: CustomError}
 * is either an error or undefined
 */
export class StateRepository {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    public async getStates(): Promise<Option<defaultState>> {
        return await this.dao.getRequest<defaultState>(`${STATE_API_PREFIX}/all`, {}).then(res => {
            const result: Option<defaultState> = {
                Some: res?.data,
            }
            return result

        }).catch((err: CustomError) => {
            const result: Option<defaultState> = {
                Some: {
                    id: 1,
                    name: "State",
                },
                None: err
            }
            return result
        })
    }

}

export const StateRepositoryInstance = new StateRepository(APIDao)
