import * as Yup from 'yup';
import {CommonValidators} from './CommonValidators';

export class ClientValidator {
    public static GetSchema(validateRoleFields: boolean = true): Yup.AnySchema {
        let schema = Yup.object().shape({
            company: Yup.object().shape({
                name: CommonValidators.commonFieldValidator
            }),
        });
        if (validateRoleFields) {
            schema = schema.shape({
                dealership: Yup.object().shape({
                    name: CommonValidators.commonFieldValidator,
                    email: CommonValidators.emailValidator,
                    phone: CommonValidators.commonFieldValidator,
                }),
                roleIds: Yup.array().of(Yup.string()).min(1, 'At least one ROLE_ID is required'),
                copyShippingToBilling: Yup.boolean(),
                billingAddress: Yup.object().when('copyShippingToBilling', {
                    is: false,
                    then: Yup.object().shape({
                        state: CommonValidators.commonFieldValidator,
                        city: CommonValidators.commonFieldValidator,
                        street: CommonValidators.commonFieldValidator,
                        zip: CommonValidators.zipValidator,
                    }),
                }),
                shippingAddress: Yup.object().shape({
                    state: CommonValidators.commonFieldValidator,
                    city: CommonValidators.commonFieldValidator,
                    street: CommonValidators.commonFieldValidator,
                    zip: CommonValidators.zipValidator,
                }),
            });
        }

        return schema;
    }
}
