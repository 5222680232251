import React, {useEffect, useState} from 'react';
import {Box, Checkbox, Flex, Input, Text} from '@chakra-ui/react';
import {MainButton} from "../../../components/Buttons/MainButton";
import {ImageItem, Product} from "../../../shared/entities/Product/Product";
import {WebPUtils} from "../../../shared/utilities/WebPUtils";
import ImageCustom from "../../../components/Image/ImageCustom";
import {useFormikContext} from "formik";

interface PhotoUploadManagerProps {
    onChange: (images: ImageItem[]) => void;
    primaryImage: number;
    item: Product;
}

const PhotoUploadManager: React.FC<PhotoUploadManagerProps> = ({onChange, item, primaryImage = 0}) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [imageItems, setImageItems] = useState<ImageItem[]>([]);
    const [updateMode, setUpdateMode] = useState<boolean>(false);
    const [fileUrl, setFileUrl] = useState<string>(''); // Состояние для хранения ссылки на файл

    const {setFieldValue} = useFormikContext();
    const areFilesEqual = (file1: File, file2: File): boolean => {
        return file1.size === file2.size && file1.name === file2.name;
    };

    const handleIndexChange = (index: number) => {
        setCurrentIndex(index);
    };
    useEffect(() => {
        if (item && item.images && item.images.length > 0) {
            const newImages = item.images.map((image, index) => ({
                url: WebPUtils.getImageUrl("common", image.uuid, false),
                file: null,
                uuid: image.uuid,
                index: index
            }));

            const shouldUpdate = newImages.some((newImage) => !imageItems.some((existingImage) => existingImage.url === newImage.url));

            if (shouldUpdate) {
                setImageItems((prevItems) => [...prevItems, ...newImages]);

                if (imageItems.length > 0) {
                    setCurrentIndex((prevIndex) => prevIndex + newImages.length);
                } else {
                    setCurrentIndex(0);
                }
            }
        } else {
            setImageItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])


    const handleImageUpload = (files: FileList | null) => {
        const newImages = files ? Array.from(files) : [];
        const newImagesWithInfo: ImageItem[] = newImages.map(file => ({
            file: file,
            url: URL.createObjectURL(file),
            uuid: "",
        }));

        if (updateMode) {
            const updatedImageItems = [...imageItems];
            updatedImageItems[currentIndex] = newImagesWithInfo[0];
            setImageItems(updatedImageItems);

            onChange(updatedImageItems);
            setUpdateMode(false);
        } else {
            const uniqueNewImages = newImagesWithInfo.filter(newImage =>
                !imageItems.some(existingImage => {
                    if (existingImage !== null && existingImage.file !== null && newImage.file !== null) {
                        return areFilesEqual(existingImage.file, newImage.file)
                    }
                })
            );

            if (uniqueNewImages.length !== 0) {
                setImageItems(prevItems => [...prevItems, ...uniqueNewImages]);
                onChange([...imageItems, ...uniqueNewImages]);
                if (imageItems.length > 0) {
                    setCurrentIndex(prevIndex => prevIndex + newImages.length);
                } else {
                    setCurrentIndex(0);
                }
            }
        }
    };


    const handleRemoveImage = (index: number) => {
        const updatedImages = [...imageItems];
        updatedImages.splice(index, 1);
        setImageItems(updatedImages);
        onChange(updatedImages);

        if (updatedImages.length === 0) {
            setCurrentIndex(0);
        } else if (currentIndex >= updatedImages.length) {
            setCurrentIndex(updatedImages.length - 1);
        }
    };

    const handleChooseImage = () => {
        const input = document.getElementById('imageInput') as HTMLInputElement;
        if (input) {
            input.click();
        }
    };
    const handleFileUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFileUrl(e.target.value);
    };
    const handleFileUrlUpload = () => {
        if (!fileUrl || (!fileUrl.startsWith('http://') && !fileUrl.startsWith('https://'))) {
            // Обработка ошибки: неверный URL
            return;
        }
        const newImagesWithInfo: ImageItem = ({
            file: null,
            url: fileUrl,
            uuid: "url",
        })
        setImageItems(prevItems => [...prevItems, newImagesWithInfo]);
        onChange([...imageItems, newImagesWithInfo]);
        if (imageItems.length > 0) {
            setCurrentIndex(imageItems.length);
        } else {
            setCurrentIndex(0);
        }
    };

    return (
        <Box mt="2rem">
            <Flex flexDir="row">
                <Text minWidth={'5px'}>
                    {imageItems.length > 0 ? `Number: ${imageItems.length} curr: ${currentIndex + 1}` : ''}
                </Text>
                <Checkbox
                    color="gray.400"
                    ml={'10px'}
                    isChecked={primaryImage === currentIndex}
                    children={'Show first'}
                    onChange={(ev: any) =>
                        setFieldValue('primaryImage', ev.target.checked ? currentIndex : primaryImage, true)
                    }
                />
            </Flex>
            <Flex alignItems="center" mb={4} mr={4}>
                <ImageCustom
                    elem={item}
                    sizeInPercent={50}
                    upload={true}
                    uploadImages={imageItems}
                    showOnHover={false}
                    hideArrows={false}
                    parentCurrentIndex={currentIndex}
                    onIndexChange={handleIndexChange}
                />
                <Flex flexDirection="column" ml={imageItems.length > 0 ? '1rem' : 'default'}>
                    <Input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={(e) => handleImageUpload(e.target.files)}
                    />
                    {/* Поле ввода ссылки на файл */}

                    <MainButton mb={'0.2rem'} onClick={() => {
                        handleChooseImage();
                        setUpdateMode(true);
                    }}>
                        {imageItems.length > 0 ? 'Update' : 'Upload'}
                    </MainButton>
                    {imageItems.length > 0 && (
                        <>
                            <MainButton mb={'0.2rem'} onClick={() => {
                                handleChooseImage();
                                setUpdateMode(false);
                            }}>
                                Add Image
                            </MainButton>
                            <MainButton onClick={() => handleRemoveImage(currentIndex)} colorScheme="red">
                                Remove
                            </MainButton>
                        </>
                    )}
                </Flex>

            </Flex>
            <Flex flexDirection="row">
                <Input
                    maxWidth={"200px"}
                    placeholder="Enter file URL"
                    value={fileUrl}
                    onChange={handleFileUrlChange}
                />
                <MainButton ml={"0.8rem"} onClick={handleFileUrlUpload} disabled={!fileUrl}>
                    Upload URL
                </MainButton>
            </Flex>
        </Box>
    );
};

export default PhotoUploadManager;

