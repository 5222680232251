import { ChakraProps, Heading } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { Fabric } from "../../shared/utilities/Fabric";
import { Separator } from "../Separator/Separator";


export enum HeadingType {
    FORM_HEADING,
    FORM_SECTION_HEADING
}

type HeadingConfig = {
    text?: string
    type: HeadingType,
    styles?: ChakraProps
}

class HeadingFabric implements Fabric<ReactNode, HeadingConfig> {

    public Generate(configurationOption: HeadingConfig): ReactNode {
        switch (configurationOption.type) {
            case HeadingType.FORM_HEADING:
                return <CardHeading children={configurationOption?.text} {...configurationOption.styles} />
            case HeadingType.FORM_SECTION_HEADING:
                return <FormSectionHeading children={configurationOption?.text} {...configurationOption.styles} />
            default:
                return <Separator></Separator>
        }
    }
}


const CardHeading: FC<ChakraProps> = (props) => {
    const GeneralCardHeadingStyleConfig: ChakraProps = {
        fontFamily: "Poppins, sans-serif",
        fontSize: "30px",
        fontWeight:"600",
        ...props
    }
    return (
        <Heading {...GeneralCardHeadingStyleConfig}>
            {props.children}
        </Heading>
    )
}

const FormSectionHeading: FC<ChakraProps> = (props) => {
    const FormSectionHeadingStyleConfig: ChakraProps = {
        fontFamily: "Poppins, sans-serif",
        fontSize: {
            sm: "14px",
            lg: "14px"
        },
        ...props
    }
    return (
        <Heading {...FormSectionHeadingStyleConfig}>
            {props.children}
        </Heading>
    )
}

export const headingFabric: Fabric<ReactNode, HeadingConfig> = new HeadingFabric()
