import {PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "react"
import {FileRepositoryInstance} from "../../repository/File/FileRepository";
import {ActionTypes} from "../../redux/File/FileReducer";
import {Option} from "../../shared/utilities/OptionT";

/**
 * ClientService.
 *
 */

export class FileService {
    private dispatch: Dispatch<PayloadAction<any>>
    private repository = FileRepositoryInstance

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
    }

    public async getFileName(fileName: string) {
        return this.repository.getFileName(fileName).then(res => {
            this.dispatch({
                type: ActionTypes.FILE_SIMPLE,
                payload: res?.Some || []
            })
            return res
        })
    }

    public async uploadFile(customerId: number | string, file: File, fileType: string): Promise<Option<any>> {
        return this.repository.uploadFile(customerId, file, fileType).then(res => {
            this.dispatch({
                type: ActionTypes.FILE_UPLOAD,
                payload: res
            })
            return res
        })
    }

    public async uploadFileUrl(customerId: number | string, file: URL, fileType: string): Promise<Option<any>> {
        return this.repository.uploadFileUrl(customerId, file, fileType).then(res => {
            this.dispatch({
                type: ActionTypes.FILE_UPLOAD,
                payload: res
            })
            return res
        })
    }
}