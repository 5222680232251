import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {OrderProductService} from "../service/OrderProduct/OrderProductService";

export class OrderProductController {
    private dispatch: Dispatch<PayloadAction<any>>
    private service: OrderProductService

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
        this.service = new OrderProductService(this.dispatch)
    }

    public async getOrderProducts(id: number) {
        return this.service.getOrderProducts(id)
    }
}
