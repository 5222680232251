import {PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "react"
import {OrderProductRepositoryInstance} from "../../repository/OrderProduct/OrderProductRepository";
import {ActionTypes} from "../../redux/OrderProduct/OrderProductReducer";


/**
 * ClientService.
 *
 */
export class OrderProductService {
    private dispatch: Dispatch<PayloadAction<any>>
    private repository = OrderProductRepositoryInstance

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
    }


    public async getOrderProducts(id: number) {
        return this.repository.getOrderProducts(id).then(res => {
            this.dispatch({
                type: ActionTypes.ORDER_PRODUCTS_LIST,
                payload: res?.Some || []
            })
            return res
        })
    }

}
