import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {FileService} from "../service/File/FileService";

export class FileController {
    private dispatch: Dispatch<PayloadAction<any>>
    private service: FileService

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
        this.service = new FileService(this.dispatch)
    }

    public async getFileName(fileName: string) {
        return this.service.getFileName(fileName).then(res => res)
    }

    public async uploadFile(customerId: number | string, file: File, fileType: string) {
        return this.service.uploadFile(customerId, file, fileType).then(res => res)
    }

    public async uploadFileUrl(customerId: number | string, fileUrl: URL, fileType: string) {
        return this.service.uploadFileUrl(customerId, fileUrl, fileType).then(res => res)
    }
}
