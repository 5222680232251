import * as Yup from "yup"
import {CommonValidators} from "./CommonValidators";

/**
 * Валидатор формы создания нового пользователя
 */
export class RestoreValidator {

        private static ValidationSchema: Yup.AnySchema = Yup.object().shape({
                EMAIL_RESTORE: CommonValidators.emailValidator,
        })

        public static GetSchema(): Yup.AnySchema {
                return this.ValidationSchema
        }
}
