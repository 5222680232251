import {Box, Flex} from "@chakra-ui/react";
import {InfoBox} from "./InfoBox";

interface InfoRowProps {
    leftLabel: string;
    leftContent: string | string[];
    rightLabel: string;
    rightContent: string | string[];
    pt?: number;  // Опциональный пропс для отступа сверху
}

export const InfoRow: React.FC<InfoRowProps> = ({leftLabel, leftContent, rightLabel, rightContent, pt = 0}) => (
    <Flex justifyContent="space-between" alignItems="flex-start" pb={1} pt={pt} direction={{base: "column", lg: "row"}}>
        <InfoBox label={leftLabel} content={leftContent}/>
        <Box pl={{lg: 1}} pt={{base: 1,lg: 0}} h="100%" width="100%">
            <InfoBox label={rightLabel} content={rightContent}/>
        </Box>
    </Flex>
);
