import * as Yup from 'yup';
import {CommonValidators} from "./CommonValidators";

export class ProductValidator {
    private static ValidationSchema: Yup.AnySchema = Yup.object().shape({
        id: Yup.number(),
        parentId: Yup.number(),
        name: Yup.string().required('Name is required'),
        shipping_cost: CommonValidators.numberValidator,
        shipping_step: CommonValidators.percentageValidator,
        shipping_min_size: CommonValidators.integerValidator,
        price: CommonValidators.numberValidator,
        quantityStep:CommonValidators.integerValidator,
        startValue:CommonValidators.integerValidator,
        description: Yup.string(),
        priority: Yup.number(),
        type: Yup.string(),
        orderBy: Yup.number(),
    });

    public static GetSchema(): Yup.AnySchema {
        return this.ValidationSchema;
    }
}

