import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {Option} from "../shared/utilities/OptionT";
import {OrderService} from "../service/Order/OrderService";
import {CheckoutRequest, CheckoutRequestNewUser} from "../shared/entities/Client/CheckoutRequest";

export class OrderController {
    private dispatch: Dispatch<PayloadAction<any>>
    private service: OrderService

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
        this.service = new OrderService(this.dispatch)
    }

    public async getOrder(id: number, unregistered: boolean, email?: string) {
        return this.service.getOrder(id, unregistered, email).then(res => {
            return res
        })
    }

    public async invoicePdf(ids: Array<number>) {
        return this.service.invoicePdf(ids).then(res => res)
    }

    public async packingSleep(ids: Array<number>) {
        return this.service.packingSleep(ids).then(res => res)
    }

    public async sendInvoice(ids: Array<number>) {
        return this.service.sendInvoice(ids).then(res => res)
    }
    public async printPressed(ids: Array<number>) {
        return this.service.printPressed(ids).then(res => res)
    }

    public async removeOrder(id: number) {
        return this.service.removeOrder(id).then(res => res)
    }

    public async orderConfirmState(data: any) {
        return this.service.orderConfirmState(data).then(res => res)
    }

    public async orderCheckout(data: CheckoutRequest) {
        return this.service.orderCheckout(data).then(res => res)
    }

    public async createOrderNewUser(data: CheckoutRequestNewUser) {
        return this.service.createOrderNewUser(data).then(res => res)
    }

    public async getOrderList(params: any) {
        return this.service.getOrderList(params).then(res => {
            return res
        })
    }

    private static handleResult<T>(res: Option<T>): Option<boolean> {
        if (res?.None) {
            return {
                Some: false,
                None: res.None
            }
        }
        return {Some: true}
    }
}
