import { Box, ChakraProps, Flex, Link } from "@chakra-ui/react";
import React, { FC, MouseEventHandler, ReactNode } from "react";

/**
 * ListItemConfig.
 */
export type ListProductItemConfig = {
    isLink?: boolean;
    content: ReactNode;
    clickAction?: MouseEventHandler<HTMLDivElement>;
    style?: ChakraProps;
    width?: string;
    justifyContent?: string;
};

/**
 * Строка в списочных компонентах, используется, например,
 *  в реестрах
 * @param param0 
 * @returns 
 */
export const ListProductItemRow: FC<{ list: Array<ListProductItemConfig>, noBorder?: boolean, style?: ChakraProps, clickAction?: MouseEventHandler<HTMLDivElement> }> = ({ list, noBorder, style, clickAction , children }) => {
    const DefaultListItemConfig: ChakraProps = {
        borderBottom: noBorder ? "none" : "1px solid #71809640",
        borderColor: noBorder ? "gray.600":"none",
        h: "auto",
        w: "100%",
        justifyContent: "space-between",
        ...style
    }
    const List: Array<ReactNode> = list.map((config: ListProductItemConfig, idx: number) => {
        return <ListProductItem key={idx} {...config} style={{
            ...style,
            // border: noBorder ? "1px solid #71809640":"none",
            // borderColor: noBorder ? "gray.600":"none",
        }}/>
    })

    const ExtendedListConfig: ChakraProps = {
        padding: "0 0 10px 0",
        background: "#E2E8F0",
    }

    return (
      <Box >
          <Flex {...DefaultListItemConfig} onClick={clickAction ? clickAction : () => { }}>
              {List}
          </Flex>
          {!!children && (
            <Box {...ExtendedListConfig}>
                {children}
            </Box>
          )}
      </Box>

    )
}

export const ListLinkItemDefaultStyleConfig: ChakraProps = {
    color: "gray.800",
    textDecoration: "none",
    _hover: {
        color: "gray.800",
        textDecoration: "underline",
        borderRadius: "5px",
    },
};



/**
 * Ячейка в строке списка
 * @param param0 
 * @returns 
 */
const ListProductItem: FC<ListProductItemConfig> = ({ isLink, content, clickAction, style, width,justifyContent }) => {
    const ListItemDefaultStyleConfig: ChakraProps = {
        display: "flex",
        justifyContent: justifyContent || "center",
        alignItems: "center",
        width: width || "fit-content",
        // textAlign: "center",
        fontSize: "18px",
        fontFamily: "Poppins",
        color: "gray.800",
        textOverflow: "ellipsis",
        // p: "5px 0px",
        ...style,
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (clickAction) {
            clickAction(event);
        }
    };

    return (
        <Box {...ListItemDefaultStyleConfig} onClick={handleClick}>
            {isLink ? <Link sx={ListLinkItemDefaultStyleConfig}>{content}</Link> : content}
        </Box>
    );
};