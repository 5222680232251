import React from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';
import {Content, Product} from '../../../shared/entities/Product/Product';
import {usePrivileges} from "../../../hooks/PrivilegesProvider";
import EditButtons from "../../../components/Buttons/EditButtons";
import ImageCustom from "../../../components/Image/ImageCustom";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import ProductVideo from "../ProductVideo";
import {CardBody} from "../../../components/Card/CardBody";
import {MainButton} from "../../../components/Buttons/MainButton";
import {WebPUtils} from "../../../shared/utilities/WebPUtils";
import CustomLink from "../../../components/Link/CustomLink";

interface AnotherGeneralItemProps {
    product: Product;
    height?: string;
    width?: string;
    divider?: number;
    isMobile?: boolean;
}

const AnotherGeneralItem: React.FC<AnotherGeneralItemProps> = ({
                                                                   product,
                                                                   height,
                                                                   width = "100%",
                                                                   divider = 1,
                                                                   isMobile = false
                                                               }) => {
    const {priv} = usePrivileges()

    return (
        <Flex justifyContent={isMobile ? "unset" : "center"}>
            <Box display="flex" direction={{base: "column", md: "row"}} height={"100%"}
                 w={{base: "100%", md: "unset", lg: "unset"}}>
                {priv?.PRODUCT_EDIT &&
                    <DragAndDropBox item={product} width={"2%"} orderByZone={true} height={height}/>
                }
                <CardBody height={{base: "100%", md: "100%", lg: height}}>
                    <DragAndDropBox item={product} isDraggable={priv?.PRODUCT_EDIT} row={true}>
                        <Flex h={"100%"} w={isMobile ? "100%" : "unset"} direction={{base: "column", md: "row"}}>
                            <Flex w={isMobile ? "100%" : "426px"} maxWidth={"426px"}>
                                <Box pl={10} py={6} pr={6}>
                                    <EditButtons item={product}/>
                                    <Flex pb={2}>
                                        <CustomLink href={WebPUtils.getProductLink(product)} product={product}>
                                            <Text fontSize={22} fontWeight={"md"} lineHeight={"110%"}>
                                                {product.name}
                                            </Text>
                                        </CustomLink>
                                    </Flex>
                                    {product.video.length > 0 && (
                                        product.video
                                            .sort((a, b) => a.order - b.order)
                                            .map((video: Content, idx: number) => {
                                                return <ProductVideo key={video.id || idx} title={true} video={video}/>
                                            })
                                    )}
                                    <Text fontWeight={"sm"} fontSize={14} lineHeight={"150%"}>
                                        {product.description}
                                    </Text>
                                </Box>
                            </Flex>
                            <Box w={{base: "100%", md: "200px"}}>
                                <Flex w={{base: "90%", md: "200px"}} mx={"auto"}>
                                    <ImageCustom elem={product} currentSize={"another"} isMobail={isMobile}/>
                                </Flex>
                                <Flex p={6} justifyContent="center">
                                    <CustomLink href={WebPUtils.getProductLink(product)} product={product}>
                                        <MainButton
                                            // onClick={() => handleLink(product)}
                                        >{product.name === 'Nitrogen' ? 'Order' : 'Read More'}</MainButton>
                                    </CustomLink>
                                </Flex>
                            </Box>
                        </Flex>
                    </DragAndDropBox>
                </CardBody>
            </Box>
        </Flex>
    );
};

export default AnotherGeneralItem;
