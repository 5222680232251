import React, {useEffect, useState} from "react";
import {
    Box,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Button,
    Flex,
    useToast,
} from "@chakra-ui/react";

import {useDispatch} from "react-redux";
import {SubscribeController} from "../../controllers/SubscribeController";
import {GrAdd} from "react-icons/all";
import {useAppSelector} from "../../redux/Store";
import {Loader} from "../../components/Loader/Loader";
import {ListItemConfig, ListItemRow} from "../../components/List/ListItem";
import {SubscribeItem} from "../../shared/entities/Subscribe/ExcludeSubscribe";
import {Filter} from "../../components/Filter/Filter";
import IconWithTooltip from "../../components/Icon/IconWithTooltip";
import {AiOutlineClose} from "react-icons/ai";
import {FixedSizeList as List} from "react-window";
import * as ReactWindow from "react-window";

interface ExcludeSubscribePopoverProps {
    item: any;
    onSuccess?: () => void;
}

const ExcludeSubscribePopover: React.FC<ExcludeSubscribePopoverProps> = ({onSuccess}) => {
    const dispatch = useDispatch();
    const toast = useToast();
    const subscribeController = new SubscribeController(dispatch);
    const subscribeList = useAppSelector(state => state.SubscribeReducer.subscribeList)
    const [filterSubscribes, setFilterSubscribes] = useState<Array<any>>([])
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false); // Добавим состояние загрузки
    const [resetFilter, setResetFilter] = useState(false);
    const onOpen = () => {
        setIsLoading(false); // Устанавливаем состояние загрузки
        setFilterSubscribes(subscribeList)
    };
    const setNewFilterValue = (searchTerm: any, modal: boolean) => {
        setSearchTerm(searchTerm.search);
    };

    useEffect(() => {
        if (searchTerm) {
            setFilterSubscribes(subscribeList.filter(subscribe =>
                subscribe.email.toLowerCase().includes(searchTerm.toLowerCase())
            ))
        } else {
            setFilterSubscribes(subscribeList)
        }
    }, [searchTerm]);

    const handleSaveClick = (subscribe?: SubscribeItem, onClose?: () => void) => {
        subscribeController.addExcludeSubscribe({ email: subscribe?.email }).then((res) => {
            const isError = !!res.None;
            toast({
                position: "top",
                title: isError
                    ? "Failed to add exclude email subscribe."
                    : "Exclude email subscribe added successfully.",
                status: isError ? "error" : "success",
                duration: 5000,
                isClosable: true,
            });

            setResetFilter((prevResetFilter) => !prevResetFilter)
            if (!isError) {
                if (onSuccess) {
                    onSuccess();
                }
                if (onClose) {
                    onClose(); // Закрыть Popover
                }
            }
        });
    };

    const renderRow = ({ index, style }: ReactWindow.ListChildComponentProps, onClose?: () => void) => {
        const elem = filterSubscribes[index];

        if (!elem) return null;

        const ItemConfig: Array<ListItemConfig> = [
            {
                content: (
                    <IconWithTooltip
                        icon={AiOutlineClose}
                        tooltipMessage={"Exclude Email"}
                    />
                ),
                clickAction: () => handleSaveClick(elem, onClose),
                isLink: true,
                style: {
                    flexBasis: "10%",
                },
            },
            {
                content: elem.email,
                isLink: false,
                style: {
                    flexBasis: "90%",
                    justifyContent: "flex-start",
                },
            },
        ];

        return (
            <Box style={style} key={index}>
                <ListItemRow list={ItemConfig}/>
            </Box>
        );
    };

    return (
        <Popover closeOnBlur={true} onOpen={onOpen} isLazy lazyBehavior="keepMounted">
            {({isOpen, onClose}) => (
                <>
                    <PopoverTrigger>
                        <Button
                            bg={"transparent"}
                            _hover={{bg: "transparent"}}
                            _active={{bg: "transparent"}}
                        >
                            <IconWithTooltip
                                icon={GrAdd}
                                isOutlined={false}
                                tooltipMessage={`Add email exclude`}
                            />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent
                        style={{
                            padding: 8,
                            maxHeight: "calc(67vh - 20px)",
                            overflowY: "auto",
                            minWidth: "50%",
                        }}
                    >
                        <PopoverBody p={0}>
                            <Box position="relative">
                                <Box position="sticky" top="0" bg="white" zIndex="1">
                                    <Flex>
                                        <Box p={1}/>
                                        <Filter
                                            updateFunc={setNewFilterValue}
                                            uniqueName="search"
                                            placeholder="Search"
                                            reset={resetFilter}
                                            style={{border: "1.6px solid", borderColor: "gray.600"}}
                                        />
                                        <Box p={1}/>
                                    </Flex>
                                </Box>
                                <Box pl={3}>
                                    {isLoading ? (
                                        <Loader/>
                                    ) : (
                                        <List
                                            height={400} // Высота видимой области
                                            itemCount={filterSubscribes.length} // Количество элементов в списке
                                            itemSize={40} // Высота одного элемента
                                            width="100%" // Ширина контейнера
                                        >
                                            {(props) => renderRow(props, onClose)}
                                        </List>
                                    )}
                                </Box>
                            </Box>
                        </PopoverBody>
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};

export default ExcludeSubscribePopover;
