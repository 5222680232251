import React from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import {ListItemConfig, ListItemRow} from "../../../components/List/ListItem";
import {ProductTypes} from "../../../common/constants";
import {BoxRowStyleConfig} from "../../Forms/FormStyleConfigs";
import {ContentTypeUtils} from "../../../shared/entities/Product/Product";
import IconWithTooltip from "../../../components/Icon/IconWithTooltip";
import {FiTrash} from "react-icons/all";
import {FiEdit} from "react-icons/fi";
import {DiscountModal} from "../../Modal/DiscountModal";
import {SelectModal} from "../../../redux/Modal/ModalReducer";
import {ModalService} from "../../../service/Modal/ModalService";
import {useDispatch} from "react-redux";
import {MainButton} from "../../../components/Buttons/MainButton";
import {ListProductItemConfig, ListProductItemRow} from "../../../components/List/ListProductItem";

interface DiscountShippingCostListProps {
    contentDiscount: Array<any>;
    type: ProductTypes;
    dealerships: Array<any>;
    label: string;
    field: string;
    setFieldValue: (field: string, value: any) => void;
}

const DiscountCost: React.FC<DiscountShippingCostListProps> = ({
                                                                   contentDiscount,
                                                                   setFieldValue,
                                                                   type,
                                                                   field,
                                                               }) => {
    const dispatch = useDispatch()
    const modalService = new ModalService(dispatch)

    const showModal = (index: number) => {
        modalService.setModal({selModel: SelectModal.DISCOUNT, selCommonContent: contentDiscount, index: index});
    };

    interface ColumnConfig {
        header: string;
        width?: string;
        isLink?: boolean;
        style?: object;
    }

    const ColumnNamesMapping: Array<ListProductItemConfig> = [
        {header: "Dealership", width: "52%"},
        {header: "Discount", width: "28%"},
        {header: "Formula", width: "28%"},
        {header: "Type", width: "12%"}
    ].map(
        (col: ColumnConfig) => {
            return {content: col.header || "", width: col.width || "auto"};
        }
    );

    return (
        <Box>
            <Flex>
                <Box minWidth={"50%"}>
                    <Box maxHeight={"200px"} mt="1rem" overflowY={"auto"}>
                        <ListProductItemRow list={ColumnNamesMapping} style={{
                            fontSize: "14px",
                            minHeight: "35px",
                            justifyContent: "flex-start"
                        }}/>
                        {contentDiscount?.map((elem: any, index: number) => {
                            const ItemConfig: Array<ListItemConfig> = [
                                {
                                    content: <Text fontWeight={"600"}>{elem.title}</Text>,
                                    isLink: false,
                                    style: {flexBasis: "40%", justifyContent: "flex-start", ml: "0.5rem"}
                                },
                                {
                                    content: <Text fontWeight={"600"}>{elem.value + '%'}</Text>,
                                    isLink: false,
                                    style: {flexBasis: "25%"}
                                },
                                {
                                    content: <Text fontWeight={"600"}>{ContentTypeUtils.getCostState(elem.type).func}</Text>,
                                    isLink: false,
                                    style: {flexBasis: "25%"}
                                },
                                {
                                    content: <Text>{ContentTypeUtils.getCostState(elem.type).name}</Text>,
                                    isLink: false,
                                    style: {flexBasis: "15%", ml: "auto"}
                                },
                                {
                                    content: <IconWithTooltip icon={FiEdit} tooltipMessage={'Edit'}
                                                              color={"black"}/>,
                                    clickAction: () => {
                                        showModal(index)
                                    },
                                    isLink: true,
                                    style: {flexBasis: "auto"},
                                },
                                {
                                    content: <IconWithTooltip icon={FiTrash} tooltipMessage={'Remove'}
                                                              color={"black"}/>,
                                    clickAction: () => {
                                        const newDealer = [...contentDiscount];
                                        newDealer.splice(index, 1);
                                        setFieldValue(field, newDealer);
                                    },
                                    isLink: true,
                                    style: {flexBasis: "auto"},
                                }
                            ]
                            return <ListItemRow style={{
                                justifyContent: "flex-start",
                                bgColor: ContentTypeUtils.getCostState(elem.type).color
                            }} key={index} list={ItemConfig}/>
                        })}
                    </Box>
                    <Box {...BoxRowStyleConfig} >
                        <MainButton onClick={() => showModal(-1)} mt={"0.5rem"} ml={"auto"}>
                            Add Discount
                        </MainButton>
                    </Box>
                </Box>
            </Flex>
            <DiscountModal/>
        </Box>
    );
}
export default DiscountCost;