import React, {useEffect, useState} from 'react';
import {Box, Center, Flex, Icon, Image, Link, Spinner, Text, useBreakpointValue} from "@chakra-ui/react";
import {FaYoutube} from "react-icons/all";
import {BoxStyleConfig, BoxRowStyleConfig} from "../Forms/FormStyleConfigs";
import ImageCustom from "../../components/Image/ImageCustom";
import {usePrivileges} from "../../hooks/PrivilegesProvider";
import {Content, ImageDTO, Product} from "../../shared/entities/Product/Product";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import EditButtons from "../../components/Buttons/EditButtons";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {ModalService} from "../../service/Modal/ModalService";
import {useDispatch} from "react-redux";
import {ProductTypes} from "../../common/constants";
import DiscountComponent from "./Discount/DiscountComponent";
import QuantityInput from "../../components/Inputs/QuantityInput";
import {MainButton} from "../../components/Buttons/MainButton";
import {useAppSelector} from "../../redux/Store";
import {ProductCostCalculateUtils} from "../../shared/utilities/ProductCostCalculateUtils";
import {imageSizes} from "../../theme/foundations/breakpoints";
import {WebPUtils} from "../../shared/utilities/WebPUtils";
import {useLocation} from "react-router";

interface ProductCardProps {
    parentProduct: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({
                                                     parentProduct
                                                 }) => {
    const {priv} = usePrivileges();
    const dispatch = useDispatch()
    const modalService = new ModalService(dispatch)
    const authDealership = useAppSelector(state => state.AuthReducer.dealership);
    const [reset, setReset] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [indexImage, setIndexImage] = useState<number>(0);
    let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false});
    const isRender = new URLSearchParams(useLocation().search).get("is_render") === "true"
    const calculatedPrice = ProductCostCalculateUtils.calculatePrice(parentProduct, authDealership ? authDealership : undefined);

    useEffect(() => {
        setTotal(Number((calculatedPrice * quantity).toFixed(4)));
    }, [parentProduct.price, quantity]);

    const renderTextWithLinksAndBold = (text: string) => {
        const linksAndBoldRegex = /(\[([^\]]+)\]\(([^)]+)\)|\*(.*?)\*)/g; // Регулярное выражение для поиска ссылок в формате Markdown и жирного текста
        let lastIndex = 0;
        const elements: React.ReactNode[] = [];

        let matches;
        while ((matches = linksAndBoldRegex.exec(text)) !== null) {
            const [fullMatch, , linkText, linkHref, boldText] = matches;
            const beforeText = text.substring(lastIndex, matches.index);
            elements.push(
                <React.Fragment key={`${beforeText}_${linkHref}`}>
                    {beforeText && (
                        <Text as="span">{beforeText}</Text>
                    )}
                    {linkText && (
                        <Link key={fullMatch} href={linkHref} color="teal.500" isExternal
                              _hover={{textDecoration: 'underline'}}>
                            {linkText}
                        </Link>
                    )}
                    {boldText && (
                        <Text key={boldText} as="span" fontWeight="bold">{boldText}</Text>
                    )}
                </React.Fragment>
            );
            lastIndex = linksAndBoldRegex.lastIndex;
        }
        const remainingText = text.substring(lastIndex);
        if (remainingText) {
            elements.push(
                <Text key={remainingText} as="span">{remainingText}</Text>
            );
        }

        return elements;
    };

    return (
        <Box {...BoxStyleConfig} pb={5}>
            <Box {...BoxRowStyleConfig}>
                <Box pb={3}>
                    <Breadcrumbs/>
                </Box>
                {priv?.PRODUCT_EDIT &&
                    <EditButtons
                        ml={"auto"}
                        size={"28px"}
                        mt={"4px"}
                        showEdit={priv?.PRODUCT_EDIT}
                        item={parentProduct}/>
                }
            </Box>
            <Flex border="1px" borderColor={"gray.200"} borderRadius={"md"} py={isMobile ? 0 : 2} px={isMobile ? 4 : 2}>
                <Flex w={"100%"} height={"100%"} flexDir={isMobile ? "column" : "row"}>
                    <Box borderColor={"blue.200"} width={isMobile ? "100%" : "50%"} minWidth={300} display="flex"
                         flexDirection="column"
                         justifyContent={"space-evenly"}>
                        <Box width={"100%"} alignItems={"center"} pb={2}>
                            <ImageCustom elem={parentProduct} showOnHover={false}
                                         currentSize={"cart"}
                                         sizeBackend={"CART"}
                                         imageIndex={indexImage}
                                         sizeInPercent={isMobile ? 80 : 100}
                                         isMobail={isMobile}
                                         hideArrows={false}/>
                        </Box>

                        <Flex h={"auto"} justifyContent={"center"} alignItems={"center"} flexWrap="wrap">
                            {parentProduct.video.length > 0 && (
                                parentProduct.video.map((video: Content, idx: number) => {
                                    return <Box pr={2} key={idx} pt={2}>
                                        <Flex
                                            border="1px" borderColor={"gray.200"} borderRadius={"md"}
                                            _hover={{
                                                borderRadius: 3,
                                                border: "1px",
                                                borderColor: "primary.general"
                                            }}
                                            alignItems="center"
                                            justifyContent="center"
                                            cursor={"pointer"}
                                            width={`${isMobile ? imageSizes.cardPreview.width / 2 : imageSizes.cardPreview.width}px`}
                                            height={`${isMobile ? imageSizes.cardPreview.height / 2 : imageSizes.cardPreview.height}px`}
                                            onClick={() => {
                                                modalService.setModal({
                                                    selModel: SelectModal.VIDEO_YOUTUBE,
                                                    selCommonContent: video.value
                                                })
                                            }}>
                                            <Box display="flex" flexDirection="column"
                                                 justifyContent={"flex-start"}>
                                                <Icon
                                                    as={FaYoutube}
                                                    strokeWidth={1}
                                                    fontSize={isMobile ? "1.25rem" : "2.5rem"}
                                                    cursor={"pointer"}
                                                    color={"gray.400"}
                                                />
                                                <Text fontSize={12} fontWeight={'sm'}> {video.title}</Text>
                                            </Box>
                                        </Flex>
                                    </Box>
                                })
                            )}
                            {parentProduct.images.length > 1 && (
                                parentProduct.images.map((image: ImageDTO, idx: number) => {
                                    return (
                                        <Box pr={2} key={idx} pt={2}>
                                            <Box border="1px" borderColor={"gray.200"} borderRadius={"md"}
                                                 onClick={() => {
                                                     setIndexImage(idx)
                                                 }}
                                                 _hover={{
                                                     borderRadius: 3,
                                                     border: "1px",
                                                     borderColor: "primary.general"
                                                 }}>
                                                <Image
                                                    fallback={<Center width="100%">
                                                        <Spinner color='red.500'/>
                                                        <Box pt={`100%`}/>
                                                    </Center>
                                                    }
                                                    borderRadius={"md"}
                                                    alt={image?.name}
                                                    src={WebPUtils.getImageUrl("preview", image.uuid, isRender)}
                                                    width={`${isMobile ? imageSizes.cardPreview.width / 2 : imageSizes.cardPreview.width}px`}
                                                    height={`${isMobile ? imageSizes.cardPreview.height / 2 : imageSizes.cardPreview.height}px`}
                                                    cursor={"pointer"}
                                                />
                                            </Box>
                                        </Box>);
                                })
                            )}
                        </Flex>

                    </Box>
                    <Box pl={isMobile ? 0 : 6} py={isMobile ? 0 : 14} width={isMobile ? "100%" : "50%"} minWidth={300}>
                        <Flex minWidth={300} height={"100%"} pr={isMobile ? 0 : 6} pb={isMobile ? 4 : 10} pt={10}>
                            <Box width={"100%"}>
                                <Text textAlign="left" as="h1" fontSize="2rem" fontWeight={"md"} pb={4}
                                      lineHeight={"2.2rem"}>
                                    {parentProduct.name}
                                </Text>
                                {parentProduct.descriptionCart &&
                                    <Text textAlign="left" wordBreak="break-word" whiteSpace={"pre-wrap"} pb={4}
                                          fontWeight={"sm"}>
                                        {renderTextWithLinksAndBold(parentProduct.descriptionCart)}
                                    </Text>
                                }
                                <Box>
                                    {ProductTypes.PRODUCT === parentProduct.type &&
                                        <Box pt={1}>
                                            <DiscountComponent elem={parentProduct} textAlign={"left"}
                                                               showDiscount={true}
                                                               selectedDealer={authDealership ? authDealership : undefined}
                                                               fontSizePrice={30} fontWeightPrice={"400"}/>
                                        </Box>
                                    }
                                    <Box display="flex" alignItems="center" mt="2">
                                        {ProductTypes.OPTIONS !== parentProduct.type && ProductTypes.SUBCATEGORY !== parentProduct.type &&
                                            <>
                                                <QuantityInput item={parentProduct} reset={reset} isMobile={isMobile}
                                                               width={isMobile ? "80%" : "20%"}
                                                               height={"40px"}
                                                               onQuantityChange={(updatedItem) => {
                                                                   setQuantity(updatedItem.quantity)
                                                                   setReset(false);
                                                               }}/>
                                                <Box pl={2}>
                                                    <MainButton
                                                        height={"40px"}
                                                        width={"157px"}
                                                        fontSize={16}
                                                        isDisabled={quantity === 0}
                                                        onClick={() => {
                                                            modalService.setModal({
                                                                selModel: SelectModal.ADD_PRODUCT,
                                                                product: parentProduct,
                                                                quantity: quantity
                                                            })
                                                            setReset(true);
                                                        }}>
                                                        Add to Cart
                                                    </MainButton>
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                    {(ProductTypes.OPTIONS !== parentProduct.type &&
                                            ProductTypes.SUBCATEGORY !== parentProduct.type &&
                                            ProductTypes.CUSTOM !== parentProduct.type &&
                                            ProductTypes.REORDER !== parentProduct.type
                                        ) &&
                                        <Text fontSize="14px" color={"primary.text"} pt={isMobile ? 2 : 0}>
                                            <span style={{fontWeight: "400", paddingRight: 5}}> {"TOTAL"}</span>
                                            <span style={{fontWeight: "600"}}>{`$${total}`}</span>
                                        </Text>
                                    }
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
};

export default ProductCard;