import {OrderProduct} from "../OrderProduct/OrderProduct";

export type Order = {
    id: number;
    tax: number;
    userId: number;
    dealershipId: number;
    companyId: number;
    shippingCost: number;
    totalAmount: number;
    sendCount: number;
    printPressed: number;
    shippingStatus: number;
    paymentStatus: OrderTypes;
    shippingComment: string | null;
    dealershipName: string | null;
    dealershipLocalStatus: boolean;
    dealershipEmail: string | null;
    userName: string | null;
    paymentComment: string | null;
    createdAt: number;
    poNumber: string | null;
    namePo: string | null;
    products: Array<OrderProduct>
}

export enum OrderTypes {
    IN = 0,
    PENDING = 1,
    PAID = 2
};