import InputMask from 'react-input-mask';
import {Input, InputGroup, InputLeftAddon} from "@chakra-ui/react";
import {FieldInputProps, FormikProps} from 'formik';
import React from 'react';

interface MaskedInputProps {
    field: FieldInputProps<any>;
    form: FormikProps<any>;

    [name: string]: any;
}

export const MaskedInput: React.FC<MaskedInputProps> = ({field, form, ...props}) => {
    return (
        <InputMask {...field} {...props} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            // const value = e.target.value.replace(/-/g, '');
            form.setFieldValue(field.name, e.target.value);
        }}>
            {() =>
                <InputGroup>
                    <InputLeftAddon>+1</InputLeftAddon>
                    <Input {...props} />
                </InputGroup>
            }
        </InputMask>
    );
};