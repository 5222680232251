import * as Yup from 'yup';
import {CommonValidators} from './CommonValidators';

export class OrderByCatalogValidator {
    public static GetSchema(): Yup.AnySchema {
        return this.ValidationSchema
    }

    private static ValidationSchema: Yup.AnySchema = Yup.object().shape({
        user: Yup.object().shape({
            name: CommonValidators.commonFieldValidator,
            email: CommonValidators.emailValidator,
            // phone: CommonValidators.phoneValidator,
        }),
        orderDetails: Yup.string()
            .required("Required field"),
        companyName: Yup.string()
            .required("Required field")
    })
}