import {Box} from "@chakra-ui/react"
import {ShowFilter} from "../../../components/Filter/ShowFilter";
import {FieldType} from "../../../common/constants";
import {BoxStyleConfig} from "../../../views/Forms/FormStyleConfigs";
import {ReactNode} from "react";

export type FilterConfig = {
    label: string,
    name: string,
    value: string,
    updateFunc: Function,
    typeField?: FieldType,
    options?: any,
}

/**
 * FilterInterceptor.
 *
 * This class is used to generate filter row
 * for any register and apply filter functions chain
 * to the register data
 *
 * Current implementation relies on data fetched
 * from API and stored in Redux store
 *
 * Filter function chain does not re-fetch data from API.
 * All data manipulations happen on existing data list from
 * Redux state. Also, filter chain does not mutate stored
 * data and returns copy of filtered array
 *
 */
export class FilterInterceptor {
    private filterMap: Map<string, FilterConfig> = new Map()
    private List: Array<any>

    constructor(list: Array<any>) {
        this.List = list
    }

    public clearFilters(): void {
        this.filterMap.clear()
    }

    public getFilterMap(): Map<string, FilterConfig> {
        return this.filterMap
    }

    public addFilters(filterConfigList: Array<FilterConfig>): void {
        filterConfigList.forEach(cfg => this.filterMap.set(cfg.name, cfg))
    }

    public addFilter(cfg: FilterConfig): void {
        this.filterMap.set(cfg.name, cfg)
    }

    public updateFilterValue(name: string, value: string): void {
        const filterConfig = this.filterMap.get(name);
        if (filterConfig) {
            filterConfig.value = value;
        }
    }

    public applyFilters(): Array<any> {
        try {
            if (!this.List) {
                throw new Error("No values to filter")
            }
            let filteredList: Array<any> = this.List

            this.filterMap.forEach((cfg: FilterConfig) => {
                filteredList = filteredList.filter(val => val)
            })
            return filteredList
        } catch (error) {
            return []
        }
    }

    public removeFilter(name: string): void {
        this.filterMap.delete(name)
    }

    /**
     * getFiltersRowWithCreateButton.
     *
     * Generates filter row with big functional
     * button with already attached functionality
     *
     * @param {ReactNode} createButton
     * @returns {FC}
     */
    public getFiltersRowWithCreateButton(): JSX.Element {
        let FilterList: Array<ReactNode> = [];
        this.filterMap.forEach((filter: FilterConfig) => {
            FilterList.push(
                <ShowFilter
                    specialLabel={filter.label}
                    key={FilterList.length + 1}
                    updateFunc={filter.updateFunc}
                    uniqueName={filter.name}
                    type={filter.typeField}
                    value={filter.value}
                    options={filter.options}
                />
            );
        });
        return (
            <Box {...BoxStyleConfig}>
                {FilterList}
            </Box>
        );
    }
}
