import * as Yup from "yup"
import {CommonValidators} from "./CommonValidators";

/**
 * Валидатор формы создания нового пользователя
 */
export class PasswordValidator {
    public static GetSchema(code?: string): Yup.AnySchema {
        return Yup.object().shape({
            NEW_PASSWORD: CommonValidators.passwordValidator,
            NEW_PASSWORD_AGAIN: Yup.string()
                .oneOf([Yup.ref('NEW_PASSWORD'), null], 'Passwords must match')
                .required('Required field'),
        });
    }

}
