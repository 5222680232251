import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {Option} from "../shared/utilities/OptionT";
import {SubscribeService} from "../service/mail/SubscribeService";

export class SubscribeController {
    private dispatch: Dispatch<PayloadAction<any>>
    private service: SubscribeService

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
        this.service = new SubscribeService(this.dispatch)
    }


    public async getSubscribeExcludeAll(params: any) {
        return this.service.getSubscribeExcludeAll(params).then(res => {
            return res
        })
    }

    public async getSubscribeList(params: any) {
        return this.service.getSubscribeList(params).then(res => {
            return res
        })
    }

    public async removeExcludeSubscribe(params: any) {
        return this.service.removeExcludeSubscribe(params).then(res => {
            return res
        })
    }

    public async addExcludeSubscribe(data: any) {
        return this.service.addExcludeSubscribe(data).then(res => {
            return res
        })
    }

    private static handleResult<T>(res: Option<T>): Option<boolean> {
        if (res?.None) {
            return {
                Some: false,
                None: res.None
            }
        }
        return {Some: true}
    }

}
