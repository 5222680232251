import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {AuthorizationService} from "../service/Authorization/AuthorizationService";
import {AuthorizationData} from "../shared/entities/Auth/AuthorizationData";
import {Privileges} from "../shared/entities/Role/Privileges";
import {RoleList} from "../shared/entities/Role/Role";
import {Option} from "../shared/utilities/OptionT";
import {RoleController} from "./RoleController";
import JSEncrypt from "jsencrypt-ext";

interface AuthorizationOperator {
    checkIfAuthorized(): Promise<Option<Array<Privileges>>>

    authorize(data: AuthorizationData): Promise<Option<RoleList>>
}

export class AuthorizationController implements AuthorizationOperator {
    private authorizationService: AuthorizationService
    private dispatch: Dispatch<PayloadAction<any>>

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
        this.authorizationService = new AuthorizationService(this.dispatch)
    }

    public async authorize(data: AuthorizationData): Promise<Option<RoleList>> {
        return this.authorizationService.authorize(data).then(res => res)
    }

    public async getPublicKey(): Promise<Option<any>> {
        return this.authorizationService.getPublicKey().then(res => res)
    }

    public async checkIfAuthorized(): Promise<Option<Array<Privileges>>> {
        return await this.authorizationService.checkAuth().then(res => res)
    }

    public async refresh(): Promise<boolean> {
        return await this.authorizationService.refresh().then(res => {
            if (res?.None || !res.Some) {
                return false;
            }
            return res.Some
        })
    }

    public async logOut(): Promise<boolean> {
        return await this.authorizationService.logOut().then(res => {
            if (res?.None || !res.Some) {
                return false;
            }
            return res.Some
        })

    }


    public async authorizeFunc(values: any, publicKey: string): Promise<any> {
        const roleController = new RoleController(this.dispatch)

        const jsEncryptEncrypt = (message: string, publicKey: string) => {
            const crypt = new JSEncrypt();
            crypt.setKey(publicKey);
            return crypt.encrypt(message);
        };

        const encryptedLogin = jsEncryptEncrypt(values.LOGIN, publicKey);
        const encryptedPassword = jsEncryptEncrypt(values.PASSWORD, publicKey);
        const authorizationData: AuthorizationData = {
            login: !encryptedLogin ? values.LOGIN : encryptedLogin,
            password: !encryptedPassword ? values.PASSWORD : encryptedPassword,
            loginNoCrypt: values.LOGIN
        };

        try {
            const res = await this.authorize(authorizationData);

            if (res.Some?.ROLES.length === 0) {
                throw new Error("Incorrect login or password");
            }

            const rolesResponse = await roleController.getRole(res?.Some?.ROLES.map(role => Number(role.ID)));
            if (rolesResponse?.Some.length === 0) {
                throw new Error("Incorrect login or password");
            }

            let privileges: Array<Privileges> = (rolesResponse?.Some?.map(elem => elem.PRIVILEGES).flat()).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.ID === value.ID && t.NAME === value.NAME
                    ))
            );

            return { privileges };
        } catch (error) {
            throw error;
        }
    }

}

