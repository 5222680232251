import {PayloadAction} from "@reduxjs/toolkit"
import {Reducer} from "react"
import {ExcludeSubscribe, SubscribeItem} from "../../shared/entities/Subscribe/ExcludeSubscribe";

export type SubscribeState = {
    excludeSubscribeList: { excludeSubscribe: Array<ExcludeSubscribe>, count: number },
    subscribeList: Array<SubscribeItem>
}
const State: SubscribeState = {
    subscribeList: [],
    excludeSubscribeList: {
        excludeSubscribe: [],
        count: 0
    }
}

export enum ActionTypes {
    EXCLUDE_SUBSCRIBE_LIST_ALL = "EXCLUDE_SUBSCRIBE_LIST_ALL",
    SUBSCRIBE_LIST_ALL = "SUBSCRIBE_LIST_ALL",
}

export const SubscribeReducer: Reducer<SubscribeState, PayloadAction<any>> = (state = State, action: PayloadAction<any>): SubscribeState => {
    switch (action.type) {
        case ActionTypes.EXCLUDE_SUBSCRIBE_LIST_ALL:
            return state = {
                ...state,
                excludeSubscribeList: action.payload,
            }
        case ActionTypes.SUBSCRIBE_LIST_ALL:
            return state = {
                ...state,
                subscribeList: action.payload,
            }
        default:
            return state
    }
}


