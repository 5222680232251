import {CustomError} from "../../shared/errors/CustomErrors";
import {Option} from "../../shared/utilities/OptionT";
import {APIDao, Requester} from "../APIRequester";
import {ExcludeSubscribe} from "../../shared/entities/Subscribe/ExcludeSubscribe";
import {SUBSCRIBE_API_PREFIX} from "../../common/constants";

/**
 * UserRepository.
 *
 * Responsible for fetching data from backend
 * by callind APIDao and handling various data
 * coming from API
 *
 * Data is passed to UserService in a form of
 * an @type Option<T> type, where @param{Some: T} is either
 * a valid data, expected from the server,
 * or, in case of error, a default safe value,
 * for an object of array (kinda like default
 * values for types in Go),and @param{None: CustomError}
 * is either an error or undefined
 */
export class SubscribeRepository<T> {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    public async addExcludeSubscribe(data: any): Promise<Option<any>> {
        return await this.dao.postRequest<any>(`${SUBSCRIBE_API_PREFIX}/exclude-add`, {data}).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }
    public async removeExcludeSubscribe(params: {}): Promise<Option<any>> {
        return await this.dao.postRequest<any>(`${SUBSCRIBE_API_PREFIX}/exclude-remove`, {params: {...params}}).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }

    public async getSubscribeList(params: {}): Promise<Option<any>> {
        return await this.dao.getRequest<{}>(`${SUBSCRIBE_API_PREFIX}/emails`, {}).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async getSubscribeExcludeAll(params: {}): Promise<Option<{
        excludeSubscribe: Array<ExcludeSubscribe>,
        count: number
    }>> {
        return await this.dao.getRequest<{
            excludeSubscribe: Array<ExcludeSubscribe>,
            count: number
        }>(`${SUBSCRIBE_API_PREFIX}/exclude-all`, {params: {...params}}).then(res => {
            const result: Option<{ excludeSubscribe: Array<ExcludeSubscribe>, count: number }> = {
                Some: {
                    excludeSubscribe: res?.data?.excludeSubscribe,
                    count: res?.data?.count
                }
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<{ excludeSubscribe: Array<ExcludeSubscribe>, count: number }> = {
                //@ts-ignore
                Some: {
                    excludeSubscribe: [],
                    count: 0
                },
                None: err
            }
            return result
        })
    }
}

export const SubscribeRepositoryInstance = new SubscribeRepository(APIDao)
