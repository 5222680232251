import React, {FC, useState} from 'react';
import {Box, Button, Flex, FormControl, Image, Text, useToast} from '@chakra-ui/react';
import {useDispatch} from "react-redux";
import {SetBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {BoxStyleConfig, FormStyleConfig} from "../Forms/FormStyleConfigs";
import OrderCatalogImage from "../../assets/img/order_catalog.jpg";
import {MainButton} from "../../components/Buttons/MainButton";
import {Loader} from "../../components/Loader/Loader";
import {Field, Form, Formik} from "formik";
import {OrderByCreateRequest} from "../../shared/entities/Users/User";
import {LabelStyled} from "../../components/Inputs/LabelStyled";
import {InputControlStyled} from "../../components/Inputs/InputControlStyled";
import {TextareaControl} from "formik-chakra-ui";
import {OrderByCatalogValidator} from "../../controllers/Validation/OrderByCatalogValidator";
import {UserController} from "../../controllers/UserController";
import {useParams} from "react-router";
import {MaskedInput} from "../../components/Inputs/MaskedInput";

const OrderByCatalog: FC<{ links: Array<BreadcrumbsData> }> = (props) => {
    const dispatch = useDispatch()
    SetBreadcrumbsLinks(dispatch, props.links)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const userController = new UserController(dispatch);
    const {id} = useParams();
    const toast = useToast();

    const [initialValues, setInitialValues] = useState<any>({
        user: {name: "", email: "", phone: ""},
        orderDetails: "",
        companyName: "",
    });

    const GreatSaveCardConfig = {
        marginX: "auto",
        minHeight: "781px",
        pageTop: 5,
        // background: "primary.gray.light",
        borderRadius: "9px",
        w: {
            sm: "100%",
            md: "100%",
            lg: "100%",
            xl: "100%"
        },
        h: {
            sm: "100%",
            lg: "100%"
        },
        // position: "relative",
        justifyContent: "center"
    }
    const OrderCatalogFormCardConfig = {
        // marginX: "auto",
        w: {
            sm: "100%",
            md: "80%",
            lg: "80",
            xl: "60%"
        },
        h: {
            sm: "50%",
            lg: "50%"
        },
        justifyContent: "center"
    }
    const inputProperties = {
        maxW: "auto",
        autoComplete: "new-password"
    };

    return (
        <Flex {...GreatSaveCardConfig} direction={{base: "column", lg: "row"}} pt={10}>
            <Box w={{base: "100%", lg: "35%"}}>
                <Box pl={{lg: 10}}>
                    <Image src={OrderCatalogImage} minWidth={{base: "100%", lg: "410px"}}
                           minHeight={{base: "100%", lg: "410px"}} cursor={"pointer"}
                           onClick={() => window.open('https://autoformsandsupplies.cld.bz/AUTO-CAT', "_blank")}
                    />
                </Box>
            </Box>
            <Box w={{base: "100%", lg: "65%"}} pl={{lg: 8}}>

                <Text fontSize={{base: 40, lg: 42}}
                      fontWeight={"md"}
                      letterSpacing={"-0.02em"}
                      lineHeight={{base: "110%", lg: "110%"}}
                      w={{base: "82%", lg: "75%"}}>Didn't find the product?
                    Order from the catalog!</Text>
                <Text py={5} fontSize={20} fontWeight={"sm"} lineHeight={"140%"}>If you do not find the products you
                    need on the site, you can choose them from the catalog. Find products and place an order using the
                    form below. We will calculate your order and contact you.</Text>
                <Box pb={10}>
                    <Button height={"48px"} width={"262px"} background={"gray.800"} color={"white"} maxH={"40px"}
                            borderRadius={5}
                            onClick={() => window.open(`https://autoformsandsupplies.cld.bz/AUTO-CAT${id ? "/" + id : ""}`, "_blank")}>Open
                        the
                        Catalog online
                    </Button>
                </Box>
                <Box {...OrderCatalogFormCardConfig} py={4}>
                    {isLoading ? <Loader/> :
                        <Formik
                            initialValues={initialValues}
                            onSubmit={async (values, actions) => {
                                actions.setSubmitting(true)
                                setIsLoading(true);
                                const orderByCreateRequest: OrderByCreateRequest = {
                                    name: values.user.name ? values.user.name : "",
                                    email: values.user.email ? values.user.email : "",
                                    phone: values.user.phone ? values.user.phone : "",
                                    orderDetails: values.orderDetails ? values.orderDetails : "",
                                    companyName: values.companyName ? values.companyName : "",
                                };
                                await userController.orderByCatalogCreate(orderByCreateRequest).then(res => {
                                    actions.setSubmitting(false)
                                    toast({
                                        position: "top",
                                        title: res.None ? 'Failed request' : 'Your request was received. Representetive will contact you shortly.!',
                                        status: res.None ? 'error' : 'success',
                                        duration: 5000,
                                        isClosable: true,
                                    })
                                })
                                setIsLoading(false);
                            }}
                            validate={values => {
                                setInitialValues(values)
                            }}
                            enableReinitialize={true}
                            validationSchema={OrderByCatalogValidator.GetSchema()}
                        >
                            {({isSubmitting, touched, errors, values, dirty, isValid, handleSubmit, setFieldValue}) => (
                                <FormControl {...FormStyleConfig} pt="unset">
                                    <Form onSubmit={handleSubmit}>
                                        <Box {...BoxStyleConfig} minWidth={{base: "100%", lg:"480px"}} maxWidth={{base: "100%", lg:"480px"}}>
                                            <LabelStyled>Order details:</LabelStyled>
                                            <TextareaControl style={{fontSize: "14px", fontWeight: "400"}}
                                                             name={"orderDetails"}
                                                             textareaProps={{
                                                                 w: {base: "100%", lg:"448px"},
                                                                 placeholder: 'Product IDs, amount, other details',
                                                                 fontSize: "14",
                                                                 fontWeight: "sm"
                                                             }}
                                            />
                                            <Flex direction={{base: "column", lg: "row"}}>
                                                <Box w={{base: "100%", lg: "236px"}}>
                                                    <LabelStyled>Company Name</LabelStyled>
                                                    <InputControlStyled name="companyName"
                                                                        inputProps={inputProperties}/>
                                                </Box>
                                                <Box pl={{lg:2}}>
                                                    <LabelStyled>Your Name</LabelStyled>
                                                    <InputControlStyled name="user.name" inputProps={inputProperties}/>
                                                </Box>
                                            </Flex>
                                            <Flex direction={{base: "column", lg: "row"}}>
                                                <Box w={{base: "100%", lg: "236px"}}>
                                                    <LabelStyled>Phone</LabelStyled>
                                                    <Field name="user.phone" component={MaskedInput} mask="***-***-****"
                                                           maskChar={null} placeholder="000-000-0000"/>
                                                </Box>
                                                <Box pl={{lg:2}}>
                                                    <LabelStyled>E-mail</LabelStyled>
                                                    <InputControlStyled name="user.email" inputProps={inputProperties}/>
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box pt={6}>
                                            <MainButton
                                                w={"156px"}
                                                minHeight={"40px"}
                                                borderColor="gray.500"
                                                border={"2px"}
                                                type="submit"
                                                isLoading={isSubmitting}
                                            >
                                                {'Send Request'}
                                            </MainButton>
                                        </Box>

                                    </Form>
                                </FormControl>
                            )}
                        </Formik>
                    }
                </Box>
            </Box>
        </Flex>
    );
}

export default OrderByCatalog;
