import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {Product} from "../../shared/entities/Product/Product";
import {ActionTypes} from "../../redux/Cart/CartReducer";


export class CartService {

    private dispatch: Dispatch<PayloadAction<any>>

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
    }

    public addProductToCart(product: Product) {
        this.dispatch({type: ActionTypes.ADD_TO_CART, payload: product})
    }

    public removeFromCart(product: Product) {
        this.dispatch({type: ActionTypes.REMOVE_FROM_CART, payload: product})
    }

    public removeAllCart() {
        this.dispatch({type: "REMOVE_ALL_CART", payload: []});
    }
}
