import {
    Box,
    Text,
    ChakraProps,
    Input,
    Select,
    Checkbox, InputRightElement, InputGroup, Flex
} from "@chakra-ui/react"
import React, {FC, useEffect, useState} from "react"
import {BoxRowStyleConfig} from "../../views/Forms/FormStyleConfigs";
import {FilterListButton} from "../Buttons/FilterListButton";
import {DatePickerUpdateStyled} from "../DatePicker/DatePicker";
import {FaSearch, FaWindowClose} from "react-icons/fa";
import {FieldType} from "../../common/constants";
import {format} from "date-fns";

export const ShowFilter: FC<{
    specialLabel?: string,
    uniqueName: string,
    updateFunc: Function,
    type?: FieldType,
    options?: any,
    style?: ChakraProps,
    value?: string;
    modal?: boolean;
}> = ({specialLabel, uniqueName, updateFunc, type, options, style, value, modal = false}) => {
    const [val, setVal] = useState<string>(value || "");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const height = "30px"
    const FilterDefaultStyleConfig: ChakraProps = {
        h: height,
        w: type === FieldType.DateRange && !modal ? "400px" : "200px",
        fontSize: "14px",
        color: "gray.500",
        // paddingRight: "60px",
        padding:1,
        background: !modal ? "#EFEFEF" : "white",
        borderColor: "gray.500",
        _active: {
            borderColor: "gray.500"
        },
        _focus: {
            border: "2px solid",
            borderColor: "gray.500"
        },
        ...style
    }

    useEffect(() => {
        setVal(value || "");
    }, [value]);

    const update = (value: string) => {
        const x = {}
        //@ts-ignore
        x[uniqueName] = value
        setVal(value)
        updateFunc(x, modal)
    }
    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            setVal(event.target.value)
            update(event.target.value)
        }
    }

    let filterElement;
    switch (type) {
        case FieldType.Select:
            filterElement = (
                <Select
                    {...FilterDefaultStyleConfig} pr="0px" value={val}
                    onChange={(ev: any) => update(ev.target.value)}
                    children={
                        options?.map((elem: any) => {
                            return <option key={elem.id} value={elem.id} children={elem.name}/>
                        })
                    }
                />
            );
            break;

        case FieldType.Checkbox:
            filterElement = (
                <Box {...FilterDefaultStyleConfig} colorScheme="gray"
                     onChange={(ev: any) => update(ev.target.value)}
                     styleConfig={{flexDir: "row", display: "flex"}}>
                    {options?.map((elem: any) => <Checkbox colorScheme={"teal"} key={elem.id} value={elem.id}
                                                           children={elem.name} mr="10px"/>)}
                </Box>
            );
            break;
        case FieldType.DateRange:
            if (modal) {
                filterElement = (
                    <>
                        <Box {...BoxRowStyleConfig}>
                            <InputGroup>
                                <DatePickerUpdateStyled placeholderText={"From Date"}
                                                        {...FilterDefaultStyleConfig}
                                                        onChange={(value) => setStartDate(value ? format(value, 'MM-dd-yyyy') : '')}
                                />
                                <span style={{height: '40px', width: '30px', fontSize: "24px"}}> - </span>
                                <DatePickerUpdateStyled placeholderText={"End Date"}
                                                        {...FilterDefaultStyleConfig}
                                                        onChange={(value) => setEndDate(value ? format(value, 'MM-dd-yyyy') : '')}
                                />
                                {(startDate.length && endDate.length) > 0 &&
                                    <InputRightElement h={"40px"} cursor="pointer">
                                        <FaSearch onClick={() => update(startDate + "  To: " + endDate)}/>
                                    </InputRightElement>
                                }
                            </InputGroup>
                        </Box>
                    </>
                );
            } else {
                filterElement = (
                    <Input
                        {...FilterDefaultStyleConfig}
                        value={val}
                        onChange={(ev: any) => setVal(ev.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                )
            }
            break;
        case FieldType.Date:
            if (modal) {
                filterElement = (
                    <InputGroup>
                        <DatePickerUpdateStyled value={val}
                                                {...FilterDefaultStyleConfig}
                                                onChange={(value) => setVal(value ? format(value, 'MM-dd-yyyy') : '')}
                        />
                        <InputRightElement h={"40px"} cursor="pointer">
                            <FaSearch onClick={() => update(val)}/>
                        </InputRightElement>
                        {val.length > 0 && <InputRightElement right="25px" h={"40px"} cursor="pointer">
                            <FaWindowClose onClick={() => update("")}/>
                        </InputRightElement>}
                    </InputGroup>
                );
            } else {
                filterElement = (
                    <Input
                        {...FilterDefaultStyleConfig}
                        value={val}
                        onChange={(ev: any) => setVal(ev.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                )
            }
            break;
        case FieldType.Input:
            if (!modal) {
                filterElement = (
                    <Input
                        {...FilterDefaultStyleConfig}
                        value={val}
                        onChange={(ev: any) => setVal(ev.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                );
            } else {
                filterElement = (
                    <InputGroup>
                        <Input
                            {...FilterDefaultStyleConfig}
                            value={val}
                            onChange={(ev: any) => setVal(ev.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                        <InputRightElement h={height} cursor="pointer">
                            <FaSearch onClick={() => update(val)}/>
                        </InputRightElement>
                        {val.length > 0 && <InputRightElement right="25px" h={height} cursor="pointer">
                            <FaWindowClose onClick={() => update("")}/>
                        </InputRightElement>}
                    </InputGroup>
                );
            }
            break;
        default:
            filterElement = (
                <Input
                    {...FilterDefaultStyleConfig}
                    value={val}
                    onChange={(ev: any) => setVal(ev.target.value)}
                    onKeyPress={handleKeyPress}
                />
            );
    }

    return (
        <Flex  mb={"5px"} fontFamily={"Poppins"} fontWeight={"300"} alignItems={"center"}>
            {!modal &&
                <Text fontSize={{base:"18px",lg:"24px"}} px={1} whiteSpace="nowrap">
                    {specialLabel ? specialLabel.charAt(0).toUpperCase() + specialLabel.slice(1).toLowerCase() + ":" : ""}
                </Text>
            }
            {filterElement}
            {!modal &&
                <>
                    <FilterListButton onClick={() => update(val)}>Filter</FilterListButton>
                    <FilterListButton onClick={() => update("")}>Reset</FilterListButton>
                </>
            }
        </Flex>
    )
}