import * as Yup from 'yup';
import {CommonValidators} from './CommonValidators';

export class RegistrationValidator {
    public static GetSchema(): Yup.AnySchema {
        return this.ValidationSchema
    }

    private static ValidationSchema: Yup.AnySchema = Yup.object().shape({
        company: Yup.object().shape({
            // name: CommonValidators.commonFieldValidator,
            email: CommonValidators.emailValidator,
            phone: CommonValidators.phoneValidator,
        }),
        user: Yup.object().shape({
            name: CommonValidators.commonFieldValidator
        })
    })
}