import { Box, ChakraProps, Flex, Link } from "@chakra-ui/react";
import { FC, MouseEventHandler, ReactNode } from "react";
import {CSSObject} from "@emotion/react";

/**
 * ListItemConfig.
 */
export type ListItemConfig = {
    content: string | number | ReactNode
    isLink: boolean,
    clickAction?: Function,
    style?: ChakraProps
}

/**
 * Строка в списочных компонентах, используется, например,
 *  в реестрах
 * @param param0 
 * @returns 
 */
export const ListItemRow: FC<{ list: Array<ListItemConfig>, noBorder?: boolean, style?: ChakraProps, clickAction?: MouseEventHandler<HTMLDivElement> }> = ({ list, noBorder, style, clickAction , children }) => {
    const DefaultListItemConfig: ChakraProps = {
        borderBottom: noBorder ? "none" : "1px solid #71809640",
        h: "auto",
        w: "100%",
        justifyContent: "space-between",
        ...style
    }
    const List: Array<ReactNode> = list.map((config: ListItemConfig, idx: number) => {
        return <ListItem key={idx} {...config} />
    })

    const ExtendedListConfig: ChakraProps = {
        padding: "0 0 10px 0",
        background: "#E2E8F0",
    }

    const BoxConfig: ChakraProps = {
        width: "100%",
        padding: !!children ? "10px 0 10 0" : "",
        background: !!children ? "#E2E8F0" : "",
        transition: "background 0.3s",
    }

    return (
      <Box {...BoxConfig}>
          <Flex {...DefaultListItemConfig} onClick={clickAction ? clickAction : () => { }}>
              {List}
          </Flex>
          {!!children && (
            <Box {...ExtendedListConfig}>
                {children}
            </Box>
          )}

      </Box>

    )
}

export const ListLinkItemDefaultStyleConfig: CSSObject = {
    color: "gray.400",
    border: "1px solid",
    borderColor: "transparent",
    borderRadius: "5px",
    _hover: {
        color: "gray.400",
        borderRadius: "5px",
    },
};



/**
 * Ячейка в строке списка
 * @param param0 
 * @returns 
 */
const ListItem: FC<ListItemConfig> = ({ isLink, content, clickAction, style }) => {
    const ListItemDefaultStyleCOnfig: ChakraProps = {
        display: "flex",
        flexDir: "row",
        justifyContent: "center",
        alignItems: "center",
        flexBasis: "100%",
        w: "fit-content",
        textAlign: "center",
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "gray.700",
        textOverflow: "ellipsis",
        p: "5px 0px",
        ...style
    }


    return (
        <Box {...ListItemDefaultStyleCOnfig} onClick={() => clickAction && clickAction()}>
            {isLink ? <Link sx={ListLinkItemDefaultStyleConfig}>{content}</Link> : content}
        </Box>
    )
}
