import {IMAGE_API_PREFIX} from "../../common/constants";
import {URLPaths} from "../../config/application/URLPaths";
import {BreadLink, Product, ProductClass} from "../entities/Product/Product";
import {BreadcrumbsData} from "../entities/Breadcrumbs/BreadCrumbsData";

export class WebPUtils {
    static getProxy() {
        return process.env.NODE_ENV === "development" ? "http://localhost:8080/" : `https://${window.location.host}/`;
    }

    static getImageUrl(sizeImage: string, uuid: string, isRender: boolean) {
        return `${this.getProxy()}${IMAGE_API_PREFIX}/${sizeImage.toLowerCase()}/${uuid}.${isRender ? 'jpg' : this.canUseWebP()}`;
    }

    static getImageJpgUrl(sizeImage: string, uuid: string) {
        return `${this.getProxy()}${IMAGE_API_PREFIX}/${sizeImage.toLowerCase()}/${uuid}.jpg`;
    }

    static getIOSVersion(): [number, number, number] {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            const match = navigator.appVersion.match(/OS (\d+)_?(\d+)?_?(\d+)?/);
            console.log("match", match)
            if (match) {
                return [
                    parseInt(match[1], 10),
                    parseInt(match[2] || '0', 10),
                    parseInt(match[3] || '0', 10)
                ];
            }
        }
        return [0, 0, 0];
    }

    static canUseWebP() {
        // return "jpg";
        // // if (document.cookie.includes('is_rendertron=true')) {
        // //     return "jpg";
        // // }
        const [majorIOSVersion] = this.getIOSVersion();

        if (majorIOSVersion > 0) {
            // iOS device detected
            if (majorIOSVersion >= 14) {
                return "webp"; // iOS version 14 or higher
            } else {
                return "jpg"; // iOS version below 14
            }
        } else {
            // Not an iOS device, use canvas to check WebP support
            const elem = document.createElement('canvas');

            if (elem.getContext && elem.getContext('2d')) {
                // Check if the browser can render WebP
                if (elem.toDataURL('image/webp').indexOf('data:image/webp') === 0) {
                    return "webp";
                } else {
                    return "jpg";
                }
            } else {
                // Very old browser like IE 8, canvas not supported
                return "jpg";
            }
        }
    }

    static getProductLink = (item: Product): string => {
        const isFullUrl = item.url.startsWith('http://') || item.url.startsWith('https://');
        return isFullUrl ? item.url : `${URLPaths.PRODUCTS.link}${item.url}`;
    };

    static getProductBreadLink = (item: BreadLink): string => {
        return `${URLPaths.PRODUCTS.link}${item.url}`;
    };

    static generateBreadcrumbs = (product: ProductClass | undefined): BreadcrumbsData[] => {
        return [
            URLPaths.HOME,
            ...(product?.breadcrumbs || [])
                .slice(1)
                .map((breadcrumb) => ({
                    link: this.getProductBreadLink(breadcrumb),
                    text: breadcrumb.value,
                })),
            {
                link: this.getProductLink(product || new ProductClass()),
                text: product?.name || "",
            },
        ];
    };
}