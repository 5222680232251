import {PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "react"
import {ProductRepositoryInstance} from "../../repository/Product/ProductRepository";
import {ActionTypes} from "../../redux/Product/ProductReducer";
import {
    ProductChangeOrderBy,
    ProductChangeParent,
    ProductChangeType,
    ProductRequest,
    ProductSetArchive
} from "../../shared/entities/Product/Product";

/**
 * ClientService.
 *
 */
export class ProductService {
    private dispatch: Dispatch<PayloadAction<any>>
    private repository = ProductRepositoryInstance

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
    }

    public async getProducts(params: {}) {
        return this.repository.getProducts(params).then(res => {
            this.dispatch({
                type: ActionTypes.PRODUCT_LIST,
                payload: res.Some
            })
            return res
        })
    }

    public async getProductsIds(params: {}) {
        return this.repository.getProductsIds(params).then(res => {
            /*this.dispatch({
                type: ActionTypes.PRODUCT_LIST,
                payload: res.Some
            })*/
            return res
        })
    }

    public async getProduct(id: number) {
        return this.repository.getProduct(id).then(res => {
            this.dispatch({
                type: ActionTypes.PRODUCT_GET,
                payload: res?.Some || []
            })
            return res
        })
    }


    public async changeTypeProduct(data: ProductChangeType) {
        return await this.repository.changeTypeProduct(data).then(res => {
            return res
        })
    }

    public async changeParentProduct(data: ProductChangeParent) {
        return await this.repository.changeParentProduct(data).then(res => {
            return res
        })
    }

    public async changeOrderByProduct(data: ProductChangeOrderBy) {
        return await this.repository.changeOrderByProduct(data).then(res => {
            return res
        })
    }

    public async setArchiveProduct(data: ProductSetArchive) {
        return await this.repository.setArchiveProduct(data).then(res => {
            return res
        })
    }

    public async createProduct(data: ProductRequest) {
        return await this.repository.createProduct(data).then(res => {
            this.dispatch({
                type: ActionTypes.CREATE_PRODUCT,
                payload: res.Some
            })
            return res
        })
    }

    public async deleteProduct(id: number) {
        return await this.repository.deleteProduct(id)
    }
}
