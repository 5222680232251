import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Box, Text, FormControl, InputGroup, InputRightElement, useToast} from "@chakra-ui/react";
import {BreadcrumbsData} from "../../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {UserController} from "../../../controllers/UserController";
import {MainButton} from "../../../components/Buttons/MainButton";
import {Form, Formik} from "formik";
import {FormStyleConfig} from "../FormStyleConfigs";
import {LabelStyled} from "../../../components/Inputs/LabelStyled";
import {InputControlStyled} from "../../../components/Inputs/InputControlStyled";
import {PasswordValidator} from "../../../controllers/Validation/PasswordValidator";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";

import {ModalService} from "../../../service/Modal/ModalService";
import {ChangePasswordRequest} from "../../../shared/entities/Users/User";
import {URLPaths} from "../../../config/application/URLPaths";
import {useNavigate} from "react-router";
import {SetBreadcrumbsLinks} from "../../../redux/Breadcrumbs/BreadcrumbsReducer";
import {headingFabric, HeadingType} from "../../../components/Headings/Heading";
import {useAppSelector} from "../../../redux/Store";
import {AuthorizationController} from "../../../controllers/AuthController";
import {ActionTypes} from "../../../redux/Role/RoleReducer";
import {buildPrivilegesMap, usePrivileges} from "../../../hooks/PrivilegesProvider";
import {authErrorHandler} from "../../../hooks/AuthHook";

export const ChangePassword: FC<{
    links: Array<BreadcrumbsData>,
    onPasswordChange?: () => void
}> = ({links, onPasswordChange}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userController = new UserController(dispatch)
    const modalService = new ModalService(dispatch)
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [code, setCode] = useState('');
    const loginForCode = useAppSelector(state => state.UserReducer.loginForCode);
    const authorizationControllerInstance = new AuthorizationController(dispatch)
    const publicKey = useAppSelector((state) => state.AuthReducer.publicKey);
    const {setPrivileges} = usePrivileges();
    const isLoginAvailable = !!(sessionStorage.getItem("LOGIN") ?? loginForCode);
    const toast = useToast();

    SetBreadcrumbsLinks(dispatch, links)
    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const codeParam = urlParams.get('code');
        if (codeParam) {
            setCode(codeParam);
        }
    }, []);

    const UserFormInitialValues = {
        CURRENT_PASSWORD: "",
        LOGIN: sessionStorage.getItem("LOGIN") ?? loginForCode,
        NEW_PASSWORD: "",
        NEW_PASSWORD_AGAIN: "",
    }

    useEffect(() => {
        if (code && userController) {
            userController.getLoginCode(code)
        }
    }, [code])

    const ChangePasswordFormCardConfig = {
        marginX: "auto",
        w: {
            base: "100%",
            md: "100%",
            lg: "50%"
        }
    }


    return (
        <Box pt={{base: 0, lg: 4}} {...ChangePasswordFormCardConfig}>
            <Formik
                initialValues={UserFormInitialValues}
                onSubmit={async (values, actions) => {
                    actions.setSubmitting(true);
                    const changePasswordRequest: ChangePasswordRequest = {
                        code: code ?? null,
                        login: sessionStorage.getItem("LOGIN") ?? "",
                        newPassword: values.NEW_PASSWORD,
                        oldPassword: values.CURRENT_PASSWORD ?? "",
                    };
                    try {
                        const res = await userController.changePassword(changePasswordRequest);
                        if (res?.None) {
                            modalService.setModalData({
                                onSubmit: () => modalService.deleteModalData(),
                                isOpen: true,
                                onOpen: console.log,
                                onClose: () => modalService.deleteModalData(),
                                modalTextData: (res?.None?.getErrorDescription ? "Change Password error: " + res?.None?.getErrorDescription() : "error server"),
                                context: "error"
                            });
                        } else {
                            if (!sessionStorage.getItem("LOGIN") && loginForCode) {
                                try {
                                    const {privileges} = await authorizationControllerInstance.authorizeFunc({
                                        LOGIN: loginForCode,
                                        PASSWORD: changePasswordRequest.newPassword
                                    }, publicKey);
                                    dispatch({type: ActionTypes.PRIVILEGES_GET, payload: privileges});
                                    setPrivileges(buildPrivilegesMap(privileges));
                                    actions.setSubmitting(false);
                                    authErrorHandler.handleAuthChange(true);
                                    navigate(URLPaths.HOME.link);
                                } catch (error) {
                                    actions.setFieldError("LOGIN", " ");
                                    actions.setFieldError("PASSWORD", "Incorrect login or password");
                                    actions.setSubmitting(false);
                                }
                            }
                            if (onPasswordChange) {
                                onPasswordChange();
                            }
                            toast({
                                position: "top",
                                title: 'Your password has been changed!',
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                            })
                        }
                    } catch (error) {
                        actions.setSubmitting(false);
                        console.error(error);
                    }
                }}
                enableReinitialize={true}
                validationSchema={PasswordValidator.GetSchema()}
            >

                {({isSubmitting, handleSubmit, touched, errors}) => (
                    <FormControl {...FormStyleConfig}  >
                        <Form onSubmit={handleSubmit}>
                            <Box pb={{base: 0, lg: 6}}>
                                {headingFabric.Generate({
                                    type: HeadingType.FORM_HEADING,
                                    text: code ? "Set a password" : "Change password"
                                })}
                            </Box>

                            {code ?
                                <Box pr={8}>
                                    <InputControlStyled name="LOGIN"
                                                        isDisabled={true}
                                                        inputProps={{type: "text", maxW: "auto"}}/>
                                </Box>
                                :
                                <Box pb={1}>
                                    <LabelStyled>Old Password</LabelStyled>
                                    <InputGroup pr={8}>
                                        <InputControlStyled
                                            name="CURRENT_PASSWORD"
                                            inputProps={{
                                                type: showOldPassword ? "text" : "password",
                                                maxW: "auto"
                                            }}
                                        />
                                        <InputRightElement>
                                            {showOldPassword ? (
                                                <ViewOffIcon cursor="pointer"
                                                             onClick={() => setShowOldPassword(false)}/>
                                            ) : (
                                                <ViewIcon cursor="pointer"
                                                          onClick={() => setShowOldPassword(true)}/>
                                            )}
                                        </InputRightElement>
                                    </InputGroup>
                                </Box>
                            }
                            <Box pb={6}>
                                <Box width={"95%"}>
                                    <LabelStyled minW={"6.5rem"}>{`New password:`}</LabelStyled>
                                    {/*{touched.NEW_PASSWORD && errors.NEW_PASSWORD &&*/}
                                    {/*    <Box fontSize={14}>*/}
                                    {/*        <Text>{`1 capital letter`}</Text>*/}
                                    {/*        <Text>{`1 number`}</Text>*/}
                                    {/*        <Text>{`6 corrector long`}</Text>*/}
                                    {/*    </Box>*/}
                                    {/*}*/}
                                </Box>
                                <InputGroup pr={8}>
                                    <InputControlStyled
                                        name="NEW_PASSWORD"
                                        inputProps={{
                                            type: showNewPassword ? "text" : "password",
                                            maxW: "auto"
                                        }}
                                    />
                                    <InputRightElement>
                                        {showNewPassword ? (
                                            <ViewOffIcon cursor="pointer"
                                                         onClick={() => setShowNewPassword(false)}/>
                                        ) : (
                                            <ViewIcon cursor="pointer"
                                                      onClick={() => setShowNewPassword(true)}/>
                                        )}
                                    </InputRightElement>
                                </InputGroup>

                                <Box pr={8}>
                                    <LabelStyled>New password again</LabelStyled>
                                    <InputGroup>
                                        <InputControlStyled
                                            name="NEW_PASSWORD_AGAIN"
                                            inputProps={{type: showNewPassword ? "text" : "password", maxW: "auto"}}
                                        />
                                    </InputGroup>
                                </Box>
                            </Box>
                            <MainButton
                                minH={10}
                                minWidth="210px"
                                type="submit"
                                borderColor="gray.500"
                                border={"2px"}
                                isLoading={isSubmitting}
                                isDisabled={!isLoginAvailable || isSubmitting}
                                loadingText="Sent data"
                            >
                                {code ? 'Update and login' : 'Update'}
                            </MainButton>

                        </Form>
                    </FormControl>
                )}
            </Formik>
        </Box>
    )
}
export default ChangePassword;