import React, {useState} from "react";
import {Box, Flex, useBreakpointValue} from "@chakra-ui/react";
import {FilterModal} from "../Modals/FilterModal";
import {FieldType} from "../../common/constants";
import {Icon} from "@chakra-ui/icons";
import {CustomFilterIcon} from "../Inputs/Icons/Icons";

interface FilterSortHeaderProps {
    headerText: string;
    filterName: string;
    onFilterClick?: () => void;
    onSortUpClick?: () => void;
    onSortDownClick?: () => void;
    setFilterModalClose: () => void;
    setNewFilterValue: (val: any, modal: boolean) => void;
    isFilterActive: boolean;
    isFilterOpen: boolean;
    fieldType?: FieldType;
    options?: any;
}

const FilterSortHeader: React.FC<FilterSortHeaderProps> = ({
                                                               headerText,
                                                               filterName,
                                                               onFilterClick,
                                                               onSortUpClick,
                                                               onSortDownClick,
                                                               setFilterModalClose,
                                                               isFilterActive,
                                                               isFilterOpen,
                                                               setNewFilterValue,
                                                               fieldType,
                                                               options
                                                           }) => {
    const [buttonPosition, setButtonPosition] = useState({top: 0, left: 0});
    let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false})

    const handleImageClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setButtonPosition({top: rect.bottom, left: isMobile ? rect.left - 40 : rect.left});

        if (onFilterClick) {
            onFilterClick();
        }
    };

    return (
        <Flex alignItems="center">
            <Box>{headerText}</Box>
            <Box ml="1" onClick={handleImageClick} style={{cursor: "pointer"}}>
                <Icon
                    as={CustomFilterIcon}
                    alt="Filter"
                    style={{
                        width: "13px",
                        filter: isFilterActive ? "invert(41%) sepia(60%) saturate(5366%) hue-rotate(207deg) brightness(91%) contrast(83%)" : "none"
                    }}
                    w={"18px"}
                    h={"18px"}
                    cursor={"pointer"}
                />
            </Box>
            <FilterModal
                isOpen={isFilterOpen}
                onClose={setFilterModalClose}
                buttonPosition={buttonPosition}
                setNewFilterValue={setNewFilterValue}
                uniqueName={filterName}
                fieldType={fieldType}
                options={options}/>
        </Flex>
    );
};

export default FilterSortHeader;
