import { Box, Text } from "@chakra-ui/react";

interface InfoBoxProps {
    label: string;
    content: string | string[];
}

export const InfoBox: React.FC<InfoBoxProps> = ({ label, content }) => (
    <Box py={4} px={5} h="100%" width="100%" borderRadius="5px" border="1px" borderColor="gray.200">
        <Text fontWeight="md" minH={5}>
            {label}
        </Text>
        <Text color="primary.text" fontWeight="sm" minH={5}>
            {content}
        </Text>
    </Box>
);
