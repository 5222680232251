import React, {FC} from "react";
import {useDispatch} from "react-redux";
import {Box, Flex, FormControl, Text} from "@chakra-ui/react";
import {BreadcrumbsData} from "../../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {UserController} from "../../../controllers/UserController";
import {MainButton} from "../../../components/Buttons/MainButton";
import {Form, Formik} from "formik";
import {FormStyleConfig} from "../FormStyleConfigs";
import {LabelStyled} from "../../../components/Inputs/LabelStyled";
import {InputControlStyled} from "../../../components/Inputs/InputControlStyled";
import {ModalService} from "../../../service/Modal/ModalService";
import {URLPaths} from "../../../config/application/URLPaths";
import {useNavigate} from "react-router";
import {RestoreValidator} from "../../../controllers/Validation/RestoreValidator";
import {RestoreRequest} from "../../../shared/entities/Users/User";


export const RestorePassword: FC<{ links: Array<BreadcrumbsData> }> = ({links}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userController = new UserController(dispatch)
    const modalService = new ModalService(dispatch)
    const RestorePasswordFormCardConfig = {
        marginX: "auto",
        w: {
            base: "100%",
            md: "50%",
            lg: "32%"
        }
    }

    return (
        <Box {...RestorePasswordFormCardConfig}>
            <Formik
                initialValues={{EMAIL_RESTORE: ""}}

                onSubmit={(values, actions) => {
                    actions.setSubmitting(true)
                    // RestoreRequest
                    const restoreRequest: RestoreRequest = {
                        email: values.EMAIL_RESTORE
                    };

                    userController.restorePassword(restoreRequest).then(res => {
                        actions.setSubmitting(false)
                        if (res?.None) {
                            modalService.setModalData({
                                onSubmit: () => modalService.deleteModalData(),
                                isOpen: true,
                                onOpen: console.log,
                                onClose: () => modalService.deleteModalData(),
                                modalTextData: (values.EMAIL_RESTORE + ": this email address does not exists in our database"),
                                context: "error"
                            })
                        } else {
                            modalService.setModalData({
                                onSubmit: () => {
                                    modalService.deleteModalData()
                                    navigate(`${URLPaths.HOME.link}`);
                                },
                                isOpen: true,
                                onOpen: console.log,
                                onClose: () => modalService.deleteModalData(),
                                modalTextData: "A link to reset your password has been sent to your email",
                                context: "error"
                            })
                        }
                    })
                }
                }
                enableReinitialize={true}
                validationSchema={RestoreValidator.GetSchema()}
            >

                {({isSubmitting, errors, values, dirty, isValid, handleSubmit, setFieldValue}) => (
                    <FormControl {...FormStyleConfig}>
                        <Form onSubmit={handleSubmit}>
                            <Text fontSize={24} fontWeight={"md"} display={{base: "none", lg: "block"}}>Password
                                restore</Text>
                            <LabelStyled>The password restore link will be sent to your
                                email</LabelStyled>
                            <Flex py={4}>
                                <LabelStyled w={"20%"} htmlFor="EMAIL_RESTORE">E-mail</LabelStyled>
                                <InputControlStyled name="EMAIL_RESTORE" inputProps={{type: "text", maxW: "auto"}}/>
                            </Flex>
                            <MainButton type="submit" minHeight={10}
                                        borderColor="gray.500"
                                        border={"2px"}
                                        isLoading={isSubmitting} loadingText="Sent data">
                                Submit
                            </MainButton>
                        </Form>
                    </FormControl>
                )}
            </Formik>
        </Box>
    )
}
export default RestorePassword;