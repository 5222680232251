import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router";
import {Box, Checkbox, Flex, Input, Select, Text, useBreakpointValue, useToast} from "@chakra-ui/react";
import {useAppSelector} from "../../redux/Store";
import {Product} from "../../shared/entities/Product/Product";
import {ListProductItemConfig, ListProductItemRow} from "../../components/List/ListProductItem";
import {SectionCard} from "../../components/Card/SectionCard";
import {Loader} from "../../components/Loader/Loader";
import {MainButton} from "../../components/Buttons/MainButton";
import {OrderController} from "../../controllers/OrderController";
import {ModalService} from "../../service/Modal/ModalService";
import {CheckoutRequest} from "../../shared/entities/Client/CheckoutRequest";
import {URLPaths} from "../../config/application/URLPaths";
import {BoxRowStyleConfig, BoxStyleConfig} from "../Forms/FormStyleConfigs";
import {format, parse} from "date-fns";
import {AddedBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {TrashItem} from "../../components/Inputs/Icons/Icons";
import ImageCustom from "../../components/Image/ImageCustom";
import QuantityInput from "../../components/Inputs/QuantityInput";
import {CloseIcon, Icon} from "@chakra-ui/icons";
import {UserController} from "../../controllers/UserController";
import {usePrivileges} from "../../hooks/PrivilegesProvider";
import {Dealership} from "../../shared/entities/Users/Dealership";
import {ProductTypes} from "../../common/constants";
import {FiCheck, FiEdit} from "react-icons/fi";
import {ProductCostCalculateUtils} from "../../shared/utilities/ProductCostCalculateUtils";
import DiscountComponent from "../Products/Discount/DiscountComponent";
import {ProductController} from "../../controllers/ProductController";
import {OrderTypes} from "../../shared/entities/Order/Order";
import IconWithTooltip from "../../components/Icon/IconWithTooltip";
import {AuthorizationForm} from "../Forms/Authoization/AuthorizationForm";
import {RequestRegistrationForm} from "../Forms/Clients/RequestRegistrationForm";
import {CartService} from "../../service/Cart/CartService";

export const OrderBasket: FC<{ account?: boolean }> = ({account = false}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {priv} = usePrivileges();
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(true)
    const orderController = new OrderController(dispatch);
    const productController = new ProductController(dispatch);
    const {id} = useParams();
    const modalService = new ModalService(dispatch)
    const cartService = new CartService(dispatch)
    const isUnRegistered = location.pathname.includes('unregistered');
    const isViewWithPath = location.pathname.includes('view') || isUnRegistered;
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [shippingCost, setShippingCost] = useState<number>(0);
    const [paymentState, setPaymentState] = useState<OrderTypes>(0);
    const [subTotal, setSubTotal] = useState<number>(0);
    const [tax, setTax] = useState<number>(0);
    const [updateDealer, setUpdateDealer] = useState<boolean>(false);
    const [dateOrder, setDate] = useState<string>("");
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [poNumber, setPoNumber] = useState("");
    const [namePo, setNamePo] = useState("");
    const [updatedProductList, setProductList] = useState<Array<Product>>([]);
    const [selectedDealer, setSelectedDealer] = useState<Dealership | undefined>();
    const [shippingEdit, setShippingEdit] = useState<Boolean>(true);
    const userController = new UserController(dispatch);

    const currentState = useAppSelector(state => state.StatesReducer.currentState);
    const cartItems = useAppSelector((state) => state.CartReducer.cartItems);
    const authDealership = useAppSelector(state => state.AuthReducer.dealership);

    const selDealership = useAppSelector(state => state.UserReducer.dealership);
    const currentUser = useAppSelector(state => state.UserReducer.user);
    const dealershipsMinInfo = useAppSelector(state => state.UserReducer.dealershipsMinInfo);
    let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false})

    useEffect(() => {
        if (authDealership) {
            setSelectedDealer(priv?.ORDER_EDIT && updateDealer ? selDealership : authDealership)
        }
    }, [authDealership, selDealership]);

    function calculateTotalPrice(updatedProductList: Array<Product>, dealer: Dealership | undefined): void {
        let result = ProductCostCalculateUtils.calculateTotalPrice(updatedProductList, dealer, selectedDealer ? undefined : currentState ? currentState.tax : undefined);
        setTotalPrice(result.totalPrice);
        setShippingCost(result.shippingCost);
        setSubTotal(result.subtotal);
        setTax(result.taxCost);
    }

    const parseDateString = (dateString: any) => {
        const parsedDate = parse(dateString, 'MM.dd.yyyy HH:mm', new Date());
        if (!parsedDate) {
            throw new RangeError('Invalid time value');
        }
        return parsedDate;
    };

    useEffect(() => {
        if (!isViewWithPath) {
            AddedBreadcrumbsLinks(dispatch, {link: location.pathname, text: "Cart"});
            setProductList(cartItems);
            calculateTotalPrice(cartItems, selectedDealer);
            setPoNumber("");
            setNamePo("");
        }
    }, [cartItems, selectedDealer, currentState]);

    useEffect(() => {
        setIsLoading(true);
        if (!isViewWithPath) {
            calculateTotalPrice(updatedProductList, selectedDealer);
        } else {
            orderController.getOrder(Number(id), isUnRegistered).then((order) => {
                if (order.None) {
                    toast({
                        position: "top",
                        title: 'Error receiving order,' + order?.None?.getErrorDescription() + ', try logging in',
                        status: 'error',
                        duration: 8000,
                        isClosable: true,
                    })
                } else {
                    const newUpdatedProductList: Array<Product> = [];
                    for (const elem of order.Some.products) {
                        elem.product.quantity = elem.quantity
                        elem.product.discount = elem.discount
                        elem.product.shippingCost = elem.shippingCost
                        elem.product.taxCost = elem.taxCost
                        elem.product.price = elem.price
                        elem.product.finalPrice = elem.finalPrice
                        newUpdatedProductList.push({...elem.product});
                    }
                    setProductList(newUpdatedProductList);
                    setPaymentState(order.Some.paymentStatus)
                    setTotalPrice(order.Some.totalAmount || 0);
                    setShippingCost(order.Some.shippingCost || 0);
                    setTax(order.Some.tax || 0);
                    setPoNumber(order.Some.poNumber || "");
                    setNamePo(order.Some.namePo || "000000");
                    setDate(format(parseDateString(order.Some.createdAt), "MM/dd/yyyy"));
                    AddedBreadcrumbsLinks(dispatch, {link: location.pathname, text: "ORDER #" + id || ""});
                }
            });
        }
        setIsLoading(false);
    }, [selectedDealer, isUnRegistered]);

    interface ColumnConfig {
        header: string;
        width?: string;
        isLink?: boolean;
        style?: object;
    }

    const columns: Array<ColumnConfig> = isMobile ? [
        {
            header: <Box>
                <Text>Product</Text>
                <Text>Item price</Text>
            </Box>,
            width: "30%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        }, {
            header: <Box>
                <Text>Info</Text>
                <Text>Qty</Text>
            </Box>,
            width: "40%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        }, {
            header: <Box>
                <Text>Total price</Text>
            </Box>,
            width: "30%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        }
    ] : [
        {
            header: "Product",
            width: priv?.ORDER_EDIT ? "13%" : "15%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        },
        {header: "Info", width: "45%", isLink: false, style: {flexBasis: "50%", color: "grey.400"}},
        {
            header: "Item price",
            width: priv?.ORDER_EDIT ? "10%" : "10%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        },
        {
            header: "Qty",
            width: priv?.ORDER_EDIT ? "12%" : "15%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        },
        {
            header: "Total price",
            width: priv?.ORDER_EDIT ? "10%" : "auto",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        },
        (priv !== undefined && priv?.ORDER_EDIT) && {
            header: "Shipping",
            width: "10%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        },
        (priv !== undefined && priv?.ORDER_EDIT) && {
            header: "Tax",
            width: "10%",
            isLink: false,
            style: {flexBasis: "50%", color: "grey.400"}
        }
    ];

    const ColumnNamesMapping: Array<ListProductItemConfig> = columns.map(
        (col: ColumnConfig) => {
            return {
                content: col.header || "",
                width: col.width || "auto",
                isLink: col.isLink || false,
                style: col.style || {},
            };
        }
    );
    const handleCheckboxChange = (id: number, checked: boolean) => {
        if (checked) {
            setSelectedIds([...selectedIds, id]);
        } else {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        }
    };

    const handleAddedSelectProductToCart = () => {
        setIsLoading(true);
        productController.getProductsIds({ids: selectedIds?.join(",")}).then(productList => {
            if (productList.Some) {
                productList.Some.items.map((product: any) => {
                    const existingProduct = updatedProductList.find((item) => item.id === product.id);
                    if (existingProduct) {
                        product.quantity = existingProduct.quantity;
                    }
                    cartService.addProductToCart(product);
                })
                setIsLoading(false);
            }
        });
    }

    const handleCheckoutCart = (total: number, shippingCost: number, tax: number) => {
        if (!priv?.ORDER_EDIT && namePo === "") {
            return;
        }
        const loggedInUser = sessionStorage.getItem("LOGIN");
        if (!loggedInUser || !selectedDealer) {
            modalService.setModalData({
                onSubmit: () => {
                    modalService.deleteModalData();
                    if (!loggedInUser) {
                        navigate(URLPaths.AUTH.link + location.pathname);
                    }
                },
                isOpen: true,
                onOpen: () => {
                },
                onClose: () => {
                    modalService.deleteModalData();
                    navigate(-1);
                },
                modalTextData: loggedInUser && !selectedDealer ? "Selected dealership" : "\n" +
                    "Only logged in register user can place an order.\n" +
                    "\n" +
                    "800-260-5051\n" +
                    "Info@akdealerservices.com",
                context: "error"
            });
            return;
        }


        if (selectedDealer && selectedDealer.id) {
            const checkoutRequest: CheckoutRequest = {
                dealership: selectedDealer.id.toString(),
                userId: Number(currentUser?.id),
                poNumber: poNumber,
                namePo: namePo || "",
                subTotal: subTotal,
                tax: tax,
                shippingCost: shippingCost,
                totalAmount: total,
                reorder: false,
                productRequest: ProductCostCalculateUtils.getProductsRequest(updatedProductList, selectedDealer)
            };
            setIsLoading(true);
            orderController.orderCheckout(checkoutRequest).then(res => {
                setIsLoading(false);
                toast({
                    position: "top",
                    title: res.None ? 'Failed checkout' : 'Your order was received and will be processed and shipped to you shortly.',
                    status: res.None ? 'error' : 'success',
                    duration: 8000,
                    isClosable: true,
                })
                if (!res?.None) {
                    navigate(`${URLPaths.ORDER_HISTORY.link}`);
                }
            });
        } else {
            toast({
                position: "top",
                title: 'Error checkout selected dealership',
                status: 'error',
                duration: 3000,
                isClosable: true
            })
        }
    };
    const getList = (productList: Array<Product>) => {
        return productList?.map((elem: Product, idx: number) => {
            const ItemConfig: Array<ListProductItemConfig> = isMobile ? [
                    {
                        content:
                            <Box pb={4} width={"100%"}>
                                <Flex>
                                    <Box minWidth={"25%"}>
                                        <ImageCustom elem={elem} sizeInPercent={40} sizeBackend={"COMMON"}/>
                                    </Box>
                                    <Box flexDir="column" textAlign="left" pl={3} pt={2}>
                                        <Text fontSize={16} fontWeight="md">{elem.name}</Text>
                                        {/*<Text fontWeight="500" color="gray.400"> Product#: {elem.id} </Text>*/}
                                        <Text fontSize={14} color="gray.400">{elem.description}</Text>
                                        {elem.type === ProductTypes.CUSTOM && (
                                            <Text fontWeight="600" fontSize="16px">
                                                {'This product is custom and will be billed and shipped on separate invoice.'}
                                            </Text>
                                        )}
                                        {elem.type === ProductTypes.REORDER && (
                                            <Text fontWeight="600" fontSize="16px">
                                                {'This product will be billed and shipped on separate invoice.'}
                                            </Text>
                                        )}
                                    </Box>
                                </Flex>
                                <Flex alignItems={"center"} pt={2}>
                                    <Flex minWidth={isViewWithPath ? "29%" : "32%"}>
                                        <DiscountComponent elem={elem} hiddenUOM={true} history={isViewWithPath}
                                                           fontWeightPrice={"300"}
                                                           textAlign={"left"}
                                                           selectedDealer={selectedDealer}
                                                           showDiscount={!isViewWithPath}
                                                           showTax={!isViewWithPath && priv?.ORDER_EDIT}
                                                           showMarkup={!isViewWithPath && priv?.ORDER_EDIT}
                                        />
                                    </Flex>
                                    <Flex w={"100%"}>
                                        {isViewWithPath ?
                                            <Text fontSize="16px" fontWeight={"md"}
                                                  width={"50%"}>{elem.quantity + " : Qty"}</Text> :
                                            <QuantityInput item={elem} isViewWithPath={isViewWithPath}
                                                           isMobile={isMobile}
                                                           width={"50%"}
                                                           onQuantityChange={(updatedItem) => {
                                                               const updatedProduct = cartItems.find((item) => item.id === elem.id);
                                                               if (updatedProduct) {
                                                                   updatedProduct.quantity = updatedItem.quantity;
                                                                   cartService.addProductToCart(updatedProduct);
                                                               }
                                                           }}/>
                                        }

                                        <Flex justifyContent={"space-between"} w={"50%"} alignItems={"center"}>
                                            <Text fontSize="16px" fontWeight={"md"} pl={3}> {"$" + (isViewWithPath ?
                                                ((elem.finalPrice !== undefined ? elem.finalPrice : 0) * elem.quantity).toFixed(2) :
                                                Number((ProductCostCalculateUtils.calculatePrice(elem, selectedDealer) * elem.quantity).toFixed(2)))}
                                            </Text>
                                            <Box pr={1}>
                                                {isViewWithPath ?
                                                    <Checkbox marginLeft={"10px"} checked={selectedIds.includes(elem?.id)}
                                                              onChange={(event) => handleCheckboxChange(elem?.id, event.target.checked)}
                                                    /> :
                                                    <IconWithTooltip icon={CloseIcon} tooltipMessage={'Remove'}
                                                                     color={"#D52E34"}
                                                                     onClick={() => cartService.removeFromCart(elem)}/>
                                                }
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Box>,
                        isLink: false,
                        width: "100%",
                        justifyContent: "flex-start"
                    }] :
                [
                    {
                        content: (<ImageCustom elem={elem} sizeInPercent={35} sizeBackend={"COMMON"}/>),
                        isLink: false,
                        width: "13%",
                        justifyContent: "flex-start"
                    },
                    {
                        content: (
                            <Box flexDir="column" textAlign="left" maxWidth={"650px"}>
                                <Text fontSize={16} fontWeight="md">{elem.name}</Text>
                                {/*<Text fontWeight="500" color="gray.400"> Product#: {elem.id} </Text>*/}
                                <Text fontSize={14} color="gray.400">{elem.description}</Text>
                                {elem.type === ProductTypes.CUSTOM && (
                                    <Text fontWeight="600" fontSize="16px">
                                        {'This product is custom and will be billed and shipped on separate invoice.'}
                                    </Text>
                                )}
                                {elem.type === ProductTypes.REORDER && (
                                    <Text fontWeight="600" fontSize="16px">
                                        {'This product will be billed and shipped on separate invoice.'}
                                    </Text>
                                )}
                            </Box>
                        ),
                        width: "40%",
                        justifyContent: "flex-start"
                    },
                    {
                        content: <DiscountComponent elem={elem} hiddenUOM={true} history={isViewWithPath}
                                                    selectedDealer={selectedDealer}
                                                    showDiscount={!isViewWithPath}
                                                    showTax={!isViewWithPath && priv?.ORDER_EDIT}
                                                    showMarkup={!isViewWithPath && priv?.ORDER_EDIT}
                        />,
                    },
                    {
                        content: isViewWithPath ?
                            <Text fontSize="14px">{elem.quantity}</Text> :
                            <QuantityInput item={elem} isViewWithPath={isViewWithPath}
                                           onQuantityChange={(updatedItem) => {
                                               const updatedProduct = cartItems.find((item) => item.id === elem.id);
                                               if (updatedProduct) {
                                                   updatedProduct.quantity = updatedItem.quantity;
                                                   cartService.addProductToCart(updatedProduct);
                                               }
                                           }}/>,
                        width: "15%"
                    },
                    {
                        content: <Text
                            fontSize="14px"> {"$" + (isViewWithPath ?
                            ((elem.finalPrice !== undefined ? elem.finalPrice : 0) * elem.quantity).toFixed(2) :
                            Number((ProductCostCalculateUtils.calculatePrice(elem, selectedDealer) * elem.quantity).toFixed(2)))}
                        </Text>,
                        width: "5%"
                    },
                    priv?.ORDER_EDIT && {
                        content: <Text
                            fontSize="14px"> {isViewWithPath ? elem.shippingCost.toFixed(2) : (
                            (elem.parentShippingCost !== 0 && elem.parentType === ProductTypes.OPTIONS) ?
                                `SumQnt ${ProductCostCalculateUtils.getCountQuantityOptions(updatedProductList, elem)}` :
                                ProductCostCalculateUtils.calculateShippingCost(elem, selectedDealer).toFixed(2) + "$")}</Text>,
                        width: "10%"
                    },
                    priv?.ORDER_EDIT && {
                        content: <Text
                            fontSize="14px">{isViewWithPath && elem.taxCost ? elem.taxCost.toFixed(2) : ProductCostCalculateUtils.calculateTaxCost(elem, selectedDealer).toFixed(2) + "$"}</Text>,
                        width: "10%"
                    },
                    !isUnRegistered && {
                        content: isViewWithPath ? <Checkbox marginLeft={"10px"} checked={selectedIds.includes(elem?.id)}
                                                            onChange={(event) => handleCheckboxChange(elem?.id, event.target.checked)}
                        /> : <IconWithTooltip icon={TrashItem} tooltipMessage={'Remove'}
                                              onClick={() => cartService.removeFromCart(elem)}/>,
                        width: "auto"
                    }
                ]
            return <ListProductItemRow key={idx} list={ItemConfig} noBorder={false}
                                       style={{p: "2px 0px"}}/>;
        });
    }

    const handleSelectChange = (event: any) => {
        if (event.target.value) {
            setUpdateDealer(true);
            userController.getDealership({id: event.target.value});
        }
    }

    return (
        <>
            {!account &&
                <Breadcrumbs/>
            }
            {isLoading ? <Loader/> :
                <Box height={"100%"} p={{base: 0, lg: 0}}>
                    <Box {...BoxStyleConfig}>
                        {isUnRegistered &&
                            <Box w={"50%"} marginX={"auto"} py={6} px={10} background={"#E9F5D9"} borderRadius={"5px"}>
                                <Text fontWeight={"md"} fontSize={16} color={"#457B00"}>Thank you for your
                                    information!</Text>
                                <Text fontWeight={"sm"} fontSize={16} color={"#457B00"} pt={4}>
                                    Registering a new customer usually takes 30 min to an hour during working hours.
                                    Once approved, you will receive your login password via email. Your order will be
                                    completed with correct taxes if applicable and shipping cost, also it will be saved
                                    in your account.</Text>
                            </Box>
                        }
                        {isViewWithPath &&
                            <>
                                <Text>
                                    DATE: {dateOrder}
                                </Text>
                                {(paymentState !== OrderTypes.IN || priv?.ORDER_EDIT) &&
                                    <Text
                                        mb="20px">
                                        ORDER#: {id}
                                    </Text>
                                }
                            </>
                        }

                        <ListProductItemRow list={ColumnNamesMapping} noBorder={false} style={{
                            fontSize: "14px",
                            minHeight: "35px",
                            justifyContent: "flex-start",
                            paddingBottom: isMobile ? 3 : 0,
                            paddingTop: isMobile ? 3 : 0
                        }}/>
                        {getList(updatedProductList)}
                        {(!isUnRegistered && selectedDealer) &&
                            <Box width="100%" padding={"10px 0"} borderBottom={"none"}>
                                <Flex justifyContent="space-between" alignItems="flex-start">
                                    <Box {...BoxStyleConfig}>
                                        {priv?.ORDER_EDIT && !isViewWithPath ?
                                            <Text
                                                ml="auto">
                                                TAX:{
                                                `${ProductCostCalculateUtils.getTaxShow(selectedDealer)} $${tax.toFixed(2)}`}
                                            </Text>
                                            :
                                            <Text
                                                ml="auto">
                                                TAX: ${tax.toFixed(2)}
                                            </Text>
                                        }
                                        {priv?.ORDER_EDIT ?
                                            <Box display="flex" flexDir="row"
                                                 justifyContent="flex-start"
                                                 alignSelf="flex-start"
                                                 ml="auto" mb={"5px"}>

                                                {shippingEdit || isViewWithPath ?
                                                    <Text ml="auto">
                                                        Shipping: ${shippingCost.toFixed(2)}
                                                    </Text>
                                                    :
                                                    <>
                                                        <Text
                                                            mr={"10px"}>Shipping:</Text>
                                                        <Input
                                                            maxWidth={"120px"}
                                                            height="30px"
                                                            borderRadius="2px"
                                                            defaultValue={shippingCost}
                                                            onChange={(e) => {
                                                                setShippingCost(Number(e.target.value));
                                                                setTotalPrice(subTotal + tax + Number(e.target.value));
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    setShippingEdit(true)
                                                                }
                                                            }}
                                                            isDisabled={isViewWithPath}
                                                        />
                                                    </>
                                                }

                                                {shippingEdit ?
                                                    <Icon
                                                        ml={"10px"}
                                                        as={FiEdit}
                                                        fontSize={"22px"}
                                                        strokeWidth={0.75}
                                                        cursor={"pointer"}
                                                        onClick={() => setShippingEdit(false)}
                                                    />
                                                    :
                                                    <Icon
                                                        ml={"10px"}
                                                        as={FiCheck}
                                                        fontSize={"22px"}
                                                        strokeWidth={1}
                                                        cursor={"pointer"}
                                                        onClick={() => setShippingEdit(true)}
                                                        _hover={{
                                                            background: "gray.100"
                                                        }}
                                                    />
                                                }
                                            </Box>
                                            :
                                            <Text
                                                ml="auto">
                                                Shipping: ${shippingCost.toFixed(2)}
                                            </Text>
                                        }
                                        <Text ml="auto">
                                            {/*Total: {selectedDealer || isViewWithPath ? `${totalPrice.toFixed(2)}` : "no selected Dealer"}*/}
                                            Total: {`${totalPrice.toFixed(2)}`}
                                        </Text>
                                        <Box display="flex" flexDir="row" justifyContent="flex-start"
                                             alignSelf="flex-start"
                                             ml="auto">
                                            <Text mr={"10px"}>PO#</Text>
                                            <Input
                                                fontSize={14}
                                                maxWidth={"120px"}
                                                height="30px"
                                                borderRadius="2px"
                                                value={poNumber}
                                                onChange={(e) => {
                                                    setPoNumber(e.target.value)
                                                }}
                                                placeholder='OPTIONAL'
                                                isDisabled={isViewWithPath}
                                            />
                                        </Box>
                                        <Box display="flex" flexDir="row" justifyContent="flex-start"
                                             alignSelf="flex-start"
                                             ml="auto">
                                            <Text mr={"10px"}>RO or Orderer’s Name</Text>
                                            <Input
                                                fontSize={14}
                                                maxWidth={"120px"}
                                                height="30px"
                                                borderRadius="2px"
                                                value={namePo}
                                                onChange={(e) => {
                                                    setNamePo(e.target.value)
                                                }}
                                                borderColor={((!priv?.ORDER_EDIT && namePo === "") && !isViewWithPath) ? "red.500" : "gray.300"}
                                                placeholder={priv?.ORDER_EDIT ? 'OPTIONAL' : 'MUST ENTER'}
                                                isDisabled={isViewWithPath}
                                            />
                                        </Box>
                                        <Box {...BoxRowStyleConfig} maxW={"25rem"} ml={"auto"} pt={3}>
                                            {priv?.ORDER_EDIT && !isViewWithPath &&
                                                <Select onChange={handleSelectChange} pr={2} fontSize={"14px"}
                                                        fontWeight={"sm"}
                                                        defaultValue={0}>
                                                    {[...dealershipsMinInfo, {
                                                        id: 0,
                                                        name: "no selected"
                                                    }].map((elem: any) => (
                                                        <option style={{fontWeight: "400", fontSize: "14px"}}
                                                                key={elem.id}
                                                                value={elem.id} hidden={elem.id === 0}>
                                                            {elem.name}
                                                        </option>
                                                    ))}
                                                </Select>
                                            }
                                            <Box w={"100%"}>
                                                {isMobile &&
                                                    <>
                                                        <Box border={"0.7px solid #D1D5DB"} width={"100%"}/>
                                                        <Text pt={6}>{'Please check out and confirm your order'}</Text>
                                                    </>
                                                }
                                                <Flex w={"100%"} pt={{base: 4, lg: 0}}>
                                                    <MainButton ml={{lg: "auto"}} minWidth={"180px"} minH={"40px"}
                                                                background={{
                                                                    base: "gray.800",
                                                                    lg: "primary.gray.light"
                                                                }}
                                                                color={{base: "white", lg: "black"}}
                                                                disabled={(isViewWithPath && selectedIds.length === 0)}
                                                                onClick={() => isViewWithPath ? handleAddedSelectProductToCart() : handleCheckoutCart(totalPrice, shippingCost, tax)}>
                                                        {isViewWithPath ? 'Add to cart' : 'Proceed to Checkout'}
                                                    </MainButton>
                                                </Flex>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        }
                    </Box>
                </Box>
            }
            {(!selectedDealer && !isUnRegistered && !account) &&
                <Flex pt={5} direction={{base: "column", lg: "row"}}>
                    <AuthorizationForm basket={true}/>
                    <RequestRegistrationForm isEditing={false} basket={true}/>
                </Flex>
            }
        </>
    );
}

export default OrderBasket;
