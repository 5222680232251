import {ChakraProps} from "@chakra-ui/react";
import {FC} from "react";
import {Card} from "./Card"

export const SectionCard:FC<ChakraProps> = (props) => {
    const SectionCardStyledConfig:ChakraProps = {
        w: "100%",
        h: "85%",
        // m: "0px 25px auto auto",
        // p: "36px 48px",
        // minW: "1024px",
        // minW: "450px",
        ...props,
    }
    return(
        <Card {...SectionCardStyledConfig}/>
    )
}
