import React, {FC, useEffect, useState} from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Flex,
    Text, useDisclosure, Box,
} from "@chakra-ui/react";
import {Product} from "../../shared/entities/Product/Product";
import {MainButton} from "../../components/Buttons/MainButton";
import {useNavigate} from "react-router";
import {useAppSelector} from "../../redux/Store";
import {ProductCostCalculateUtils} from "../../shared/utilities/ProductCostCalculateUtils";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {ActionTypes} from "../../redux/Cart/CartReducer";
import {useDispatch} from "react-redux";
import ImageCustom from "../../components/Image/ImageCustom";


const AddProductModal: FC = () => {
    const navigate = useNavigate()
    const authDealership = useAppSelector(state => state.AuthReducer.dealership);
    const [calcProduct, setCalcProduct] = useState<any>();
    const [product, setProduct] = useState<Product>();
    const dispatch = useDispatch();

    const event = useAppSelector(state => state.ModalReducer.event);
    const {isOpen, onOpen, onClose} = useDisclosure()

    useEffect(() => {
        if (event && event.selModel === SelectModal.ADD_PRODUCT && event.product && event.quantity) {
            setProduct(event.product);
            event.product.quantity += event.quantity;
            dispatch({type: ActionTypes.ADD_TO_CART, payload: event.product});
            setCalcProduct(ProductCostCalculateUtils.calculatePrice(event.product, authDealership ? authDealership : undefined));
            onOpen()
        } else {
            onClose()
        }
    }, [event]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Items added to cart</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    {product &&
                        <>
                            <Flex justifyContent="center">
                                <ImageCustom elem={product} sizeBackend={"CART"} showOnHover={false}
                                             sizeInPercent={120}/>
                            </Flex>
                            <Flex>
                                <Text>
                                    <span style={{fontWeight: "400"}}> {"QTY: "}</span>
                                    <span style={{fontWeight: "600"}}>{product.quantity}</span>
                                </Text>
                                <Text pl={6}>
                                    <span style={{fontWeight: "400"}}> {"Total price: "}</span>
                                    <span style={{fontWeight: "600"}}>${Number((calcProduct * product.quantity).toFixed(2))}</span>
                                </Text>
                            </Flex>
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Flex justifyContent="space-around" width="100%">
                        <MainButton onClick={onClose}>Continue Shopping</MainButton>
                        <Box px={4}/>
                        <MainButton onClick={() => {
                            onClose();
                            navigate("/cart")
                        }}>View & Edit Cart</MainButton>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddProductModal;
