import {
    ChakraProps, Modal,
    ModalBody,
    ModalContent
} from "@chakra-ui/react";
import React, {FC} from "react";
/**
 * eslint-disable-next-line @typescript-eslint/no-unused-vars
 */
import {ShowFilter} from "../Filter/ShowFilter";
import {FieldType} from "../../common/constants";

;

/**
 * CustomModal.
 *
 * @param {CustomModalConfig}
 *
 * This component represents modals, that will appear
 * in response to arbitrary events
 */
interface MenuModalProps {
    isOpen: boolean;
    onClose: () => void;
    setNewFilterValue: (val: any, modal: boolean) => void;
    buttonPosition: { top: number; left: number };
    uniqueName: string;
    fieldType?: FieldType,
    options?: any,
}

export const FilterModal: FC<MenuModalProps> = ({
                                                    isOpen,
                                                    onClose,
                                                    buttonPosition,
                                                    setNewFilterValue,
                                                    uniqueName,
                                                    fieldType,
                                                    options
                                                }) => {

    const FilterModalStylesConfig: ChakraProps = {
        fontFamily: "Poppins",
        fontSize: "16px",
        textAlign: "center",
        width: "auto",
        position: "absolute",
        top: buttonPosition.top - 50,
        left: buttonPosition.left
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="none" closeOnOverlayClick={true}>
            <ModalContent {...FilterModalStylesConfig}>
                <ModalBody>
                    <ShowFilter options={options} modal={true} type={fieldType} updateFunc={setNewFilterValue}
                                uniqueName={uniqueName}/>
                </ModalBody>
            </ModalContent>
        </Modal>

    );
}
