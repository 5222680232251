import {
    Box, Button, Checkbox, Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter, ModalHeader,
    ModalOverlay, Text, useDisclosure, useToast
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";
import {useAppSelector} from "../../redux/Store";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {MainButton} from "../../components/Buttons/MainButton";
import {ModalService} from "../../service/Modal/ModalService";
import {useDispatch} from "react-redux";
import {LabelStyled} from "../../components/Inputs/LabelStyled";
import {ListItemConfig, ListItemRow} from "../../components/List/ListItem";
import {Company} from "../../shared/entities/Company/Company";
import {InputControlStyled} from "../../components/Inputs/InputControlStyled";
import {Form, Formik} from "formik";
import {SelectControlStyled} from "../../components/Inputs/SelectControlStyled";
import {RoleController} from "../../controllers/RoleController";
import {usePrivileges} from "../../hooks/PrivilegesProvider";
import {UserController} from "../../controllers/UserController";
import {User} from "../../shared/entities/Users/User";

interface AddUserModalProps {
    onRefresh: () => void;
}

export const AddUserModal: FC<AddUserModalProps> = ({onRefresh}) => {
    const event = useAppSelector(state => state.ModalReducer.event);
    const dispatch = useDispatch();
    const modalService = new ModalService(dispatch);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [commonContent, setCommonContent] = useState<Company>()
    const dispath = useDispatch()
    const roleController = new RoleController(dispath)
    const roles = useAppSelector(store => store.RoleReducer?.roleList)

    const {isOpen, onOpen, onClose} = useDisclosure()

    const [filterDealerships, setFilterDealerships] = useState<Array<any>>([])
    const debug = process.env.NODE_ENV === "development";
    const [currentRole, setCurrentRole] = useState<number>(3)
    const [editId, setEditId] = useState<number>(-1)
    const {priv} = usePrivileges();
    const userController = new UserController(dispatch);
    const toast = useToast();

    const [initialValues, setInitialValues] = useState<any>();
    useEffect(() => {
        if (event && event.selModel === SelectModal.ADD_USER) {
            setCommonContent(event.selCommonContent)
            setEditId(event.selId);
            if (event.selId !== -1) {
                let userCurrent = event.selCommonContent.users.find((c: User) => c.id === event.selId)
                setInitialValues({
                    login: userCurrent.login,
                    email: userCurrent.email,
                    phone: userCurrent.phone,
                    roleCurrent: userCurrent.roles[0].ID
                })
                setCurrentRole(userCurrent.roles[0].ID);
                setSelectedItems(userCurrent.dealerships);
            } else {
                setCurrentRole(3);
                setInitialValues({
                    login: "", email: "", roleCurrent: 3,
                })
                setSelectedItems([])

            }
            roleController.getRole().then(res => {
                if (res.None) {
                    modalService.setModalData({
                        onSubmit: () => modalService.deleteModalData(),
                        isOpen: true,
                        onOpen: () => {
                        },
                        onClose: () => modalService.deleteModalData(),
                        modalTextData: (res?.None?.getErrorDescription ? "Role: " + res?.None?.getErrorDescription() : "Not found"),
                        context: "error"
                    })
                }
            })
            onOpen()
        } else {
            onClose()
        }
    }, [event]);

    useEffect(() => {
        if (commonContent?.dealerships) {
            setFilterDealerships(commonContent?.dealerships)
        }
    }, [commonContent]);

    const handleOnclickNew = (elem: any, remove: Boolean) => {
        setSelectedItems(prevSelectedItems => {
            const isSelected = prevSelectedItems.some(item => item.id === elem.id);
            if (isSelected) {
                return remove ? prevSelectedItems.filter(item => item.id !== elem.id) : prevSelectedItems;
            } else {
                return [...prevSelectedItems, elem];
            }
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>{editId === -1 ? 'Create user' : 'Update user'}</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values, actions) => {
                            if (values.copyShippingToBilling) {
                                delete values.billingAddress;
                            }
                            if (editId === -1) {
                                if (priv?.USER_CREATE) {
                                    actions.setSubmitting(true)
                                    userController.createUser(values).then(res => {
                                        if (res.None) {
                                            if (res?.None?.getErrorCode() === 10202) {
                                                actions.setFieldError("login", res?.None?.getErrorDescription())
                                                actions.setSubmitting(false)
                                                return
                                            }
                                            if (res?.None?.getErrorCode() === 10203) {
                                                actions.setFieldError("email", res?.None?.getErrorDescription())
                                                actions.setSubmitting(false)
                                                return
                                            }
                                        } else {
                                            toast({
                                                position: "top",
                                                title: 'Your user was created successfully',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                            onRefresh()
                                            modalService.resetModal();
                                            onClose();
                                        }
                                    })
                                }
                            } else {
                                values.editUserId = editId
                                if (priv?.USER_EDIT) {
                                    actions.setSubmitting(true)
                                    userController.updateUser(values).then(res => {
                                        if (res.None) {
                                            if (res?.None?.getErrorCode() === 10202) {
                                                actions.setFieldError("login", res?.None?.getErrorDescription())
                                                actions.setSubmitting(false)
                                                return
                                            }
                                            if (res?.None?.getErrorCode() === 10203) {
                                                actions.setFieldError("email", res?.None?.getErrorDescription())
                                                actions.setSubmitting(false)
                                                return
                                            }
                                        } else {
                                            toast({
                                                position: "top",
                                                title: 'User was update successfully',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                            onRefresh()
                                            modalService.resetModal();
                                            onClose();
                                        }
                                    })
                                }
                            }
                            actions.setSubmitting(false)
                        }}
                        validate={values => {
                            values.roleIds = [currentRole];
                            values.companyId = commonContent?.id;
                            values.dealerships = selectedItems.map(d => ({id: d.id, name: d.name}));
                            setInitialValues(values)
                        }}
                        enableReinitialize={true}
                    >
                        {({isSubmitting, touched, errors, values, dirty, isValid, handleSubmit, setFieldValue}) => (
                            <Form onSubmit={handleSubmit}>
                                <LabelStyled>Name</LabelStyled>
                                <InputControlStyled name="login" inputProps={{
                                    maxW: "450px",
                                    autoComplete: debug ? "on" : "new-password"
                                }}/>
                                <LabelStyled>E-mail</LabelStyled>
                                <InputControlStyled name="email" inputProps={{
                                    maxW: "450px",
                                    autoComplete: debug ? "on" : "new-password"
                                }}
                                />
                                <LabelStyled>Role</LabelStyled>
                                <SelectControlStyled
                                    maxW="450px"
                                    name="roleCurrent"
                                    onChange={(ev: any) => setCurrentRole(ev.target.value)}
                                >
                                    {roles?.map((role) => (
                                        <option key={role.ID} value={role.ID}>
                                            {role.NAME}
                                        </option>
                                    ))}
                                </SelectControlStyled>
                                {/*</Box>*/}
                                {selectedItems && (
                                    <Box mb="4" mt="4" display="flex" flexWrap="wrap" maxHeight={"200px"}
                                         overflowY={"auto"}>
                                        {selectedItems.map((item) => (
                                            <Button
                                                key={item.id}
                                                mr="2"
                                                colorScheme="teal"
                                                variant="solid"
                                                size="sm"
                                                mb={"2"}
                                                onClick={() => handleOnclickNew(item, true)}
                                            >
                                                {item.name} ✕
                                            </Button>
                                        ))}
                                    </Box>
                                )}
                                <Box>
                                    <LabelStyled ml={"8%"}>Access dealership for user</LabelStyled>
                                    <Flex>
                                        <Box minWidth={"95%"} ml={"5%"}>
                                            <Box maxHeight={"200px"} mt="1rem" overflowY={"auto"} border={"1px solid"}
                                                 borderRadius={"5px"}>
                                                {filterDealerships?.map((elem: any, index: number) => {
                                                    const ItemConfig: Array<ListItemConfig> = [
                                                        {
                                                            content: <Checkbox font={"14px"}
                                                                               minW={"65px"}
                                                                               isChecked={selectedItems.some(item => item.id === elem.dealershipId)}
                                                                               children={elem.dealershipId}
                                                                               onChange={(ev: any) => {
                                                                                   handleOnclickNew(elem, selectedItems.some(item => item.id === elem.dealershipId))
                                                                               }}
                                                            />,
                                                            isLink: false,
                                                            style: {
                                                                flexBasis: "5%",
                                                                justifyContent: "flex-start",
                                                                ml: "0.5rem"
                                                            }
                                                        }, {
                                                            content: <Text
                                                                           fontWeight={"600"}>{elem.name}</Text>,
                                                            isLink: false,
                                                            style: {
                                                                flexBasis: "90%",
                                                                justifyContent: "flex-start",
                                                                ml: "0.5rem"
                                                            }
                                                        }
                                                    ]
                                                    return <ListItemRow style={{justifyContent: "flex-start"}}
                                                                        key={index}
                                                                        list={ItemConfig}
                                                    />
                                                })}
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <ModalFooter>
                                        <MainButton
                                            type="submit"
                                            isLoading={isSubmitting}
                                            disabled={selectedItems.length === 0 && (Number(currentRole) === 3)}
                                        > Save </MainButton>
                                    </ModalFooter>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}
