import React, {FC} from 'react';
import {AspectRatio, Box, Flex, Link, Text} from '@chakra-ui/react';
import {useDispatch} from "react-redux";
import {SetBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {Icon} from "@chakra-ui/icons";
import {FaArrowRight} from "react-icons/all";

const Contacts: FC<{ links: Array<BreadcrumbsData> }> = (props) => {
    const dispatch = useDispatch()
    SetBreadcrumbsLinks(dispatch, props.links)
    return (
        <>
            <Breadcrumbs/>
            <Flex width={"100%"} direction={{base: "column", lg: "row"}} pt={4}>
                <Box w={{base: "100%", lg: "40%"}}>
                    <Text fontSize={40} fontWeight={"md"}>Contacts</Text>
                    <Text pt={3} fontWeight={"sm"}>We want to make sure your experience is a good one. If you need any
                        help with
                        wholesale ordering just give us a call. We are available Monday through Friday from 9am to 6pm
                        CST.</Text>
                </Box>
                <Box w={{base: "100%", lg: "60%"}} pl={{base: 0, lg: 10}}>
                    <Text>Phone:</Text>
                    <Text fontSize={22}>800.260.5051</Text>
                    <Text>Email:</Text>
                    <Link href="mailto:info@akdealerservices.com" textDecoration="underline" py={2}
                          fontWeight={"sm"}>info@akdealerservices.com</Link>
                    <Text py={2}>Address:</Text>
                    <Text fontWeight={"sm"}>7315 Gibson Cemetery Rd # 103</Text>
                    <Text fontWeight={"sm"}>Mansfield, TX 76063</Text>
                                        <Box onClick={() => window.open("https://www.google.com/maps/place/7315+Gibson+Cemetery+Rd,+Mansfield,+TX+76063,+USA/@32.5922679,-97.2093163,17z/data=!4m15!1m8!3m7!1s0x864e6699bf063b63:0x8522c39c2e23067f!2s7315+Gibson+Cemetery+Rd,+Mansfield,+TX+76063,+USA!3b1!8m2!3d32.5922635!4d-97.20444!16s%2Fg%2F11q4045xyp!3m5!1s0x864e6699bf063b63:0x8522c39c2e23067f!8m2!3d32.5922635!4d-97.20444!16s%2Fg%2F11q4045xyp?hl=en&entry=ttu", "_blank")} maxW={"130px"} pt={5}
                         cursor={"pointer"}>
                        <Flex>
                            <Text>{`See on map`}</Text>
                            <Icon as={FaArrowRight} px={2} fontSize={30} strokeWidth={1} color={"gray.800"}
                                  cursor={"pointer"}/>
                        </Flex>
                        <Box border={"2px"} borderColor={"gray.300"} width={"90%"}/>
                    </Box>
                </Box>
            </Flex>
            <Box pt={5}>
                <AspectRatio ratio={16 / 9}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3361.522065517489!2d-97.209316271818!3d32.59226792550033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e6699bf063b63%3A0x8522c39c2e23067f!2s7315%20Gibson%20Cemetery%20Rd%2C%20Mansfield%2C%20TX%2076063%2C%20USA!5e0!3m2!1sen!2sng!4v1715891887173!5m2!1sen!2sng"
                        width="600" height="450" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </AspectRatio>
            </Box>
        </>
    );
}

export default Contacts;
