import { FormLabel, FormLabelProps } from "@chakra-ui/react";
import { FC } from "react";

/**
 * Лейбл над инпутами стилизованный по умолчанию
 * @param props
 * @returns
 */
export const LabelStyled: FC<FormLabelProps> = (props) => {
    const LabelStyledProps: FormLabelProps = {
        ...props,
        paddingTop: "15px",
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight:"20px",
        fontWeight:"md"
    }

    return (
        <FormLabel {...LabelStyledProps} />
    )
}
