import React from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import {ListItemConfig, ListItemRow} from "../../../components/List/ListItem";
import IconWithTooltip from "../../../components/Icon/IconWithTooltip";
import {FiTrash} from "react-icons/all";
import {LabelStyled} from "../../../components/Inputs/LabelStyled";
import {useFormikContext} from "formik";
import {usePrivileges} from "../../../hooks/PrivilegesProvider";

interface DiscountShippingCostListProps {
    userDealerships: Array<any>;
    field: string;
}

const DealershipsForUsers: React.FC<DiscountShippingCostListProps> = ({
                                                                          userDealerships,
                                                                          field
                                                                      }) => {
    const formik = useFormikContext();
    const {priv} = usePrivileges();

    return (
        <Box>
            <LabelStyled ml={"8%"}>Access dealership for user</LabelStyled>
            <Flex>
                <Box minWidth={"95%"} ml={"5%"}>
                    <Box maxHeight={"200px"} mt="1rem" overflowY={"auto"} border={"1px solid"} borderRadius={"5px"}>
                        {userDealerships?.map((elem: any, index: number) => {
                            const ItemConfig: Array<ListItemConfig> = [
                                {
                                    content: <Text fontWeight={"600"}>{elem.name}</Text>,
                                    isLink: false,
                                    style: {flexBasis: "90%", justifyContent: "flex-start", ml: "0.5rem"}
                                },
                                priv?.USER_REMOVE && {
                                    content: <IconWithTooltip icon={FiTrash} tooltipMessage={'Remove'}
                                                              color={"black"}/>,
                                    clickAction: () => {
                                        const newDealer = [...userDealerships];
                                        newDealer.splice(index, 1);
                                        formik.setFieldValue(field, newDealer);
                                    },
                                    isLink: true,
                                    style: {flexBasis: "auto"},
                                }
                            ]
                            return <ListItemRow style={{justifyContent: "flex-start"}}
                                                key={index}
                                                list={ItemConfig}
                            />
                        })}
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
}
export default DealershipsForUsers;