import React, { FC, useState } from "react";
import { FieldType } from "../../common/constants";
import { FilterInterceptor } from "../../shared/utilities/Filters/FilterInterceptor";
import FilterSortHeader from "./FilterSortHeader";

interface ColumnConfig {
    id: number;
    header: string;
    width?: string;
    isLink?: boolean;
    style?: object;
    type: FieldType;
    options?: any;
}

interface ColumnHandlerProps {
    col: ColumnConfig;
    filterInterceptor: FilterInterceptor;
    setFilterConfig: (val: any) => void;
}

const ColumnHandler: FC<ColumnHandlerProps> = ({ col, filterInterceptor, setFilterConfig }) => {
    const [isFilterInputVisible, setIsFilterInputVisible] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const handleSortUpClick = () => { /* Ваш код сортировки по возрастанию */ };
    const handleSortDownClick = () => { /* Ваш код сортировки по убыванию */ };
    const handleFilterClick = () => { updateFilterState(true); };
    const handleCloseFilter = () => { updateFilterState(false); };

    const setNewFilterValue = (val: any, modal: boolean) => {
        if (val && typeof val === 'object') {
            const columnHeader = Object.keys(val)[0];
            const columnValue = val[columnHeader].trim();
            if (columnValue === "") {
                filterInterceptor.removeFilter(col.header);
                updateImageFilterState(false);
            } else {
                if (modal) {
                    const textFilterConfig = {
                        name: columnHeader,
                        typeField: col.type,
                        label: columnHeader,
                        value: columnValue,
                        options: col.options,
                        updateFunc: setNewFilterValue,
                    };
                    filterInterceptor.addFilter(textFilterConfig);
                    updateFilterState(false);
                    updateImageFilterState(true);
                }
            }
            filterInterceptor.updateFilterValue(columnHeader, columnValue);
            setFilterConfig(val);
        }
    };

    const updateFilterState = (isOpen: boolean) => {
        setIsFilterOpen(isOpen);
    };

    const updateImageFilterState = (isOpen: boolean) => {
        setIsFilterInputVisible(isOpen);
    };

    return (
        <FilterSortHeader
            headerText={col.header}
            filterName={col.header || ""}
            onFilterClick={handleFilterClick}
            onSortUpClick={handleSortUpClick}
            onSortDownClick={handleSortDownClick}
            isFilterActive={isFilterInputVisible}
            isFilterOpen={isFilterOpen}
            setFilterModalClose={handleCloseFilter}
            setNewFilterValue={setNewFilterValue}
            fieldType={col.type}
            options={col.options}
        />
    );
};

export default ColumnHandler;
