import {PayloadAction} from "@reduxjs/toolkit"
import {Dispatch} from "react"
import {ActionTypes} from "../../redux/Users/UserReducer"
import {UserRepositoryInstance} from "../../repository/User/UserRepository"
import {OrderByCreateRequest, UserCreationRequest} from "../../shared/entities/Users/User"

/**
 * UserService.
 *
 */
export class UserService {
    private dispatch: Dispatch<PayloadAction<any>>
    private repository = UserRepositoryInstance

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
    }

    public async getUserList() {
        return this.repository.getUserList().then(res => {
            this.dispatch({
                type: ActionTypes.USER_LIST,
                payload: res?.Some || []
            })
            return res
        })
    }

    public async getUserListAll(params: {}) {
        return await this.repository.getUserListAll(params).then(res => {
            this.dispatch({
                type: ActionTypes.USER_LIST_ALL,
                payload: res.Some
            })
            return res
        })

    }

    public async getDealershipAll(params: {}) {
        return await this.repository.getDealershipAll(params).then(res => {
            this.dispatch({
                type: ActionTypes.DEALERSHIP_LIST,
                payload: res.Some
            })
            return res.Some
        })

    }
    public async getAll(data: any, body: any) {
        return await this.repository.getAll(data, body).then(res => {
            this.dispatch({
                type: ActionTypes.DEALERSHIP_LIST,
                payload: res?.Some
            })
            return res
        })
    }

    public async getCompanyAll(data: any, body: any) {
        return await this.repository.getCompanyAll(data, body).then(res => {
            this.dispatch({
                type: ActionTypes.COMPANY_LIST,
                payload: res?.Some
            })
            return res
        })
    }

    public async getDealershipMinInfoAll(params: {}) {
        return await this.repository.getDealershipMinInfoAll(params).then(res => {
            this.dispatch({
                type: ActionTypes.DEALERSHIP_MIN_INFO_LIST,
                payload: res.Some
            })
            return res.Some
        })
    }

    public async getRequestRegistrationAll(params: {}) {
        return await this.repository.getRequestRegistrationAll(params).then(res => {
            this.dispatch({
                type: ActionTypes.REQUEST_REGISTRATION,
                payload: res.Some
            })
            return res.Some
        })
    }

    public async confirmCompleted(id: number) {
        return this.repository.confirmCompleted(id).then(res => {
            return res
        })
    }

    public async dealershipFeeState(data: any) {
        return this.repository.dealershipFeeState(data).then(res => {
            return res
        })
    }

    public async createUser(data: any) {
        return this.repository.createUser(data).then(res => {
            this.dispatch({
                type: ActionTypes.USER_CREATE,
                payload: res?.Some || data
            })
            return res
        })

    }
    public async createCompany(data: any) {
        return this.repository.createCompany(data).then(res => {
            return res
        })

    }

    public async changePassword(data: any) {
        return this.repository.changePassword(data).then(res => {
            this.dispatch({
                type: ActionTypes.CHANGE_PASSWORD,
                payload: res?.Some || data
            })
            return res
        })

    }

    public async getLoginCode(param: any) {
        return this.repository.getLoginCode(param).then(res => {
            this.dispatch({
                type: ActionTypes.CODE_LOGIN,
                payload: res?.Some
            })
            return res
        })

    }

    public async restorePassword(data: any) {
        return this.repository.restorePassword(data).then(res => {
            return res
        })

    }

    public async getUser(param: any, setReduce: boolean) {
        return await this.repository.getUser(param).then(res => {
            if (setReduce) {
                this.dispatch({
                    type: ActionTypes.USER_GET,
                    payload: res?.Some
                })
            }
            return res
        })
    }

    public async getCompany(id: any, setReduce: boolean) {
        return await this.repository.getCompany(id).then(res => {
            if (setReduce) {
                this.dispatch({
                    type: ActionTypes.COMPANY,
                    payload: res?.Some
                })
            }
            return res
        })
    }

    public async getDealership(params: {}) {
        return await this.repository.getDealership(params).then(res => {
            this.dispatch({
                type: ActionTypes.DEALERSHIP_GET,
                payload: res?.Some
            })
            return res
        })
    }

    public async updateUser(data: any) {
        return this.repository.updateUser(data).then(res => {
            this.dispatch({
                type: ActionTypes.USER_UPDATE,
                payload: res?.Some || {},
            })
            return res
        })
    }

    public async updateCompany(data: any) {
        return this.repository.updateCompany(data).then(res => {
            return res
        })
    }

    public cleanUser() {
        this.dispatch({
            type: ActionTypes.USER_GET,
            payload: {}
        })
    }

    public cleanCompany() {
        this.dispatch({
            type: ActionTypes.COMPANY,
            payload: {}
        })
    }

    public async deleteUser(id: number) {
        return await this.repository.deleteUser(id)
    }
    public async deleteCompany(id: number) {
        return await this.repository.deleteCompany(id)
    }

    public async deleteDealership(id: number) {
        return await this.repository.deleteDealership(id)
    }

    public async companyRegistered(id: number) {
        return await this.repository.companyRegistered(id)
    }

    public async inviteCreate(id: number) {
        return await this.repository.inviteCreate(id)
    }

    public async userCreationRequest(data: UserCreationRequest) {
        return await this.repository.userCreationRequest(data).then(res => {
            return res
        })
    }

    public async orderByCatalogCreate(data: OrderByCreateRequest) {
        return await this.repository.orderByCatalogCreate(data).then(res => {
            return res
        })
    }
    public async setNewPassword(data: any) {
        return await this.repository.setNewPassword(data).then(res => {
            return res
        })
    }
}



