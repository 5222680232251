import React, {FC} from "react";
import {Helmet} from "react-helmet";
import {WebPUtils} from "../../shared/utilities/WebPUtils";
import {ImageDTO, Product} from "../../shared/entities/Product/Product";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";

interface SchemaOrgProps {
    isGeneralPage: boolean;
    isProductGroup: boolean;
    product: Product;
    description: any;
    url: string;
}

const SchemaOrg: FC<SchemaOrgProps> = ({isGeneralPage, isProductGroup, product, url, description}) => {
    function generateImages(images: any) {
        return images.map((image: ImageDTO) => ({
            "@type": "ImageObject",
            "inLanguage": "en-US",
            "@id": `${url}/#${image.uuid}`,
            "url": WebPUtils.getImageJpgUrl("cart", image.uuid),
            "contentUrl": WebPUtils.getImageJpgUrl("original", image.uuid),
            "width": 1000,
            "height": 1000
        }));
    }

    function generateBreadcrumbs(item: BreadcrumbsData[]) {
        return item.map((el: BreadcrumbsData, idx: number) => {
            const breadcrumb: Record<string, any> = {
                "@type": "ListItem",
                "position": idx + 1,
                "name": el.text
            };
            // Убираем поле item для последнего элемента
            if (idx !== item.length - 1) {
                breadcrumb.item = `${window.location.origin}${el.link}`;
            }
            return breadcrumb;
        });
    }

    function generateImagesId(images: any) {
        return images.map((image: ImageDTO) => ({
            "@id": `${url}/#${image.uuid}`,
        }));
    }

    const schemaDataProduct = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "WebPage",
                "@id": url,
                "url": url,
                "name": product?.name,
                "isPartOf": {
                    "@id": `${window.location.origin}/#website`
                },
                // Удаляем primaryImageOfPage и image, если это групповая страница
                ...(isProductGroup
                    ? {}
                    : {
                        "primaryImageOfPage": {
                            "@id": `${url}/#${product.primaryImageUuid}`
                        },
                        "image": generateImagesId(product.images)
                    }),
                // Удаляем thumbnailUrl, если это групповая страница
                ...(isProductGroup ? {} : { "thumbnailUrl": `${url}/#previewimage` }),
                "datePublished": new Date(product.updateLast).toISOString(),
                "dateModified": new Date(product.updateLast).toISOString(),
                "description": description,
                "breadcrumb": {
                    "@id": `${url}/#breadcrumb`
                },
                "inLanguage": "en-US",
                "potentialAction": [
                    {
                        "@type": "ReadAction",
                        "target": [url]
                    }
                ]
            },
            // Удаляем секции ImageObject, если это групповая страница
            ...(isProductGroup ? [] : generateImages(product.images)),
            ...(isProductGroup
                ? []
                : [
                    {
                        "@type": "ImageObject",
                        "inLanguage": "en-US",
                        "@id": `${url}/#previewimage`,
                        "url": WebPUtils.getImageJpgUrl("preview", product.primaryImageUuid),
                        "contentUrl": WebPUtils.getImageJpgUrl("original", product.primaryImageUuid),
                        "width": 160,
                        "height": 160
                    }
                ]),
            {
                "@type": "BreadcrumbList",
                "@id": `${url}/#breadcrumb`,
                "name": `${product.url}`,
                "itemListElement": generateBreadcrumbs(WebPUtils.generateBreadcrumbs(product))
            },
            {
                "@type": "WebSite",
                "@id": `${window.location.origin}/#website`,
                "url": `${window.location.origin}/`,
                "name": "AK Dealer Services",
                "description": "Quality Auto Parts & Services",
                "publisher": {
                    "@id": `${window.location.origin}/#organization`
                },
                "potentialAction": [
                    {
                        "@type": "SearchAction",
                        "target": {
                            "@type": "EntryPoint",
                            "urlTemplate": `${window.location.origin}/?s={search_term_string}`
                        },
                        "query-input": "required name=search_term_string"
                    }
                ],
                "inLanguage": "en-US"
            },
            {
                "@type": [
                    "Organization",
                    "AutoPartsStore"
                ],
                "@id": `${window.location.origin}/#organization`,
                "name": "AK Dealer Services",
                "url": `${window.location.origin}/`,
                "logo": {
                    "@type": "ImageObject",
                    "inLanguage": "en-US",
                    "@id": `${window.location.origin}/#/schema/logo/image/`,
                    "url": `${window.location.origin}/favicon.ico`,
                    "contentUrl": `${window.location.origin}/favicon.ico`,
                    "width": 32,
                    "height": 32,
                    "caption": "AK Dealer Services"
                },
                "image": {
                    "@id": `${window.location.origin}/#/schema/logo/image/`
                },
                "sameAs": [
                    "https://www.facebook.com/AKDealerServices/",
                    "https://www.linkedin.com/company/ak-dealer-services/"
                ],
                "telephone": "+18002605051",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "7315 Gibson Cemetery Rd # 103",
                    "addressLocality": "Mansfield",
                    "addressRegion": "TX",
                    "postalCode": "76063",
                    "addressCountry": "US"
                }
            },
            // Удаляем секцию Product, если это групповая страница
            ...(isProductGroup
                ? []
                : [
                    {
                        "@type": "Product",
                        "@id": `${url}7#product`,
                        "name": product.name,
                        "description": description,
                        "sku": product.id,
                        "image": generateImagesId(product.images),
                        "brand": {
                            "@type": "Brand",
                            "name": "AK Dealer Services"
                        },
                        "offers": {
                            "@type": "Offer",
                            "priceCurrency": "USD",
                            "price": product.price,
                            "availability": "https://schema.org/InStock",
                            "url": url
                        }
                    }
                ])
        ]
    };

    const schemaDataHome = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "WebPage",
                "@id": `${window.location.origin}/`,
                "url": `${window.location.origin}/`,
                "name": "AK Dealer Services - Quality Auto Parts & Services",
                "isPartOf": {
                    "@id": `${window.location.origin}/#website`
                },
                "about": {
                    "@id": `${window.location.origin}/#organization`
                },
                "primaryImageOfPage": {
                    "@id": `${window.location.origin}/#primaryimage`
                },
                "image": {
                    "@id": `${window.location.origin}/#primaryimage`
                },
                "thumbnailUrl": `${window.location.origin}/favicon.ico`,
                "datePublished": "2023-07-01T00:00:00+00:00",
                "dateModified": "2024-09-21T00:00:00+00:00",
                "description": "AK Dealer Services provides quality auto parts and services for dealers and customers. Explore our range of products and solutions.",
                "breadcrumb": {
                    "@id": `${window.location.origin}/#breadcrumb`
                },
                "inLanguage": "en-US",
                "potentialAction": [
                    {
                        "@type": "ReadAction",
                        "target": [
                            `${window.location.origin}/`
                        ]
                    }
                ]
            },
            {
                "@type": "ImageObject",
                "inLanguage": "en-US",
                "@id": `${window.location.origin}/#primaryimage`,
                "url": `${window.location.origin}/favicon.ico`,
                "contentUrl": `${window.location.origin}/favicon.ico`,
                "width": 32,
                "height": 32
            },
            {
                "@type": "BreadcrumbList",
                "@id": `${window.location.origin}/#breadcrumb`,
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Home"
                    }
                ]
            },
            {
                "@type": "WebSite",
                "@id": `${window.location.origin}/#website`,
                "url": `${window.location.origin}/`,
                "name": "AK Dealer Services",
                "description": "Quality Auto Parts & Services",
                "publisher": {
                    "@id": `${window.location.origin}/#organization`
                },
                "potentialAction": [
                    {
                        "@type": "SearchAction",
                        "target": {
                            "@type": "EntryPoint",
                            "urlTemplate": `${window.location.origin}/?s={search_term_string}`
                        },
                        "query-input": "required name=search_term_string"
                    }
                ],
                "inLanguage": "en-US"
            },
            {
                "@type": [
                    "Organization",
                    "AutoPartsStore"
                ],
                "@id": `${window.location.origin}/#organization`,
                "name": "AK Dealer Services",
                "url": `${window.location.origin}/`,
                "logo": {
                    "@type": "ImageObject",
                    "inLanguage": "en-US",
                    "@id": `${window.location.origin}/#/schema/logo/image/`,
                    "url": `${window.location.origin}/favicon.ico`,
                    "contentUrl": `${window.location.origin}/favicon.ico`,
                    "width": 32,
                    "height": 32,
                    "caption": "AK Dealer Services"
                },
                "image": {
                    "@id": `${window.location.origin}/#/schema/logo/image/`
                },
                "sameAs": [
                    "https://www.facebook.com/AKDealerServices/",
                    "https://www.linkedin.com/company/ak-dealer-services/"
                ],
                "slogan": "Quality Auto Parts & Services",
                "description": "AK Dealer Services provides quality auto parts and services for dealers and customers.",
                "telephone": "+18002605051",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "7315 Gibson Cemetery Rd # 103",
                    "addressLocality": "Mansfield",
                    "addressRegion": "TX",
                    "postalCode": "76063",
                    "addressCountry": "US"
                }
            }
        ]
    };

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(isGeneralPage ? schemaDataHome : schemaDataProduct)}
            </script>
        </Helmet>
    );
};

export default SchemaOrg;
