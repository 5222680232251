import {
    Box, Button, Checkbox, Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter, ModalHeader,
    ModalOverlay, Text, useDisclosure
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";
import {useAppSelector} from "../../redux/Store";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {useFormikContext} from "formik";
import {MainButton} from "../../components/Buttons/MainButton";
import {ModalService} from "../../service/Modal/ModalService";
import {useDispatch} from "react-redux";
import {LabelStyled} from "../../components/Inputs/LabelStyled";
import {ListItemConfig, ListItemRow} from "../../components/List/ListItem";
import {DealershipMinInfo} from "../../shared/entities/Users/Dealership";
import {FixedSizeList as List} from "react-window";
import ReactWindow from "react-window";

export const AddDealershipModal: FC = () => {
    const event = useAppSelector(state => state.ModalReducer.event);
    const dispatch = useDispatch();
    const modalService = new ModalService(dispatch);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const formik = useFormikContext()

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [searchTerm, setSearchTerm] = useState('');
    const dealershipsNoFilters = useAppSelector(state => state.UserReducer.dealershipsMinInfo);
    const [filterDealerships, setFilterDealerships] = useState<Array<any>>([])

    interface FormValues {
        dealerships: DealershipMinInfo[]; // Предположим, что у вас есть интерфейс DealershipMinInfo для элементов в dealerships
    }

    useEffect(() => {
        if (event && event.selModel === SelectModal.ADD_DEALERSHIP) {
            let vals = formik.values as FormValues;
            if (vals.dealerships) {
                setSelectedItems(vals.dealerships);
            } else {
                setSelectedItems([])
            }
            onOpen()
        } else {
            onClose()
        }
    }, [event]);

    useEffect(() => {
        if (dealershipsNoFilters) {
            if (searchTerm) {
                setFilterDealerships(dealershipsNoFilters.filter(dealer =>
                    dealer.name.toLowerCase().includes(searchTerm.toLowerCase())
                ))
            } else {
                setFilterDealerships(dealershipsNoFilters)
            }
        }
    }, [searchTerm, dealershipsNoFilters]);

    const handleChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const handleOnclickNew = (elem: any, remove: Boolean) => {
        setSelectedItems(prevSelectedItems => {
            const isSelected = prevSelectedItems.some(item => item.id === elem.id);
            if (isSelected) {
                return remove ? prevSelectedItems.filter(item => item.id !== elem.id) : prevSelectedItems;
            } else {
                return [...prevSelectedItems, elem];
            }
        });
    };

    const handleSaveClick = () => {
        formik.setFieldValue("dealerships", selectedItems)
        modalService.resetModal();
        onClose();
    };
    const renderRow = ({index, style}: ReactWindow.ListChildComponentProps, onClose?: () => void) => {
        const elem = filterDealerships[index];

        if (!elem) return null;

        const ItemConfig: Array<ListItemConfig> = [
            {
                content: <Checkbox font={"14px"}
                                   minW={"65px"}
                                   isChecked={selectedItems.some(item => item.id === elem.id)}
                                   children={elem.id}
                                   onChange={(ev: any) => {
                                       handleOnclickNew(elem, selectedItems.some(item => item.id === elem.id))
                                   }}
                />,
                isLink: false,
                style: {flexBasis: "5%", justifyContent: "flex-start", ml: "0.5rem"}
            }, {
                content: <Text
                    fontWeight={"600"}>{elem.name}</Text>,
                isLink: false,
                style: {
                    flexBasis: "90%",
                    justifyContent: "flex-start",
                    ml: "0.5rem"
                }
            }
        ]

        return (
            <Box style={style} key={index}>
                <ListItemRow list={ItemConfig}/>
            </Box>
        );
    };
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Discount product for dealership</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <Box mb="4" display="flex" flexWrap="wrap" maxHeight={"200px"} overflowY={"auto"}>
                        {selectedItems.map((item) => (
                            <Button
                                key={item.id}
                                mr="2"
                                colorScheme="teal"
                                variant="solid"
                                size="sm"
                                mb={"2"}
                                onClick={() => handleOnclickNew(item, true)}
                            >
                                {item.name} ✕
                            </Button>
                        ))}
                    </Box>

                    <Input
                        height="2em"
                        placeholder="Search dealership"
                        value={searchTerm}
                        onChange={handleChange}
                    />
                    <Box>
                        <LabelStyled ml={"8%"}>Access dealership for user</LabelStyled>
                        <Flex>
                            <Box minWidth={"95%"} ml={"5%"}>
                                <Box maxHeight={"450px"} mt="1rem" border={"1px solid"}
                                     borderRadius={"5px"}>
                                    <List
                                        height={400} // Высота видимой области
                                        itemCount={filterDealerships.length} // Количество элементов в списке
                                        itemSize={40} // Высота одного элемента
                                        width="100%" // Ширина контейнера
                                    >
                                        {(props) => renderRow(props, onClose)}
                                    </List>
                                </Box>
                            </Box>
                        </Flex>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <MainButton onClick={handleSaveClick}> Save </MainButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
