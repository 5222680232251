import {Box, ChakraProps, Text} from "@chakra-ui/react";
import ru from "date-fns/locale/ru";
import enUS from "date-fns/locale/en-US";
import {ErrorMessage, useField, useFormikContext} from "formik";
import React, {FC, useState} from "react";
import ReactDatePicker, {ReactDatePickerProps, registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import "./date-picker.css";

/**
 * Компонент для выбора и устоановки даты и времени
 * @param param0
 * @returns
 */
export const DatePickerStyled: FC<Omit<ReactDatePickerProps, "onChange">
    & { specialLabel?: string, customStyle?: ChakraProps, isDisabled?: boolean, optionalAction?: Function }> = (props) => {
    registerLocale("ru", ru);
    const {setFieldValue} = useFormikContext();
    const [field] = useField(props as unknown as any);

    const DatePickerStyledConfig: ChakraProps = {
        maxW: "200px",
    }

    return (
        <Box p="16px 10px 0px 0px" minW="80px" maxW="min-content" className="light-theme" {...DatePickerStyledConfig}>
            <Text fontSize="14px" color="gray.400" mb="2px">{props.title}</Text>
            <ReactDatePicker
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    if (val === null && field.name === "start_date") {
                        return;
                    }
                    if (val === null) {
                        setFieldValue(field.name, val)
                        return;
                    }
                    //@ts-ignore
                    let newVal = new Date(val)
                    newVal.setHours(0, 0, 0, 0);
                    setFieldValue(field.name, newVal)
                    if (props?.optionalAction) {
                        props?.optionalAction()
                    }
                }}

                disabled={props.isDisabled}
                locale={ru}
                dateFormat={"dd.MM.yyyy"}
                minDate={props?.minDate}
                showYearPicker={false}
                showYearDropdown={false}
                isClearable={props?.isClearable}
            />
            <Box className="specialError">
                <ErrorMessage name={field.name}/>
            </Box>
        </Box>
    )
}

/**
 * Компонент для выбора и установки даты и времени
 * @returns
 * @param props
 */
export const DatePickerUpdateStyled: FC<Omit<ReactDatePickerProps, "onChange"> & {
    specialLabel?: string;
    customStyle?: ChakraProps;
    isDisabled?: boolean;
    optionalAction?: Function;
    title?: string;
    placeholderText?: string;
    onChange: (value: Date | null) => void;
    error?: string;
}> = (props) => {
    registerLocale("ru", ru);

    const DatePickerStyledConfig: ChakraProps = {
        maxW: "200px",
    };
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (value: Date | null) => {
        setSelectedDate(value); // Обновляем локальное состояние
        props.onChange(value); // Вызываем переданную функцию onChange
    };
    return (
        <Box  {...DatePickerStyledConfig}  className="light-theme">
            <ReactDatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                disabled={props.isDisabled}
                placeholderText={props.placeholderText}
                locale={enUS}
                dateFormat={"MM.dd.yyyy"}
                minDate={props.minDate}
                showYearPicker={false}
                showYearDropdown={false}
                isClearable={props.isClearable}
            />
        </Box>
    );
};

export const DatePickerRangeStyled: FC<Omit<ReactDatePickerProps, "onChange"> & {
    specialLabel?: string;
    customStyle?: ChakraProps;
    isDisabled?: boolean;
    optionalAction?: Function;
    title?: string;
    onChange: (value: Date | null) => void;
    error?: string;
}> = (props) => {
    registerLocale("ru", ru);

    const DatePickerStyledConfig: ChakraProps = {
        maxW: "400px",
    };
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (value: Date | null) => {
        setSelectedDate(value); // Обновляем локальное состояние
        props.onChange(value); // Вызываем переданную функцию onChange
    };
    return (
        <Box  {...DatePickerStyledConfig}  className="light-theme">
            <ReactDatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                disabled={props.isDisabled}
                placeholderText={'From date'}
                locale={enUS}
                dateFormat={"dd.MM.yyyy"}
                minDate={props.minDate}
                showYearPicker={false}
                showYearDropdown={false}
                isClearable={props.isClearable}
            />
            <ReactDatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                disabled={props.isDisabled}
                placeholderText={'End date'}
                locale={enUS}
                dateFormat={"dd.MM.yyyy"}
                minDate={props.minDate}
                showYearPicker={false}
                showYearDropdown={false}
                isClearable={props.isClearable}
            />
        </Box>
    );
};

export const TimePickerStyled: FC<Omit<ReactDatePickerProps, "onChange">
    & { specialLabel?: string, customStyle?: ChakraProps, isDisabled?: boolean }> = (props) => {
    const {setFieldValue} = useFormikContext();
    const [field] = useField(props as unknown as any);
    const DatePickerStyledConfig: ChakraProps = {
        maxW: "125px",
        minW: "110px",
        mt: props.specialLabel ? "8px" : "5px",
        minH: "45px",
        pr: "8px",
        pl: "8px",
        ...props.customStyle
    }


    return (
        <Box {...DatePickerStyledConfig} className="light-theme" display="flex" flexDir="column"
             justifyContent="center">
            {props.specialLabel && <Text fontSize="14px" color="gray.400" mb="2px">{props.specialLabel}</Text>}
            <ReactDatePicker
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    setFieldValue(field.name, val)
                }}
                disabled={props.isDisabled}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                wrapperClassName="time-wrapper"
                timeCaption="Время"
                className="time"
                name={props.name}
            />
            <Box className="specialError">
                <ErrorMessage name={field.name}/>
            </Box>
        </Box>
    )
}

