import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Flex, useToast} from "@chakra-ui/react";
import {usePrivileges} from "../../hooks/PrivilegesProvider";
import {ListProductItemConfig, ListProductItemRow} from "../../components/List/ListProductItem";
import {Loader} from "../../components/Loader/Loader";
import {Paginator} from "../../components/Paignator/Paginator";
import FilterSortHeader from "../../components/Buttons/FilterSortHeader";
import {ModalService} from "../../service/Modal/ModalService";
import {FieldType} from "../../common/constants";
import {FilterConfig, FilterInterceptor} from "../../shared/utilities/Filters/FilterInterceptor";
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {SetBreadcrumbsLinks} from "../../redux/Breadcrumbs/BreadcrumbsReducer";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {SubscribeController} from "../../controllers/SubscribeController";
import {ExcludeSubscribe} from "../../shared/entities/Subscribe/ExcludeSubscribe";

import {DownloadButton} from "../../components/Buttons/DownloadButton";
import {DownloaderInstance} from "../../service/Downloader/Downloder";
import IconWithTooltip from "../../components/Icon/IconWithTooltip";
import {FiTrash} from "react-icons/all";
import {useAppSelector} from "../../redux/Store";
import ExcludeSubscribePopover from "../Modal/ExcludeSubscribePopover";


export const SubscribeExclude: FC<{ links: Array<BreadcrumbsData> }> = ({links}) => {
        const dispatch = useDispatch();
        const toast = useToast();
        const {priv} = usePrivileges();
        const [isLoading, setIsLoading] = useState(false)
        const modalService = new ModalService(dispatch)
        const [refreshSubscribe, setRefreshSubscribe] = useState(false);
        const [listAll, setListAll] = useState<any>({excludeSubscribe: [], count: 0})
        const subscribeList = useAppSelector(state => state.SubscribeReducer.subscribeList)
        const subscribeController = new SubscribeController(dispatch)
        SetBreadcrumbsLinks(dispatch, links);

        const [currentPaginatorPosition, setCurrentPaginatorPosition] = useState(1)
        const [previewPaginatorPosition, setPreviewPaginatorPosition] = useState(1)
        const [filterInterceptor] = useState(new FilterInterceptor([]));
        const [filterConfig, setFilterConfig] = useState<any>()


        const handlePageChange = (newPage: number) => {
            setPreviewPaginatorPosition(currentPaginatorPosition);
            setCurrentPaginatorPosition(newPage);
        };


        useEffect(() => {
            setIsLoading(true)
            const reqConfig = {
                params: {
                    page: (currentPaginatorPosition === previewPaginatorPosition) ? 0 : (currentPaginatorPosition > 0) ? currentPaginatorPosition - 1 : 0,
                    size: 20
                }
            }
            setPreviewPaginatorPosition(currentPaginatorPosition);
            filterInterceptor.getFilterMap().forEach((filter, key) => {
                if (filter !== undefined) {
                    //@ts-ignore
                    reqConfig.params[key] = filter.value;
                }
            })
            subscribeController.getSubscribeExcludeAll(reqConfig?.params).then(res => {
                setIsLoading(false)
                setListAll(res)
            })
            subscribeController.getSubscribeList({})
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filterConfig, currentPaginatorPosition, refreshSubscribe])

        interface ColumnConfig {
            id?: number;
            header: string;
            width?: string;
            isLink?: boolean;
            style?: object;
            type: FieldType;
            options?: any;
            filterField?: string;
        }

        const handleSortUpClick = () => {
        };
        const handleSortDownClick = () => {
        };

        const columnsAll: Array<ColumnConfig> = [
            {
                header: "ID",
                filterField: "id",
                isLink: false,
                width: "15%",
                type: FieldType.Input
            }, {
                header: "DATE",
                filterField: "date",
                isLink: false,
                width: "20%",
                type: FieldType.Input
            }, {
                header: "EMAIL",
                filterField: "email",
                isLink: false,
                width: "60%",
                type: FieldType.Input
            },

            {header: "ACTIONS", filterField: "actions", isLink: false, width: "auto", type: FieldType.Input},
        ];

        const [isFilterInputVisible, setIsFilterInputVisible] = useState(columnsAll.map(() => false));
        const [isFilterOpen, setIsFilterOpen] = useState(columnsAll.map(() => false));
        const handleFilterClick = (column: ColumnConfig, sel: boolean) => {
            if (typeof column.id === 'number') {
                updateFilterState(column.id, sel);
            }
        };

        const updateFilterState = (columnId: number, isOpen: boolean) => {
            setIsFilterOpen(prevIsFilterOpen => {
                const newIsFilterOpen = [...prevIsFilterOpen];
                newIsFilterOpen[columnId] = isOpen;
                return newIsFilterOpen;
            });
        };

        const updateImageFilterState = (columnId: number, isOpen: boolean) => {
            setIsFilterInputVisible(prevIsFilterOpen => {
                const newIsFilterOpen = [...prevIsFilterOpen];
                newIsFilterOpen[columnId] = isOpen;
                return newIsFilterOpen;
            });
        };

        const setNewFilterValue = (val: any, modal: boolean) => {
            if (val && typeof val === 'object') {
                const columnFilterField = Object.keys(val)[0];
                const columnValue = val[columnFilterField];
                const column = columnsAll.find((col) => col.filterField === columnFilterField)
                if (column) {
                    if (columnValue === "") {
                        filterInterceptor.removeFilter(column.filterField || "");
                        if (typeof column.id === 'number') {
                            updateImageFilterState(column.id, false);
                        }
                    } else {

                        if (modal) {
                            const textFilterConfig: FilterConfig = {
                                name: columnFilterField,
                                typeField: column?.type,
                                label: column.header,
                                value: columnValue,
                                options: column.options,
                                updateFunc: setNewFilterValue,
                            };
                            filterInterceptor.addFilter(textFilterConfig);
                            if (typeof column.id === 'number') {
                                updateFilterState(column.id, false);
                                updateImageFilterState(column.id, true);
                            }
                        }
                    }
                    filterInterceptor.updateFilterValue(columnFilterField, columnValue);
                    setFilterConfig(val)
                }
            }
        }
        const ColumnNamesMapping: Array<ListProductItemConfig> = columnsAll.map(
            (col: ColumnConfig, index: number) => {
                col.id = index;
                return {
                    content: col.header === "ACTIONS" || col.header === "ID" || col.header === "DATE"
                        ? col.header : (
                            <FilterSortHeader
                                headerText={col.header}
                                filterName={col.filterField || ""}
                                onFilterClick={() => handleFilterClick(col, true)}
                                onSortUpClick={handleSortUpClick}
                                onSortDownClick={handleSortDownClick}
                                isFilterActive={isFilterInputVisible[index]}
                                isFilterOpen={isFilterOpen[index]}
                                setFilterModalClose={() => handleFilterClick(col, false)}
                                setNewFilterValue={setNewFilterValue}
                                fieldType={col.type}
                                options={col.options}
                            />
                        ),
                    width: columnsAll[index].width || "auto",
                    isLink: col.isLink || false,
                    style: col.style || {},
                };
            }
        );

        const confirmRemove = (id: number) => {
            modalService.setModalData({
                onSubmit: () => {
                    modalService.deleteModalData();
                    subscribeController.removeExcludeSubscribe({id: id}).then((res) => {
                        toast({
                            position: "top",
                            title: res.None ? 'Failed removed id: ' + id : 'Order removed id: ' + id,
                            status: res.None ? 'error' : 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                        setRefreshSubscribe(prevIndex => !prevIndex);
                    })
                },
                isOpen: true,
                onOpen: () => {
                },
                onClose: () => {
                    modalService.deleteModalData();
                },
                modalTextData: "Do you sure remove exclude subscribe",
                context: "action"
            });
        };
        const List = listAll?.excludeSubscribe ? listAll.excludeSubscribe
            .map((elem: ExcludeSubscribe, idx: number) => {
                const ItemConfig: Array<ListProductItemConfig> = [
                    {
                        content: elem.id,
                        isLink: false,
                        width: columnsAll.find((col) => col.filterField === 'id')?.width || "auto",
                    },
                    {
                        content: elem.createdAt,
                        isLink: false,
                        width: columnsAll.find((col) => col.filterField === 'date')?.width || "auto",
                    },
                    {
                        content: elem.email,
                        isLink: false,
                        width: columnsAll.find((col) => col.filterField === 'email')?.width || "auto",
                    },

                    priv?.SUBSCRIBE_EXCLUDE_REMOVE && {
                        content: <IconWithTooltip icon={FiTrash} tooltipMessage={'Remove from List'}/>,
                        clickAction: () => confirmRemove(elem.id),
                        isLink: true,
                        width: columnsAll.find((col) => col.filterField === 'actions')?.width || "auto",
                    }
                ].filter(Boolean);
                return <ListProductItemRow key={idx} list={ItemConfig}
                                           style={{fontSize: "13px", minHeight: "35px", justifyContent: "flex-start",}}/>
            }) : null;
        const downloadFunc = async () => {
            //@ts-ignore
            return await DownloaderInstance.getSubscribeListXls();
        }

        return (
            <>
                <Breadcrumbs/>
                <Flex flexDir="column" height="100%">
                    <Flex justifyContent={"flex-end"}>
                        {priv?.SUBSCRIBE_EXCLUDE_CREATE && (
                            <ExcludeSubscribePopover item={Object}
                                                     onSuccess={() => setRefreshSubscribe(prevIndex => !prevIndex)}/>
                        )}
                        <DownloadButton clickAction={() => downloadFunc()} count={subscribeList.length}
                                        nameFile={"subscribe-mailchimp.xlsx"}/>
                    </Flex>
                    <Flex flexDir="row" mb="10px" justifyContent="space-between" alignItems="end">
                        <Flex
                            flexDir="row"
                            alignItems="end"
                            width="80%"
                            fontFamily="Poppins"
                            position="relative"
                            flexWrap="wrap">
                            {filterInterceptor.getFiltersRowWithCreateButton()}
                        </Flex>
                    </Flex>
                    {
                        isLoading
                            ? <Loader/>
                            : <>
                                <ListProductItemRow list={ColumnNamesMapping} style={{
                                    fontSize: "13px",
                                    fontWeight: "700",
                                    backgroundColor: "aliceblue",
                                    justifyContent: "flex-start"
                                }}/>
                                {List}
                            </>
                    }
                </Flex>
                <Paginator limit={typeof listAll?.count === 'number' ? listAll?.count : 0}
                           current={currentPaginatorPosition}
                           swapPageTrigger={handlePageChange} step={20}/>
            </>
        )
    }
;

export default SubscribeExclude;