import {CustomError} from "../../shared/errors/CustomErrors";
import {Option} from "../../shared/utilities/OptionT";
import {APIDao, Requester} from "../APIRequester";
import {
    Product, ProductChangeOrderBy,
    ProductChangeParent,
    ProductChangeType,
    ProductRequest,
    ProductSetArchive
} from "../../shared/entities/Product/Product";
import {PRODUCT_API_PREFIX} from "../../common/constants";

/**
 * ProductRepository.
 *
 * Responsible for fetching data from backend
 * by callind APIDao and handling various data
 * coming from API
 *
 * Data is passed to ClientService in a form of
 * an @type Option<T> type, where @param{Some: T} is either
 * a valid data, expected from the server,
 * or, in case of error, a default safe value,
 * for an object of array (kinda like default
 * values for types in Go),and @param{None: CustomError}
 * is either an error or undefined
 */
export class ProductRepository {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    public async getProducts(params: {}): Promise<Option<{ items: Array<Product>, count: number }>> {
        return await this.dao.getRequest<{ items: Array<Product>, count: number }>(`${PRODUCT_API_PREFIX}/all`, {params: {...params}})
            .then(res => {
            const result: Option<{ items: Array<Product>, count: number }> = {
                Some: {
                    items: res?.data?.items,
                    count: res?.data?.count
                }
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<{ items: Array<Product>, count: number }> = {
                //@ts-ignore
                Some: {
                    items: [],
                    count: 0
                },
                None: err
            }
            return result
        })
    }

    public async getProductsIds(params: {}): Promise<Option<{ items: Array<Product>, count: number }>> {
        return await this.dao.getRequest<{ items: Array<Product>, count: number }>(`${PRODUCT_API_PREFIX}/ids`, {params: {...params}})
            .then(res => {
                const result: Option<{ items: Array<Product>, count: number }> = {
                    Some: {
                        items: res?.data?.items,
                        count: res?.data?.count
                    }
                }
                return result
            }).catch((err: CustomError) => {
                const result: Option<{ items: Array<Product>, count: number }> = {
                    //@ts-ignore
                    Some: {
                        items: [],
                        count: 0
                    },
                    None: err
                }
                return result
            })
    }

    public async getProduct(id: number): Promise<Option<Product>> {
        return await this.dao.getRequest<Product>(`${PRODUCT_API_PREFIX}/${id}`, {}).then(res => {
            const result: Option<Product> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<Product> = {
                Some: {} as Product,
                None: err
            }
            return result
        })
    }

    public async changeTypeProduct(data: ProductChangeType): Promise<Option<number>> {
        return await this.dao.postRequest<number>(`${PRODUCT_API_PREFIX}/change-type`, {data}).then(res => {
            const result: Option<number> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }

    public async changeParentProduct(data: ProductChangeParent): Promise<Option<number>> {
        return await this.dao.postRequest<number>(`${PRODUCT_API_PREFIX}/change-parent`, {data}).then(res => {
            const result: Option<number> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }

    public async changeOrderByProduct(data: ProductChangeOrderBy): Promise<Option<number>> {
        return await this.dao.postRequest<number>(`${PRODUCT_API_PREFIX}/change-order-by`, {data}).then(res => {
            const result: Option<number> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }

    public async setArchiveProduct(data: ProductSetArchive): Promise<Option<number>> {
        return await this.dao.postRequest<number>(`${PRODUCT_API_PREFIX}/set-archive`, {data}).then(res => {
            const result: Option<number> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }

    public async createProduct(data: ProductRequest): Promise<Option<number>> {
        return await this.dao.postRequest<number>(`${PRODUCT_API_PREFIX}/create-update`, {data}).then(res => {
            const result: Option<number> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }

    public async deleteProduct(id: number) {
        await this.dao.putRequest<Product>(`${PRODUCT_API_PREFIX}/delete/${id}`, {});
    }
}

export const ProductRepositoryInstance = new ProductRepository(APIDao)
