import {Flex} from "@chakra-ui/react";
import React, {FC} from "react";
import {useDispatch} from "react-redux";
import {ModalService} from "../../service/Modal/ModalService";
import {Option} from "../../shared/utilities/OptionT";
import IconWithTooltip from "../Icon/IconWithTooltip";
import {AiOutlineFileExcel} from "react-icons/all";

var fileDownload = require('js-file-download');

/**
 * Кнопка скачивания документов в реестрах
 *
 * Принимает функцию, которая должна вызываться по названию
 * @param param0
 * @returns
 */
export const DownloadButton: FC<{ clickAction: Function, height?: string, count?: number, nameFile: string }> = ({
                                                                                                                     clickAction,
                                                                                                                     height,
                                                                                                                     count,
                                                                                                                     nameFile
                                                                                                                 }) => {
    const dispatch = useDispatch()
    const modalService = new ModalService(dispatch)

    const handleClick = () => {
        clickAction()?.then((res: Option<any>) => {
            if (res?.None) {
                modalService.setModalData({
                    onSubmit: () => modalService.deleteModalData(),
                    isOpen: true,
                    onOpen: () => {
                    },
                    onClose: () => modalService.deleteModalData(),
                    modalTextData: "Error upload File",
                    context: "error"
                })
            } else {
                fileDownload(res.Some, nameFile);
            }
        })
    }

    return (
        <Flex py={2}>
            <IconWithTooltip icon={AiOutlineFileExcel} tooltipMessage={'Upload Excel count: ' + count} fontSize={"22px"}
                             onClick={() => handleClick()}/>
        </Flex>
    )
}