import React, {useState, FC} from 'react';
import {useLocation, useNavigate} from "react-router";
import {Box, ChakraProps, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {FaSearch, FaWindowClose} from "react-icons/fa";

export const SearchWithHistory: FC<{
    isMobile?: boolean
    height?: any,
    width?: any,
    style?: ChakraProps
}> = ({style, height = "40px", width = "200px", isMobile = false}) => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();



    const handleSearch = (searchQuery: string) => {
        searchQuery ? params.set("s", searchQuery) : params.delete("s");
        navigate({pathname: location.pathname, search: params.toString()});
        if (searchQuery.trim() === '') return;
    };

    const handleSelectQuery = (query: string) => {
        setSearchQuery(query); // Подставляем выбранный запрос в строку поиска
        handleSearch(query)
    };

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            handleSelectQuery(event.target.value)
        }
    }
    const FilterDefaultStyleConfig: ChakraProps = {
        h: height,
        w: width,

        fontSize: "14px",
        color: "gray.700",
        paddingRight: "60px",
        _active: {
            borderColor: "gray.500"
        },
        _focus: {
            border: "2px solid",
            borderColor: "gray.500"
        },
        ...style
    }

    return (
        <Box maxW={width}>
            <InputGroup>
                <Input {...FilterDefaultStyleConfig}
                       placeholder="Search"
                       value={searchQuery}
                       onChange={(e) => setSearchQuery(e.target.value)}
                       onKeyPress={handleKeyPress}
                />
                <InputRightElement h={height} cursor="pointer">
                    <FaSearch onClick={() => handleSelectQuery(searchQuery)}/>
                </InputRightElement>
                {searchQuery.length > 0 && <InputRightElement right="25px" h={height} cursor="pointer">
                    <FaWindowClose onClick={() => handleSelectQuery("")}/>
                </InputRightElement>}
            </InputGroup>
        </Box>
    );
};

export default SearchWithHistory;
