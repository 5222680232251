import {ChakraProps, Flex, Container} from "@chakra-ui/react";
import React, {FC, useEffect, Suspense} from "react";
import {useDispatch} from "react-redux";
import {Route, Routes, useLocation} from "react-router";
import {Navbar} from "../components/Navbars/Navbar";
import {URLPaths} from "../config/application/URLPaths";
import {AuthorizationController} from "../controllers/AuthController";
import {usePrivileges} from "../hooks/PrivilegesProvider";
import {generalView} from "../theme/foundations/breakpoints";
import {NavbarBottom} from "../components/Navbars/NavbarBottom";
import Products from "./Products/Products";
import SubscribeExclude from "./Mail/SubscribeExclude";
import Page404 from "./404/Page404";
import ChangePassword from "./Forms/Accounts/ChangePassword";
import AuthorizationForm from "./Forms/Authoization/AuthorizationForm";
import AboutUs from "./Home/AboutUs";
import Contacts from "./Home/Contacts";
import GreatSave from "./Home/GreatSave";
import OrderByCatalog from "./Home/OrderByCatalog";
import OrderBasket from "./Order/OrderBasket";
import RestorePassword from "./Forms/Accounts/RestorePassword";
import RequestRegistrationForm from "./Forms/Clients/RequestRegistrationForm";
import NewProductForm from "./Forms/Products/NewProductForm";
import OrderHistory from "./Order/OrderHistory";
import Account from "./Clients/Account";
import ClientForm from "./Forms/Clients/ClientForm";
import Clients from "./Clients/Clients";
import RoleForm from "./Forms/Roles/RoleForm";

// Ленивая загрузка компонентов
// const Page404 = React.lazy(() => import('./404/Page404'));
// const RoleForm = React.lazy(() => import('./Forms/Roles/RoleForm'));
// const AuthorizationForm = React.lazy(() => import('./Forms/Authoization/AuthorizationForm'));
// const OrderBasket = React.lazy(() => import('./Order/OrderBasket'));
// const OrderHistory = React.lazy(() => import('./Order/OrderHistory'));
// const Account = React.lazy(() => import('./Clients/Account'));
// const ChangePassword = React.lazy(() => import('./Forms/Accounts/ChangePassword'));
// const RestorePassword = React.lazy(() => import('./Forms/Accounts/RestorePassword'));
// const ClientForm = React.lazy(() => import('./Forms/Clients/ClientForm'));
// const NewProductForm = React.lazy(() => import('./Forms/Products/NewProductForm'));
// const Clients = React.lazy(() => import('./Clients/Clients'));
// const AboutUs = React.lazy(() => import('./Home/AboutUs'));
// const Contacts = React.lazy(() => import('./Home/Contacts'));
// const RequestRegistrationForm = React.lazy(() => import('./Forms/Clients/RequestRegistrationForm'));
// const GreatSave = React.lazy(() => import('./Home/GreatSave'));
// const OrderByCatalog = React.lazy(() => import('./Home/OrderByCatalog'));

interface MainContainerProps {
    isAuth: boolean;
}

export const MainContainer: FC<MainContainerProps> = ({isAuth}) => {
    const dispatch = useDispatch();
    const authController = new AuthorizationController(dispatch);
    const {priv} = usePrivileges();
    const isRender = new URLSearchParams(useLocation().search).get("is_render") === "true";  // Проверка наличия параметра

    const containerStyleConfig: ChakraProps = {
        px: {base: 4, lg: 0},
        maxW: ['container.sm', 'container.md', 'container.lg', 'container.xl']
    };
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const refreshAndSetTimer = async () => {
            try {
                if (isAuth) {
                    await authController.refresh().then((res) => {
                        if (res) {
                            return;
                        }
                    });
                    sessionStorage.setItem('lastRefreshTime', new Date().getTime().toString());
                    clearInterval(intervalId);

                    intervalId = setInterval(async () => {
                        const lastRefreshTimeRaw = sessionStorage.getItem('lastRefreshTime');
                        const lastRefreshTime = lastRefreshTimeRaw ? parseInt(lastRefreshTimeRaw, 10) : new Date().getTime();
                        const tokenExpirationTime = lastRefreshTime + 24 * 60 * 60 * 1000;
                        console.log(
                            'Current time:', new Date(lastRefreshTime).toLocaleTimeString(),
                            'Time since lastRefreshTimeRaw:', new Date(tokenExpirationTime).toLocaleTimeString()
                        );

                        await refreshAndSetTimer();
                    }, 23 * 60 * 60 * 1000);
                }
            } catch (error) {
                console.error('Error refreshing token:', error);
            }
        };

        if (isAuth) {
            refreshAndSetTimer();
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isAuth]);

    return (
        <Flex width={"100%"} minHeight={"100%"}>
            <Container {...containerStyleConfig}>
                {!isRender && <Navbar />}
                <Flex flexDir="column" minHeight={{lg: generalView.home.height}}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path={"*" || "/404"} element={<Page404/>}/>
                            <Route path={URLPaths.AUTH.link + "/*"}
                                   element={<AuthorizationForm links={[URLPaths.HOME, URLPaths.AUTH]}/>}/>
                            <Route path={URLPaths.PASSWORD_RESTORE_CODE.link} element={<ChangePassword
                                links={[URLPaths.HOME, URLPaths.ACCOUNT, URLPaths.CHANGE_PASSWORD]}/>}/>
                            <Route path={URLPaths.PASSWORD_CREATE.link} element={<ChangePassword
                                links={[URLPaths.HOME, URLPaths.ACCOUNT, URLPaths.CHANGE_PASSWORD]}/>}/>
                            <Route path={URLPaths.HOME.link} element={<Products/>}/>
                            <Route path={URLPaths.PRODUCTS.link + ":urlItem"} element={<Products/>}/>
                            <Route path={URLPaths.ABOUT_US.link}
                                   element={<AboutUs links={[URLPaths.HOME, URLPaths.ABOUT_US]}/>}/>
                            <Route path={URLPaths.CONTACTS.link}
                                   element={<Contacts links={[URLPaths.HOME, URLPaths.CONTACTS]}/>}/>
                            <Route path={URLPaths.GREAT_SAVE.link}
                                   element={<GreatSave links={[URLPaths.HOME, URLPaths.GREAT_SAVE]}/>}/>
                            <Route path={URLPaths.ORDER_BY_CATALOG.link}
                                   element={<OrderByCatalog links={[URLPaths.HOME, URLPaths.ORDER_BY_CATALOG]}/>}/>
                            <Route path={URLPaths.ORDER_BY_CATALOG.link + "/:id"}
                                   element={<OrderByCatalog links={[URLPaths.HOME, URLPaths.ORDER_BY_CATALOG]}/>}/>
                            <Route path={URLPaths.CART.link} element={<OrderBasket/>}/>
                            <Route path={URLPaths.RESTORE_PASSWORD.link}
                                   element={<RestorePassword links={[URLPaths.HOME]}/>}/>
                            <Route path={URLPaths.ACCOUNT_REGISTRATION.link} element={<RequestRegistrationForm
                                links={[URLPaths.HOME, URLPaths.ACCOUNT_REGISTRATION]} isEditing={false}/>}/>
                            <Route path={URLPaths.ORDER_UNREGISTERED.link + ":id"} element={<OrderBasket/>}/>
                            {priv?.PRODUCT_VIEW && (
                                <>
                                    <Route path={URLPaths.HOME_ARCHIVE.link} element={<Products/>}/>
                                    <Route path={URLPaths.PRODUCTS_ARCHIVE.link + ":urlItem"} element={<Products/>}/>
                                    <Route path={URLPaths.PRODUCTS_CREATE.link + ":id"}
                                           element={<NewProductForm edit={false}/>}/>
                                    <Route path={URLPaths.PRODUCTS_EDIT.link + ":id"}
                                           element={<NewProductForm edit={true}/>}/>
                                </>
                            )}
                            {priv?.ORDER_VIEW && (
                                <>
                                    <Route path={URLPaths.ORDER_VIEW.link + ":id"} element={<OrderBasket/>}/>
                                    <Route path={URLPaths.ORDER_HISTORY.link}
                                           element={<OrderHistory links={[URLPaths.HOME, URLPaths.ORDER_HISTORY]}/>}/>
                                    <Route path={URLPaths.ORDER_HISTORY.link + "/:id"}
                                           element={<OrderHistory links={[URLPaths.HOME, URLPaths.ORDER_HISTORY]}/>}/>
                                    <Route path={URLPaths.ORDER_HISTORY_USER.link + "/:id"} element={<OrderHistory
                                        links={[URLPaths.HOME, URLPaths.ORDER_HISTORY_USER]}/>}/>
                                    <Route path={URLPaths.SUBSCRIBE_EXCLUDE.link} element={<SubscribeExclude
                                        links={[URLPaths.HOME, URLPaths.SUBSCRIBE_EXCLUDE]}/>}/>

                                </>
                            )}
                            {priv?.SETTING_VIEW && (
                                <>
                                    <Route path={URLPaths.ACCOUNT.link}
                                           element={<Account links={[URLPaths.HOME, URLPaths.ACCOUNT]}/>}/>
                                    <Route path={URLPaths.CHANGE_PASSWORD.link} element={<ChangePassword
                                        links={[URLPaths.HOME, URLPaths.ACCOUNT, URLPaths.CHANGE_PASSWORD]}/>}/>
                                </>
                            )}
                            {priv?.USER_VIEW && (
                                <>
                                    <Route path={URLPaths.COMPANY_CREATE.link}
                                           element={<ClientForm links={[URLPaths.HOME, URLPaths.COMPANIES]}
                                                                isEditing={false}/>}/>
                                    <Route path={URLPaths.COMPANY_VIEW.link + ":paramCompanyId"}
                                           element={<ClientForm links={[URLPaths.HOME, URLPaths.COMPANIES]}
                                                                isEditing={false}/>}/>
                                    <Route path={URLPaths.COMPANY_EDIT.link + ":paramCompanyId"}
                                           element={<ClientForm links={[URLPaths.HOME, URLPaths.COMPANIES]}
                                                                isEditing={true}/>}/>
                                    <Route path={URLPaths.COMPANY_VIEW.link + ":paramCompanyId/:paramDealershipId"}
                                           element={<ClientForm links={[URLPaths.HOME, URLPaths.COMPANIES]}
                                                                isEditing={false}/>}/>
                                    <Route path={URLPaths.COMPANIES.link} element={<Clients links={[URLPaths.HOME]}/>}/>
                                </>
                            )}
                            {priv?.ROLE_VIEW && <Route path={URLPaths.ROLES.link}
                                                       element={<RoleForm links={[URLPaths.HOME, URLPaths.ROLES]}/>}/>}
                        </Routes>
                    </Suspense>
                </Flex>
                {!isRender && <NavbarBottom/>}
            </Container>
        </Flex>
    );
};
