import {ChakraProps, Flex, Text} from "@chakra-ui/react";
import React, {FC} from "react";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {Icon} from "@chakra-ui/icons";
import {
    FaAngleRight
} from "react-icons/all";
import CustomLink from "../Link/CustomLink";


/**
 * Компонент бредкрабмс - это стандартные хлебные крошки
 * на всех страницах портала
 *
 */
export const Breadcrumbs: FC = () => {
    const savedBreadItems = sessionStorage.getItem("breadcrumbsData");
    let breadcrumbsData: Array<BreadcrumbsData> = [];
    if (savedBreadItems) {
        breadcrumbsData = JSON.parse(savedBreadItems);
    }
    const BreadcrumbWrapperStyleConfig: ChakraProps = {fontSize: "sm"}

    function truncateText(text: string, maxLength: number) {
        if (!text) return "";
        if (text.length <= maxLength) {
            return text;
        } else {
            return '...' + text.substring(text.length - maxLength);
        }
    }

    const Links = breadcrumbsData?.map((elem: BreadcrumbsData, idx: number) => {
        const isLast = breadcrumbsData.length - 1 === idx;
        const truncatedText = truncateText(elem.text, 50); // Обрезаем текст и начинаем с трех точек

        return (
            <Flex key={idx} alignItems={"center"}>
                {!isLast ? (
                    <CustomLink href={elem.link}>
                        <Text
                            fontSize={14}
                            fontWeight={"sm"}
                            color="gray.400"
                            cursor="pointer"
                            _hover={{color: "gray.800", textDecoration: "underline"}}
                            children={truncatedText}
                        />
                    </CustomLink>
                ) : (
                    <Text
                        fontSize={14}
                        fontWeight={"md"}
                        children={truncatedText}
                        color="gray.800"
                    />
                )}
                {!isLast && (
                    <Icon as={FaAngleRight} px={2} fontSize={24} color={"gray.800"} cursor={"pointer"}/>
                )}
            </Flex>
        );
    });
    return (
        <>
            {Links?.length > 0 && <Flex {...BreadcrumbWrapperStyleConfig} wrap="wrap">
                {Links}
            </Flex>}
        </>
    )
}
