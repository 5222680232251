import { Flex, Text } from "@chakra-ui/react";
import { FC } from "react";
import {Helmet} from "react-helmet";

export const ProductPage404: FC = () => {
    return (
        <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"40vh"}
        >
            <Helmet>
                <title>Products Not Found</title>
                <meta name="description" content="No products were found for your search." />
            </Helmet>
            <Text
                color={"gray.600"}
                fontSize={{ base: "16px", md: "24px", xl: "36px" }}
                fontFamily={"Poppins"}
                textAlign={"center"}
            >
                Products Not Found
            </Text>
            <Text
                color={"gray.600"}
                fontSize={{ base: "14px", md: "16px", xl: "20px" }}
                fontFamily={"Poppins"}
                textAlign={"center"}
                mt={4}
            >
                We couldn't find any products matching your criteria. Please try again.
            </Text>
        </Flex>
    );
};

export default ProductPage404;