import React, {FC, useEffect, useState} from 'react';
import {Link as ReactRouterLink} from 'react-router-dom';
import {ChakraProps, Link as ChakraLink, useToast} from '@chakra-ui/react';
import {Product} from "../../shared/entities/Product/Product";
import {FileController} from "../../controllers/FileController";
import IndexedDBService from "../../service/IndexedDBService";
import {useDispatch} from "react-redux";

interface CustomLinkProps extends ChakraProps {
    href: string;
    children: React.ReactNode;
    product?: Product;
}


const CustomLink: FC<CustomLinkProps> = ({ href, children, product, ...props }) => {
    const isExternal = href.startsWith('http');
    const fileName = 'Permaplate_v2';
    const filesToDelete = ['Dealer Presentation Book & Training - National Team']; // Список старых файлов для удаления
    const dispatch = useDispatch();
    const [pdfUrl, setPdfUrl] = useState<Blob>();

    const fileController = new FileController(dispatch);
    const toast = useToast()
    const indexedDBService = new IndexedDBService('FileStorage', 'files');
    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (product && product.id === 1000521) {
            if (!pdfUrl) {
                toast({
                    position: "top",
                    title: 'No loading catalog',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                })
            } else {
                event.preventDefault(); // Останавливаем переход по ссылке
                window.open(URL.createObjectURL(pdfUrl) + '#toolbar=1&menubar=0', "_blank");
            }
        }
    };

    const fetchAndCacheFile = async () => {

        try {
            // Попытка загрузить файл из IndexedDB
            let fileBlob = await indexedDBService.loadFile(fileName);
            if (!fileBlob || fileBlob.size === 0) {
                // Если файл не найден в IndexedDB, удаляем файлы из списка
                await indexedDBService.deleteFiles(filesToDelete);
                // Если файл не найден в IndexedDB, загружаем его
                const res = await fileController.getFileName(fileName);
                if (res) {
                    fileBlob = new Blob([res.Some], {type: 'application/pdf'});

                    // Сохраняем файл в IndexedDB
                    await indexedDBService.saveFile(fileName, fileBlob);
                }
            }
            if (fileBlob) {
                setPdfUrl(fileBlob);
            }
        } catch (error) {
            console.error('Error fetching or saving the file:', error);
        }
    }

    useEffect(() => {
        if (product && product.id === 1000521) {
            fetchAndCacheFile();
        }
    }, [product]);


    return (
        <ChakraLink {...props}
                    as={isExternal ? undefined : ReactRouterLink}
                    to={isExternal ? "unset" : href}
                    href={isExternal ? href : undefined}
                    isExternal={isExternal}
                    onClick={handleLinkClick}
        >
            {children}
        </ChakraLink>
    );
};

export default CustomLink;
