import React from 'react';
import {Flex, Text} from '@chakra-ui/react';
import {Product} from '../../../shared/entities/Product/Product';
import {usePrivileges} from "../../../hooks/PrivilegesProvider";
import EditButtons from "../../../components/Buttons/EditButtons";
import ImageCustom from "../../../components/Image/ImageCustom";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import {CardBody} from "../../../components/Card/CardBody";
import {WebPUtils} from "../../../shared/utilities/WebPUtils";
import CustomLink from "../../../components/Link/CustomLink";

interface ProductItemProps {
    product: Product;
    height?: string;
    width?: string;
    divider: number;
    isMobile?: boolean;
}

const ProductGeneralItem: React.FC<ProductItemProps> = ({
                                                            product,
                                                            height,
                                                            width = "100%",
                                                            divider = 1,
                                                            isMobile = false
                                                        }) => {
    const {priv} = usePrivileges()

    return (
        <Flex w={width} minHeight={height}>
            {priv?.PRODUCT_EDIT &&
                <DragAndDropBox item={product} width={"18px"} orderByZone={true} height={height}/>
            }
            <CardBody>
                <DragAndDropBox item={product} isDraggable={priv?.PRODUCT_EDIT}>
                    <EditButtons item={product}/>
                    <ImageCustom elem={product} currentSize={"subgeneral"} sizeBackend={"GENERAL"}
                                 isMobail={isMobile}
                                 sizeInPercent={100 / divider}/>
                    <CustomLink fontWeight={"md"} fontSize={22} lineHeight={"115%"} href={WebPUtils.getProductLink(product)}>
                        <Flex px={6} cursor={"pointer"}
                              alignItems="center"
                              justifyContent="flex-start"
                              width="100%" height={24}>
                            <Text fontWeight={"md"} fontSize={22} lineHeight={"115%"} wordBreak="break-word" whiteSpace={"pre-wrap"}>
                                {product.name}
                            </Text>
                        </Flex>
                    </CustomLink>
                </DragAndDropBox>
            </CardBody>
        </Flex>
    );
};
export default ProductGeneralItem;
