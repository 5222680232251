import {PayloadAction} from "@reduxjs/toolkit";
import {Dispatch} from "react";
import {UserService} from "../service/User/UserService";
import {Option} from "../shared/utilities/OptionT";
import {OrderByCreateRequest, UserCreationRequest} from "../shared/entities/Users/User";

export class UserController {
    private dispatch: Dispatch<PayloadAction<any>>
    private service: UserService

    constructor(dispatch: Dispatch<PayloadAction<any>>) {
        this.dispatch = dispatch
        this.service = new UserService(this.dispatch)
    }


    public async getUserList() {
        return this.service.getUserList().then(res => {
            return res
        })
    }

    public async getUserListAll(params: {}) {
        return this.service.getUserListAll(params).then(res => {
            return res
        })
    }

    public async getDealershipAll(params: {}) {
        return this.service.getDealershipAll(params).then(res => {
            return res
        })
    }

    public async getAll(data: any, body: any) {
        return this.service.getAll(data, body).then(res => {
            return res
        })
    }

    public async getCompanyAll(data: any, body: any) {
        return this.service.getCompanyAll(data, body).then(res => {
            return res
        })
    }

    public async getDealershipMinInfoAll(params: any) {
        return this.service.getDealershipMinInfoAll(params).then(res => {
            return res
        })
    }

    public async getRequestRegistrationAll(params: any) {
        return this.service.getRequestRegistrationAll(params).then(res => {
            return res
        })
    }

    public async confirmCompleted(id: number) {
        return this.service.confirmCompleted(id).then(res => {
            return res
        })
    }

    public async dealershipFeeState(data: any) {
        return this.service.dealershipFeeState(data).then(res => {
            return res
        })
    }

    public async createUser(data: any): Promise<Option<boolean>> {
        return this.service.createUser(data).then(res => {
            return UserController.handleResult<number>(res)
        })
    }

    public async changePassword(data: any): Promise<Option<boolean>> {
        return this.service.changePassword(data).then(res => {
            return UserController.handleResult<number>(res)
        })
    }

    public async getLoginCode(param: any) {
        return this.service.getLoginCode(param)
    }

    public async restorePassword(data: any): Promise<Option<boolean>> {
        return this.service.restorePassword(data).then(res => {
            return UserController.handleResult<number>(res)
        })
    }

    public async getUser(param: any, setReduce: boolean) {
        return this.service.getUser(param, setReduce)
    }

    public async getCompany(param: any, setReduce: boolean) {
        return this.service.getCompany(param, setReduce)
    }

    public async createCompany(data: any): Promise<Option<boolean>> {
        return this.service.createCompany(data).then(res => {
            return UserController.handleResult<number>(res)
        })
    }

    public async updateCompany(data: any): Promise<Option<boolean>> {
        return this.service.updateCompany(data).then(res => {
            return UserController.handleResult(res)
        })
    }

    public async getDealership(params: {}) {
        return this.service.getDealership(params)
    }

    public async updateUser(data: any): Promise<Option<boolean>> {
        return this.service.updateUser(data).then(res => {
            return UserController.handleResult(res)
        })
    }

    public cleanUser() {
        this.service.cleanUser()
    }

    public cleanCompany() {
        this.service.cleanCompany()
    }

    public async deleteUser(id: number) {
        return this.service.deleteUser(id)
    }

    public async deleteCompany(id: number) {
        return this.service.deleteCompany(id)
    }

    public async deleteDealership(id: number) {
        return this.service.deleteDealership(id)
    }

    public async companyRegistered(id: number) {
        return this.service.companyRegistered(id)
    }

    public async inviteCreate(id: number) {
        return this.service.inviteCreate(id)
    }

    public async userCreationRequest(data: UserCreationRequest) {
        return this.service.userCreationRequest(data).then(res => res)
    }

    public async orderByCatalogCreate(data: OrderByCreateRequest) {
        return this.service.orderByCatalogCreate(data).then(res => res)
    }

    public async setNewPassword(data: any) {
        return this.service.setNewPassword(data).then(res => res)
    }

    private static handleResult<T>(res: Option<T>): Option<boolean> {
        if (res?.None) {
            return {
                Some: false,
                None: res.None
            }
        }
        return {Some: true}
    }

}
