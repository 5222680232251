import {Box, Checkbox, Flex, Grid, Text} from "@chakra-ui/react";
import {Form, Formik} from "formik"
import {FormControl} from "formik-chakra-ui";
import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router"
import {FormButtonsRow} from "../../../components/Buttons/FormButtonsRow";
import {SectionCard} from "../../../components/Card/SectionCard";
import {InputControlStyled} from "../../../components/Inputs/InputControlStyled";
import {ListItemConfig, ListItemRow} from "../../../components/List/ListItem";
import {RoleController} from "../../../controllers/RoleController";
import {RoleValidator} from "../../../controllers/Validation/RoleValidator";
import {SetBreadcrumbsLinks} from "../../../redux/Breadcrumbs/BreadcrumbsReducer";
import {ModalService} from "../../../service/Modal/ModalService";
import {BreadcrumbsData} from "../../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {PreventNavigationModal} from "../../../shared/utilities/PreventNavigationModal";
import {BoxRowStyleConfig, BoxStyleConfig, FormStyleConfig} from "../FormStyleConfigs";
import {Privileges} from "../../../shared/entities/Role/Privileges";
import {RoleDownIcon, RoleUpIcon, TrashItem} from "../../../components/Inputs/Icons/Icons";
import {Icon} from "@chakra-ui/icons";
import {Breadcrumbs} from "../../../components/Breadcrumbs/Breadcrumbs";
import {MainButton} from "../../../components/Buttons/MainButton";
import {usePrivileges} from "../../../hooks/PrivilegesProvider";

/**
 * Форма создания роли
 * @param param0
 * @returns
 */
export const RoleForm: FC<{ edit?: boolean, links: Array<BreadcrumbsData> }> = ({edit, links}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const roleController = new RoleController(dispatch)
    const [allPrivileges, setAllPrivileges] = useState<Array<Privileges>>([]);

    const modalService = new ModalService(dispatch)
    const [initialValues, setInitialValues] = useState<any>([]);
    const [group, setGroup] = useState<any>([]);
    const [countNewRole, setCountNewRole] = useState<number>(0);
    const [showRole, setShowRole] = useState<boolean[]>(Array(initialValues.length).fill(false));
    const {priv} = usePrivileges();

    useEffect(() => {
        SetBreadcrumbsLinks(dispatch, links)
        roleController.getRole().then((roles => {
                if (roles.Some) {
                    roles.Some.sort((a, b) => Number(a.ID) - Number(b.ID));

                    let roleDtos = roles.Some.map((role) => {
                        return {
                            id: role.ID,
                            privileges: role.PRIVILEGES.map((v: any) => v.ID),
                            name: role.NAME,
                            defaultRole: role.DEFAULT_ROLE
                        }
                    })
                    setInitialValues(roleDtos)
                }
            })
        )
        roleController.getPrivileges().then((res => {
            if (res.Some) {
                setGroup([...new Set(res.Some.map(item => item.PRIVILEGE_GROUP))])
                setAllPrivileges(res.Some);
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleRole = (idx: number) => {
        setShowRole(prevShowRole => {
            const updatedShowRole = [...prevShowRole];
            updatedShowRole[idx] = !updatedShowRole[idx];
            return updatedShowRole;
        });
    };
    const handleNewRole = () => {
        setInitialValues([...initialValues, {
            id: -1,
            privileges: [],
            name: "Custom Role1" + countNewRole,
            defaultRole: false
        }]);
        setCountNewRole(countNewRole + 1);
    };

    const handleRemove = (role: any) => {
        modalService.setModalData({
            onSubmit: () => {
                modalService.deleteModalData();
                roleController.deleteRole(Number(role.id)).then(() => {
                    window.location.reload();
                });
            },
            isOpen: true,
            onOpen: () => {
            },
            onClose: () => {
                modalService.deleteModalData();
            },
            modalTextData: "Role will be deleted.",
            context: "action"
        });
    };

    return (
        <SectionCard>
            <Box {...BoxRowStyleConfig} >
                <Breadcrumbs/>
                {priv?.ROLE_CREATE && (
                    <MainButton children={"Create role"} marginLeft="auto" left="0px" onClick={() => handleNewRole()}/>
                )}
            </Box>
            <Formik initialValues={initialValues}
                    onSubmit={(values) => {
                        roleController.createUpdateRole(values).then(() => {
                            window.location.reload();
                        })
                    }}
                    validationSchema={RoleValidator.GetSchema()}
                    enableReinitialize={true}
            >

                {({isSubmitting, errors, values, dirty, isValid, handleSubmit, setFieldValue}) => (
                    <FormControl name={"name"} {...FormStyleConfig}>
                        <Form onSubmit={handleSubmit}>
                            {values?.map((elemRole: any, idxr: number) => {
                                return (
                                    <Flex pb={(values?.length - 1) === idxr ? "50px" : "10px"}
                                          key={elemRole.name + idxr}>
                                        <Box {...BoxStyleConfig} borderWidth="1.54px" boxShadow="md" borderRadius={10}
                                             borderColor={"#5A5A5A"}>
                                            <Box {...BoxRowStyleConfig} mb={"5px"}>
                                                <Text ml={"20px"} fontSize={"18px"} mt={"4px"}>{elemRole.name} </Text>
                                                <Flex spacing="15px" ml={"auto"}>
                                                    {(priv?.ROLE_REMOVE && !elemRole.defaultRole) && (
                                                        <Icon ml={"auto"} mr={"20px"} mt={"8px"}
                                                              as={TrashItem}
                                                              fontSize={"20px"}
                                                              color="grey.500"
                                                              cursor={"pointer"}
                                                              onClick={() => handleRemove(elemRole)}
                                                        />)
                                                    }
                                                    <Icon mr={"20px"} mt={"8px"}
                                                          as={showRole[idxr] ? RoleUpIcon : RoleDownIcon}
                                                          fontSize={"20px"}
                                                          color="grey.500"
                                                          cursor={"pointer"}
                                                          onClick={() => toggleRole(idxr)}
                                                    />
                                                </Flex>
                                            </Box>
                                            {showRole[idxr] && (
                                                <Box {...BoxRowStyleConfig} mt={"20px"}>
                                                    <Box ml={"20px"}>
                                                        {(priv?.ROLE_EDIT || priv?.ROLE_CREATE) && (
                                                            <InputControlStyled name={`[${idxr}].name`} onChange={
                                                                (ev: any) => setFieldValue(`[${idxr}].name`, ev.target.value, true)
                                                            }/>)
                                                        }
                                                    </Box>
                                                    <Box flexDir={"column"} width="50%" ml={"10px"} mt={"10px"}
                                                         mb={"10px"}>
                                                        {group?.sort((a: any, b: any) => a.localeCompare(b)).map((elem: any, idxg: number) => {
                                                            const ItemConfig: Array<ListItemConfig> = [
                                                                {
                                                                    content: <Text
                                                                                   fontWeight={"600"}>{elem}</Text>,
                                                                    isLink: false,
                                                                    style: {
                                                                        flexBasis: "30%",
                                                                    }
                                                                },
                                                                {
                                                                    content: <FormControl name={elem.ID + "name"}
                                                                                          key={elem + idxg}>
                                                                        <Grid templateColumns="repeat(4, 1fr)" gap={0}>
                                                                            {allPrivileges
                                                                                .filter((group) => group.PRIVILEGE_GROUP === elem)
                                                                                .sort((a: any, b: any) => a.NAME.localeCompare(b.NAME))
                                                                                .map((item: any, idxp: number) => {
                                                                                    return <Box {...BoxRowStyleConfig}
                                                                                                key={item + idxr + idxp + idxg}
                                                                                                z>
                                                                                        <Checkbox
                                                                                            key={item.ID + " key" + idxp}
                                                                                            marginRight={"5px"}
                                                                                            mt={"7px"}
                                                                                            colorScheme={"gray"}

                                                                                            isChecked={values[idxr]?.privileges.includes(Number(item.ID))}
                                                                                            isDisabled={(!priv?.ROLE_EDIT && !priv?.ROLE_CREATE)}
                                                                                            id={`[${idxr}].privileges`}
                                                                                            name={`[${idxr}].privileges`}
                                                                                            value={item?.ID as string}
                                                                                            label={item?.NAME as string}
                                                                                            onChange={(e: any) => {
                                                                                                setFieldValue(`[${idxr}].privileges`,
                                                                                                    values[idxr]?.privileges.findIndex((f: number) => f === Number(e.target.value)) === -1
                                                                                                        ? [...values[idxr]?.privileges, Number(e.target.value)]
                                                                                                        : values[idxr]?.privileges.filter((elem: number) => elem !== Number(e.target.value)))
                                                                                            }}
                                                                                        />
                                                                                        <Text>{item.TAG}</Text>
                                                                                    </Box>
                                                                                })
                                                                            }
                                                                        </Grid>
                                                                    </FormControl>
                                                                    ,
                                                                    isLink: false,
                                                                    style: {
                                                                        flexBasis: "70%",
                                                                    }
                                                                }
                                                            ]
                                                            return <ListItemRow style={{justifyContent: "flex-start"}}
                                                                                key={"listitemrow" + idxr + idxg}
                                                                                list={ItemConfig} noBorder={true}/>
                                                        })}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Flex>)
                            })
                            }
                            <Box>
                                <FormButtonsRow
                                    config={{
                                        submitButton: {
                                            disabled: !dirty && isValid,
                                            text: 'Save',
                                            type: "submit"
                                        },
                                        cancelButton: {
                                            text: 'Cancel',
                                            cancelFunc: () => PreventNavigationModal(dispatch, () => navigate(-1))
                                        }
                                    }}/>
                            </Box>
                        </Form>
                    </FormControl>
                )}
            </Formik>
        </SectionCard>
    )
}
export default RoleForm;